import { useEffect, useRef, useState, type ComponentType } from 'react';
import { Button } from './Button';
import clsx from 'classnames';
import { useCombobox } from 'downshift';

interface ComboBoxProps<T> {
  initialItems: T[];
  itemToString?: (item: T | null) => string;
  selectedItem: T | null;
  onChange: (item: T) => void;

  label?: string;
  placeholder?: string;
  autofocus?: boolean;
  id?: string;
  ItemRenderer?: ComponentType<{ item: T }>;
}

function defaultFilter(itemAsString: string, filterValue: string | undefined): boolean {
  if (filterValue === undefined) {
    return true;
  }
  return itemAsString.toLowerCase().includes(filterValue.toLowerCase());
}

function defaultToString<T>(item: T | null): string {
  return item !== null ? String(item) : '';
}

export function AutoCompleteCombo<T>({
  initialItems,
  selectedItem,
  onChange,
  itemToString = defaultToString,
  label,
  placeholder = 'Choose...',
  autofocus = false,
  id,
  ItemRenderer = ({ item }) => <>{itemToString(item)}</>,
}: ComboBoxProps<T>) {
  const [items, setItems] = useState(initialItems);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setItems(initialItems.filter(b => defaultFilter(itemToString(b), inputValue)));
    },
    items,
    itemToString,
    selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (newSelectedItem == null) {
        // This should never happen
        console.error('Selected null value from Autocomplete??');
      } else {
        onChange(newSelectedItem);
      }
    },
    onStateChange(e) {
      // remove filter when clicking
      if (e.type === useCombobox.stateChangeTypes.InputClick) {
        setItems(initialItems);
      }
    },
  });

  useEffect(() => {
    if (autofocus) {
      inputRef?.current?.focus();
    }
    // eslint-disable-next-line
  }, [inputRef?.current]);

  return (
    <div className="input-container">
      {label && (
        <label className="col-form-label form-label" {...getLabelProps()}>
          {label}
        </label>
      )}
      <div className="sgme-picker position-relative">
        <div className="form-control d-flex align-items-stretch pe-0">
          <label className="picker-label overflow-hidden d-flex flex-wrap flex-grow-1 justify-content-start align-items-center mt-n1 mb-0">
            {/* FIXME: disable outline properly */}
            <input
              style={{ outline: 'none' }}
              placeholder={placeholder}
              className="mt-1 text-primary py-0 bg-transparent border-0 flex-grow-1"
              {...getInputProps({
                ref: inputRef,
                id,
              })}
            />
          </label>
          <Button
            flat
            icon
            className="d-flex flex-center"
            aria-label="toggle menu"
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? (
              <em className="icon line-height-1" aria-hidden="true">
                arrow_drop_up
              </em>
            ) : (
              <em className="icon line-height-1" aria-hidden="true">
                arrow_drop_down
              </em>
            )}
          </Button>
        </div>
        <div
          className={clsx(
            'picker-list picker-list--down position-absolute bg-lvl1 border shadow-lg overflow-auto',
            {
              'd-none': !(isOpen && items.length),
            },
          )}
          {...getMenuProps({
            style: { left: 0, right: 0, zIndex: 10, maxHeight: 300 },
          })}
        >
          {isOpen &&
            items.map((item, index) => {
              const active = highlightedIndex === index;
              const selected = selectedItem === item;
              return (
                <Button
                  flat
                  className={clsx(
                    'bg-secondary-alpha-lg bg-secondary-picker-focus fw-normal font-weight-normal h-auto d-flex align-items-center btn-block btn-md text-left text-start mt-0 border border-opacity-20 border-alpha-sm border-start-0 border-left-0 border-top-0 border-end-0 border-right-0 btn-flat-primary space-between',
                    {
                      selected: selected,
                      active: active,
                    },
                  )}
                  key={itemToString(item)}
                  {...getItemProps({ item, index })}
                >
                  <div className="flex-fill">
                    <ItemRenderer item={item} />
                  </div>
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
}

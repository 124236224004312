import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormattedMessage } from 'react-intl';

import { getHedgerErrorMessage } from '@/components/hedger/common/getHedgerErrorMessage.ts';

type FetchErrorViewParams = {
  error: FetchBaseQueryError | SerializedError;
};

export function FetchErrorView({ error }: FetchErrorViewParams) {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">
        <FormattedMessage id="App.Error" />
      </h4>
      <p className="mb-0">{getHedgerErrorMessage(error)}</p>
    </div>
  );
}

import type { AdditionalConfig, AppConfig } from '../../../scripts/AppConfig';

import { webApi } from '@/web/utils/webApi.ts';
import { getConfig } from '@/utils/config/config.ts';
import { logger } from '@/utils/libs/logger.ts';

export function isPreprodEnv(): boolean {
  return getConfig().environment === 'uat';
}

export function isDevCiEnv(): boolean {
  return getConfig().environment === 'devci';
}

export function isLocalEnv(): boolean {
  return getConfig().environment === 'local';
}

export function isDevEnv(): boolean {
  return getConfig().environment === 'devci' || getConfig().environment === 'local';
}

export function isProdEnv(): boolean {
  return getConfig().environment === 'prod';
}

export async function fetchAdditionalConfig(
  defaultConfig?: AppConfig,
): Promise<AdditionalConfig | AppConfig | undefined> {
  try {
    if (defaultConfig !== undefined) {
      const stringifiedConfig = JSON.stringify(defaultConfig);

      return await webApi
        .get(`api/config?defaultConfig=${stringifiedConfig}`)
        .json<AdditionalConfig>();
    }

    return await webApi.get('api/config').json<AdditionalConfig>();
  } catch (error) {
    logger.logError('Error while getting FrontConfig from webapi {error_s}', JSON.stringify(error));
    return undefined;
  }
}

import { z } from 'zod';

export const hedgerErrorSchema = z.object({
  status: z.number(),
  errorMessages: z.array(
    z.object({ error: z.string(), field: z.string(), severity: z.string(), message: z.string() }),
  ),
  path: z.string(),
  timestamp: z.string(),
});

export const hedgerErrorDataSchema = z.object({
  data: hedgerErrorSchema,
});

export type HedgerError = z.infer<typeof hedgerErrorSchema>;

import type { ProcessDataFromClipboardParams } from '@ag-grid-community/core';

export function processDataFromClipboard(
  params: ProcessDataFromClipboardParams,
): string[][] | null {
  const data = [...params.data];
  const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1;

  if (emptyLastRow) {
    data.splice(data.length - 1, 1);
  }

  const lastIndex = params.api!.getDisplayedRowCount() - 1;
  const focusedCell = params.api!.getFocusedCell();
  const focusedIndex = focusedCell!.rowIndex;

  if (focusedIndex + data.length - 1 > lastIndex) {
    const resultLastIndex = focusedIndex + (data.length - 1);
    const numRowsToAdd = resultLastIndex - lastIndex;

    const rowsToAdd: any[] = [];
    for (let i = 0; i < numRowsToAdd; i++) {
      const index = data.length - 1;
      const row = data.slice(index, index + 1)[0];

      // Create row object
      const rowObject: any = {};
      let currentColumn: any = focusedCell!.column;
      row.forEach(item => {
        if (!currentColumn) {
          return;
        }
        rowObject[currentColumn.colDef.field] = item;
        currentColumn = params.api!.getDisplayedColAfter(currentColumn);
      });

      rowsToAdd.push(rowObject);
    }

    params.api!.applyTransaction({ add: rowsToAdd });
  }

  return data;
}

type ValueMap<T, R> = {
  [K in keyof T]: R;
};

export function mapDefinedValues<T extends object, R>(
  obj: T,
  mapper: (value: T[keyof T]) => R | undefined,
): ValueMap<T, R> {
  const result: ValueMap<T, R> = {} as ValueMap<T, R>;
  for (const key in obj) {
    const mapped = mapper(obj[key]);
    if (mapped !== undefined) {
      result[key] = mapped;
    }
  }
  return result;
}

import { useState } from 'react';

import { Button } from '@/components/common/bootstrap/Button.tsx';
import { useTimeout } from '@/components/common/hooks/useTimeoutFn.ts';

export function CopyButton({ text }: { text: string }): JSX.Element {
  const [justCopied, setJustCopied] = useState<boolean>(false);

  const [, , set] = useTimeout(() => {
    setJustCopied(false);
  }, 3000);

  function handleClick() {
    navigator.clipboard.writeText(text);
    setJustCopied(true);
    set();
  }

  return (
    <Button className="btn-icon-start" onClick={handleClick}>
      {justCopied ? (
        <>
          <em className="icon">assignment_turned_in</em> Copied!
        </>
      ) : (
        <>
          <em className="icon">content_paste</em>
          Copy to clipboard
        </>
      )}
    </Button>
  );
}

/*  eslint-disable no-storage/no-browser-storage */

import type { z, ZodType } from 'zod';

export interface StorageKey<T extends ZodType> {
  type: 'localStorage' | 'sessionStorage';
  key: string;
  schema: T;
}

export function saveToStorage<T extends ZodType>(
  storageKey: StorageKey<T>,
  value: z.infer<T>,
): void {
  getStorage(storageKey.type).setItem(storageKey.key, JSON.stringify(value));
}

export function getFromStorage<T extends ZodType>(
  storageKey: StorageKey<T>,
): z.infer<T> | undefined {
  const strValue = getStorage(storageKey.type).getItem(storageKey.key);
  if (strValue === null) {
    return undefined;
  }

  const parseResult = storageKey.schema.safeParse(JSON.parse(strValue));
  return parseResult.success ? parseResult.data : undefined;
}

function getStorage(type: 'localStorage' | 'sessionStorage'): Storage {
  switch (type) {
    case 'localStorage':
      return localStorage;
    case 'sessionStorage':
      return sessionStorage;
  }
}

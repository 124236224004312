export function safeParseNumber(maybeNum: string | undefined | null) {
  if (maybeNum == null || maybeNum === '') {
    return undefined;
  }

  const number = Number(maybeNum);
  if (isNaN(number)) {
    return undefined;
  }

  return number;
}

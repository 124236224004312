import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import type { AppState, AppThunk } from '@/store/store.ts';
import type { CubeMode } from '@/types/AppConfig.ts';
import type { IntlMessage } from '@/types/intl';
import { getTheme, type Theme } from '@/utils/libs/theme.ts';

export type IntlMessageOrString = IntlMessage | string;

export type ToastSeverity = 'error' | 'info';

interface ToastErrorType {
  severity: 'error';
}

export type ToastCategory = 'IntradayPricingUpdated' | 'OfficialPricingUpdated';

interface ToastInfoType {
  severity: 'info';
  category: ToastCategory;
}

type ToastType = ToastErrorType | ToastInfoType;

export interface ToastMessage {
  id: string;
  title: IntlMessageOrString;
  body: IntlMessageOrString;
  time?: string;
  type: ToastType;
}

export interface CubeInstanceUI {
  webSocketUrl: string | undefined;
  cubeMode: CubeMode;
  cubeHostName: string;
  trafficWatcher: {
    errorMargin: number;
    expectedMillis: number;
    meanRetentionFactor: number;
  };
}

export type PageOverlay = 'UserPrefs' | null;

export interface UiState {
  toastMessages: ToastMessage[];
  theme: Theme;
  cubeInstances: CubeInstanceUI[];
  selectedCubeInstanceName: string | undefined;
  currentPageOverlay: PageOverlay;
  leftBarModule: string | undefined;
  areWidgetsCollapsed: boolean;
}

const initialUiState: UiState = {
  toastMessages: [],
  theme: getTheme(),
  cubeInstances: [],
  selectedCubeInstanceName: undefined,
  leftBarModule: undefined,
  currentPageOverlay: null,
  areWidgetsCollapsed: true,
};

export type ToastMessagePayload = Omit<ToastMessage, 'id'>;

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUiState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastMessagePayload>) => {
      const newToast = action.payload;
      const newType = newToast.type;

      function isSameCategory(toast: ToastMessage, newType: ToastInfoType) {
        return toast.type.severity === 'info' && toast.type.category === newType.category;
      }

      if (newType.severity === 'info') {
        state.toastMessages = state.toastMessages.filter(toast => !isSameCategory(toast, newType));
      }
      state.toastMessages.push({ ...newToast, id: nanoid(), time: new Date().toISOString() });
    },
    deleteToast: (state, action: PayloadAction<string>) => {
      state.toastMessages = state.toastMessages.filter(
        toastMessage => toastMessage.id !== action.payload,
      );
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },

    setCurrentPageOverlay: (state, action: PayloadAction<PageOverlay>) => {
      state.currentPageOverlay = action.payload;
    },
    toggleCurrentPageOverlay: (state, action: PayloadAction<PageOverlay>) => {
      if (state.currentPageOverlay === action.payload) {
        state.currentPageOverlay = null;
      } else {
        state.currentPageOverlay = action.payload;
      }
    },
    toggleLeftBarModule: (state, action: PayloadAction<string | undefined>) => {
      if (state.leftBarModule === action.payload) {
        state.leftBarModule = undefined;
      } else {
        state.leftBarModule = action.payload;
      }
    },
    setCubeInstances: (state, action: PayloadAction<CubeInstanceUI[]>) => {
      state.cubeInstances = action.payload;
    },
    setSelectedCubeInstanceName: (state, action: PayloadAction<string>) => {
      state.selectedCubeInstanceName = action.payload;
    },
    setWidgetCollapsed: (state, action: PayloadAction<boolean>) => {
      state.areWidgetsCollapsed = action.payload;
    },
  },
});

export function selectCurrentInstance(state: AppState): CubeInstanceUI {
  const { selectedCubeInstanceName } = state.ui;
  return state.ui.cubeInstances.find(
    ({ cubeHostName }) => cubeHostName === selectedCubeInstanceName,
  )!; //  should always be defined because instance always in the list
}

export function addErrorToastThunk(message: string, title: string = 'Error'): AppThunk {
  return dispatch => {
    dispatch(
      uiSlice.actions.addToast({
        title,
        body: message,
        type: { severity: 'error' },
        time: new Date().toISOString(),
      }),
    );
  };
}

import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from '@sgme/fp';
import { formatISO } from 'date-fns';

import type { Hierarchy } from '@/core/hierachies.ts';
import type { MeasureId } from '@/core/measures.ts';
import type {
  FilterMeasure,
  InListUnaryFilter,
  QueryFilter,
  QueryFilters,
} from '@/core/query/mdx/filter/filterModel.ts';
import { GREAT_CUBE_NAME, SG_CUBE_NAME } from '@/core/query/mdx/utils.ts';
import type { AppState, AppThunk } from '@/store/store.ts';
import type { CubeMode } from '@/types/AppConfig.ts';
import { strictEntries } from '@/utils/libs/entries.ts';

export const exclusionModes = ['applied', 'onlyExcluded', 'notApplied'] as const;
export type ExclusionMode = (typeof exclusionModes)[number];

export interface QueryState {
  selectedHierarchies: Hierarchy[];
  perimeters: string[];
  selectedMeasureIds: MeasureId[];
  valueDate: string;
  filters: QueryFilters;
  filtersEnabled: boolean;
  exclusionMode: ExclusionMode;
  cubeName: string;
  cubeMode: CubeMode;
  wsUrl: string | undefined;
}

const initialState: QueryState = {
  selectedHierarchies: [],
  selectedMeasureIds: [],
  perimeters: [],
  valueDate: 'today',
  filters: {},
  filtersEnabled: false,
  exclusionMode: 'applied',
  cubeMode: 'sgCube',
  cubeName: SG_CUBE_NAME,
  wsUrl: undefined,
};

export const querySlice = createSlice({
  name: 'query',
  initialState: initialState,
  reducers: {
    setHierarchies: (state, action: PayloadAction<Hierarchy[]>) => {
      state.selectedHierarchies = action.payload;
    },
    setMeasures: (state, action: PayloadAction<MeasureId[]>) => {
      state.selectedMeasureIds = action.payload;
    },
    setPerimeters: (state, action: PayloadAction<string[]>) => {
      state.perimeters = action.payload;
    },
    setFilters: (state, action: PayloadAction<QueryFilters>) => {
      state.filters = action.payload;
    },
    toggleFilters: (state, action: PayloadAction<{ enabled: boolean }>) => {
      state.filtersEnabled = action.payload.enabled;
    },
    setExclusionMode: (state, action: PayloadAction<ExclusionMode>) => {
      state.exclusionMode = action.payload;
    },

    addFilters: (
      state,
      action: PayloadAction<
        { hierarchy: Hierarchy; measure: FilterMeasure; filter: QueryFilter }[]
      >,
    ) => {
      const indexedFilters = toIndexedFilters(state.filters);

      for (const filterData of action.payload) {
        const { filter, hierarchy, measure } = filterData;

        indexedFilters.push({
          hierarchy,
          measure,
          filter,
          // This is not used by filtersFromIndexed
          index: -1,
        });
      }

      state.filters = filtersFromIndexed(indexedFilters);
    },

    addFilter: (
      state,
      action: PayloadAction<{ hierarchy: Hierarchy; measure: FilterMeasure; filter: QueryFilter }>,
    ) => {
      const { filter, hierarchy, measure } = action.payload;
      const indexedFilters = toIndexedFilters(state.filters);
      indexedFilters.push({
        hierarchy,
        measure,
        filter,
        // This is not used by filtersFromIndexed
        index: -1,
      });
      state.filters = filtersFromIndexed(indexedFilters);
    },

    removeFilter: (
      state,
      action: PayloadAction<{
        index: number;
      }>,
    ) => {
      const { index } = action.payload;
      const indexedFilters = toIndexedFilters(state.filters);
      indexedFilters.splice(index, 1);
      state.filters = filtersFromIndexed(indexedFilters);
      if (Object.keys(state.filters).length === 0) {
        // if the last filter is removed, consider that they are enabled again
        state.filtersEnabled = true;
      }
    },
    replaceFilter: (state, action: PayloadAction<IndexedFilter>) => {
      const { index } = action.payload;
      const indexedFilters = toIndexedFilters(state.filters);
      indexedFilters.splice(index, 1, action.payload);
      state.filters = filtersFromIndexed(indexedFilters);
    },

    setCubeInfos: (
      state,
      action: PayloadAction<{
        cubeMode: CubeMode;
        wsUrl: string | undefined;
      }>,
    ) => {
      state.cubeMode = action.payload.cubeMode;
      state.cubeName = action.payload.cubeMode === 'sgCube' ? SG_CUBE_NAME : GREAT_CUBE_NAME;
      state.wsUrl = action.payload.wsUrl;
      state.valueDate = getTodayQueryDate(action.payload.cubeMode);
    },
  },
});

export interface IndexedFilter {
  hierarchy: Hierarchy;
  measure: FilterMeasure;
  filter: QueryFilter;
  index: number;
}

export const selectIndexedFilters = createSelector([selectFilters], toIndexedFilters);

function toIndexedFilters(filters: QueryFilters): IndexedFilter[] {
  let index = 0;
  return strictEntries(filters).flatMap(([hierarchy, measureFilters]) =>
    strictEntries(measureFilters).flatMap(([measure, filters]) =>
      filters.map(filter => ({
        hierarchy,
        measure,
        filter,
        index: index++,
      })),
    ),
  );
}

function filtersFromIndexed(indexed: IndexedFilter[]): QueryFilters {
  const filters: QueryFilters = {};
  for (const { hierarchy, measure, filter } of indexed) {
    if (filters[hierarchy] === undefined) {
      filters[hierarchy] = {};
    }

    if (Array.isArray(filters[hierarchy]![measure])) {
      filters[hierarchy]![measure]!.push(filter);
    } else {
      filters[hierarchy]![measure] = [filter];
    }
  }
  return filters;
}

export function selectFilters(appState: AppState): QueryFilters {
  return appState.query.filters;
}

function getTodayQueryDate(cubeMode: CubeMode) {
  return cubeMode === 'sgCube' ? 'today' : formatISO(new Date(), { representation: 'date' });
}

export interface FilterParams {
  hierarchyFilterLevel: number;
  cellValue: string;
}

export function applyQuickFiltersThunk(flterParams: FilterParams[]): AppThunk {
  return (dispatch, getState) => {
    const filterList: { hierarchy: Hierarchy; measure: FilterMeasure; filter: QueryFilter }[] = [];

    for (const { hierarchyFilterLevel, cellValue } of flterParams) {
      // do nothing on total row
      if (hierarchyFilterLevel === 0) {
        continue;
      }

      const state = getState();
      const selectedHierarchy = state.query.selectedHierarchies[hierarchyFilterLevel - 1];
      const filter = getFilter([cellValue]);

      if (filter !== undefined) {
        filterList.push({
          hierarchy: selectedHierarchy,
          measure: 'Hierarchy',
          filter,
        });
      }
    }
    dispatch(querySlice.actions.addFilters(filterList));
  };
}

export function applyQuickFilterThunk(
  hierarchyFilterLevel: number,
  cellValues: string[],
): AppThunk {
  return (dispatch, getState) => {
    // do nothing on total row
    if (hierarchyFilterLevel === 0) {
      return;
    }

    const state = getState();
    const selectedHierarchy = state.query.selectedHierarchies[hierarchyFilterLevel - 1];
    const filter = getFilter(cellValues);

    if (filter !== undefined) {
      dispatch(
        querySlice.actions.addFilter({
          hierarchy: selectedHierarchy,
          measure: 'Hierarchy',
          filter,
        }),
      );
    }
  };
}

function getFilter(cellValues: string[]): QueryFilter | InListUnaryFilter | undefined {
  if (isEmpty(cellValues)) {
    return undefined;
  } else if (cellValues.length === 1) {
    return {
      type: 'string',
      operator: 'equals',
      value: cellValues[0],
    };
  } else {
    return {
      type: 'string',
      operator: 'inList',
      values: cellValues,
    };
  }
}

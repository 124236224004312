import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useCombobox, useMultipleSelection } from 'downshift';

import { Button } from '@/components/common/bootstrap/Button.tsx';

function getFilteredItems<T>(
  items: readonly T[],
  inputValue: string,
  selectedItems: T[] = [],
  itemToString: (item: T | null) => string = item => `${item}`.toLowerCase(),
) {
  const selectedItemsAsString = selectedItems.map(itemToString);

  return items.filter(item => {
    const itemAsString = itemToString(item);
    return !selectedItemsAsString.includes(itemAsString) && itemAsString.includes(inputValue);
  });
}

function defaultToString<T>(item: T | null): string {
  return item !== null ? String(item) : '';
}
interface MultiselectDropdownProps<T> {
  items: readonly T[];
  selectedItems: T[];
  onChange: (items: T[]) => void;
  itemToString?: (item: T | null) => string;
  label: string;
  autofocus?: boolean;
  itemsToFreeze: T[];
}
export function MultiselectDropdown<T>(props: MultiselectDropdownProps<T>) {
  const {
    itemToString = defaultToString,
    autofocus = false,
    label,
    onChange,
    selectedItems = [],
    items,
    itemsToFreeze,
  } = props;
  const [inputValue, setInputValue] = useState('');

  const selectableItems = useMemo(
    () => getFilteredItems<T>(items, inputValue, selectedItems),
    [items, inputValue, selectedItems],
  );
  const isItemFrozen = useCallback(
    (item: T) => itemsToFreeze.map(itemToString).includes(itemToString(item)),
    [itemsToFreeze, itemToString],
  );

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    onStateChange({ selectedItems: newSelectedItems = [], type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          onChange(newSelectedItems);
          break;
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          {
            const allowedToDelete = selectedItems
              .filter(item => !newSelectedItems.includes(item))
              .every(element => !itemsToFreeze.includes(element));
            if (allowedToDelete) {
              onChange(newSelectedItems);
            }
          }
          break;
        default:
          break;
      }
    },
    selectedItems,
  });
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items: selectableItems,
    itemToString,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,

    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },

    onStateChange({ inputValue: newInputValue = '', type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            onChange([...selectedItems, newSelectedItem]);
            setInputValue('');
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);

          break;
        default:
          break;
      }
    },
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autofocus) {
      inputRef?.current?.focus();
    }
    // eslint-disable-next-line
  }, [inputRef?.current]);

  return (
    <div className="form-group-deprecated">
      <label className="col-form-label form-label" {...getLabelProps()}>
        {label}
      </label>
      <div className="era-picker position-relative">
        <div className="form-control d-flex gap-1 flex-wrap p-2">
          {selectedItems.map((selectedItemForRender, index) => {
            const isFrozen = isItemFrozen(selectedItemForRender);
            return (
              <div
                className={`text-primary badge badge-secondary my-auto ${
                  isFrozen ? '' : 'badge-dismissible'
                }`}
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  selectedItem: selectedItemForRender,
                  index,
                })}
              >
                {!isFrozen && (
                  <button
                    className="btn"
                    onClick={e => {
                      e.stopPropagation();
                      removeSelectedItem(selectedItemForRender);
                    }}
                  >
                    <em className="icon">close</em>
                  </button>
                )}
                <span>{itemToString(selectedItemForRender)}</span>
              </div>
            );
          })}
          <div className="d-flex  overflow-auto flex-grow-1 align-items-center w-25">
            <label className=" overflow-hidden d-flex flex-wrap   flex-grow-1">
              <input
                placeholder={'Choose...'}
                className=" mt-1 text-primary py-0 bg-transparent border-0 "
                {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
              />
            </label>
            <Button flat icon aria-label="toggle menu" type="button" {...getToggleButtonProps()}>
              {isOpen ? (
                <em className="icon line-height-1" aria-hidden="true">
                  arrow_drop_up
                </em>
              ) : (
                <em className="icon line-height-1" aria-hidden="true">
                  arrow_drop_down
                </em>
              )}
            </Button>
          </div>
        </div>

        <div
          className={classNames(
            'picker-list picker-list--down position-absolute bg-lvl1 border shadow-lg overflow-auto',
            {
              'd-none': !(isOpen && selectableItems.length),
            },
          )}
          {...getMenuProps({
            style: { left: 0, right: 0, zIndex: 10, maxHeight: 300 },
          })}
        >
          {isOpen &&
            selectableItems.map((item: any, index: any) => {
              const active = highlightedIndex === index;
              const selected = selectedItem === item;
              return (
                <Button
                  flat
                  className={classNames(
                    'bg-secondary-alpha-lg bg-secondary-picker-focus fw-normal font-weight-normal h-auto d-flex align-items-center btn-block btn-md text-left text-start mt-0 border border-opacity-20 border-alpha-sm border-start-0 border-left-0 border-top-0 border-end-0 border-right-0 btn-flat-primary space-between',
                    {
                      selected: selected,
                      active: active,
                    },
                  )}
                  key={itemToString(item)}
                  {...getItemProps({ item, index })}
                >
                  <span>{itemToString(item)}</span>
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
}

import type { ChangeEvent } from 'react';
import { nanoid } from 'nanoid';
import { FormattedMessage } from 'react-intl';

import type { TradingToolTemplate } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import {
  getSelectableTradingToolLabels,
  getTradingToolTemplateByKey,
} from '@/components/hedger/manage/recipients/utils.ts';
import type { StrictOmit } from '@/utils/libs/strictTypes.ts';

interface EditRecipientDataFrameProps {
  id?: string;
  recipientData: RecipientData;
  onRecipientDataUpdate: (recipientData: RecipientData) => void;
  tradingToolTemplates: TradingToolTemplate[];
  tradingToolTemplate: TradingToolTemplate;
}
export type RecipientData = StrictOmit<
  Recipient,
  'lastUpdateTime' | 'sharedWithScenarios' | 'recipientId' | 'additionalFields'
>;
export function EditRecipientInputControls(
  editRecipientDataFrameProps: EditRecipientDataFrameProps,
): JSX.Element {
  const {
    recipientData,
    onRecipientDataUpdate,
    tradingToolTemplates,
    tradingToolTemplate,
    id = nanoid(),
  } = editRecipientDataFrameProps;
  const { recipientLabel } = recipientData;
  const teams = useAppSelector(userSlice.selectors.teams);

  const { transmissionLabels, orderTypeLabels } = getSelectableTradingToolLabels(
    tradingToolTemplate,
    tradingToolTemplates,
  );
  const getSelectId = (key: keyof Recipient | keyof TradingToolTemplate): string => `${id}_${key}`;

  const onRecipientLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onRecipientDataUpdate({ ...recipientData, recipientLabel: value });
  };

  const onTeamChange = (team: string) => {
    onRecipientDataUpdate({ ...recipientData, team });
  };
  const onTransmissionLabelChange = (transmissionLabel: string) => {
    const nextTradingToolTemplate = tradingToolTemplates.find(
      tradingToolTemplate => tradingToolTemplate.transmissionLabel === transmissionLabel,
    )!;
    onRecipientDataUpdate({
      ...recipientData,
      tradingToolTemplateId: nextTradingToolTemplate.tradingToolTemplateId,
    });
  };
  const onOrderTypeLabelChange = (orderTypeLabel: string) => {
    const nextTradingToolTemplate = getTradingToolTemplateByKey(tradingToolTemplates, {
      orderTypeLabel,
      transmissionLabel: tradingToolTemplate.transmissionLabel,
    });
    if (nextTradingToolTemplate === undefined) {
      return;
    }

    onRecipientDataUpdate({
      ...recipientData,
      tradingToolTemplateId: nextTradingToolTemplate?.tradingToolTemplateId,
    });
  };

  return (
    <div className="d-flex flex-wrap from-group-container flex-wrap column-gap-5">
      <div className="form-group-deprecated">
        <label htmlFor={getSelectId('recipientLabel')} className="form-label">
          <FormattedMessage id="Hedger.Recipient.Name" />
        </label>
        <input
          className="form-control form-control"
          onChange={onRecipientLabelChange}
          type="text"
          value={recipientLabel ?? ''}
          id={getSelectId('recipientLabel')}
        />
      </div>
      <div className="form-group-deprecated">
        <label htmlFor={getSelectId('team')} className="form-label">
          Owner team
        </label>
        <Select<string>
          id={getSelectId('team')}
          itemsAsObjects={teams}
          selectedItemValue={recipientData.team}
          onChange={onTeamChange}
        />
      </div>
      <div className="form-group-deprecated ">
        <label htmlFor={getSelectId('transmissionLabel')} className="form-label">
          <FormattedMessage id="Hedger.Recipient.TradingTool" />
        </label>
        <Select<string>
          id={getSelectId('transmissionLabel')}
          itemsAsObjects={transmissionLabels}
          onChange={onTransmissionLabelChange}
          selectedItem={tradingToolTemplate.transmissionLabel}
        />
      </div>
      <div className="form-group-deprecated ">
        <label htmlFor={getSelectId('orderTypeLabel')} className="form-label">
          <FormattedMessage id="Hedger.Recipient.OrderType" />
        </label>
        <Select<string>
          id={getSelectId('orderTypeLabel')}
          itemsAsObjects={orderTypeLabels}
          onChange={onOrderTypeLabelChange}
          selectedItem={tradingToolTemplate.orderTypeLabel}
        />
      </div>
    </div>
  );
}

import { Button } from '@/components/common/bootstrap/Button.tsx';

interface TimePickerProps {
  time: string;
  onTimeChange: (value: string) => void;
}

export function TimePicker({ time, onTimeChange }: TimePickerProps) {
  return (
    <div className="d-flex" style={{ width: '12em' }}>
      <input
        className="form-control"
        aria-label="Time"
        type="time"
        value={time}
        onChange={e => onTimeChange(e.target.value)}
      />
      <Button onClick={() => onTimeChange('')}>
        <em className="icon icon-sm">close</em>
      </Button>
    </div>
  );
}

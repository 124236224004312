import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { derivePingUrlFromWsUrl } from '@/web/greatApi/derivePingUrlFromWsUrl.ts';
import { setRequestHeaders } from '@/web/utils/webApi.ts';

export const greatServerApi = createApi({
  reducerPath: 'greatServerApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: headers => {
      return setRequestHeaders(headers);
    },
  }),
  endpoints: builder => ({
    getValueDate: builder.query<string, string>({
      query: webSocketUrl => {
        return { url: derivePingUrlFromWsUrl(webSocketUrl), responseHandler: 'text' };
      },
    }),
  }),
});

export const { useGetValueDateQuery } = greatServerApi;

import { Link } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';

import { rulesCategories } from '@/store/api/rulesApi/rulesModels.ts';

export function RulesSideNav() {
  return (
    <div
      className="col-1 bg-lvl2 p-0 border-top background-primary h-100"
      style={{ minWidth: '230px' }}
    >
      <div className="h5 p-4">Rules</div>
      <ul className="nav nav-underline nav-vertical nav-lg nav-hover">
        {rulesCategories.map(category => (
          <li key={category} className="nav-item">
            {/* bootstrap bug? */}
            <Link
              style={{ height: 'fit-content' }}
              className="nav-link"
              to="/rules/$category"
              params={{ category }}
              activeProps={{
                className: 'active',
              }}
            >
              <FormattedMessage id={`Rules.Link.${category}`} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function removeFromArrayAtIndex<T>(arr: T[], index: number) {
  if (index <= -1) {
    return arr;
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function removeOneFromArray<T>(arr: T[], match: (el: T) => boolean): T[] {
  const index = arr.findIndex(match);
  return removeFromArrayAtIndex(arr, index);
}

export function removeAllFromArray<T>(arr: T[], match: (el: T) => boolean): T[] {
  return arr.filter(el => !match(el));
}

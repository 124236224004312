import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import type { AllMeasureId } from '@/core/measures.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { getCurrencyLabel } from '@/utils/libs/currency.ts';

type AllMeasureAndCacheAndHierarchy = AllMeasureId | 'Hierarchy';

export function useMeasureFormat(): (
  measure: AllMeasureAndCacheAndHierarchy | null | undefined,
) => string {
  const { formatMessage } = useIntl();
  const currencyValue = useAppSelector(
    state => 'k' + getCurrencyLabel(state.userPreferences.currencyValue),
  );

  return useCallback(
    measure => {
      if (measure == null) {
        return '';
      }
      return formatMessage({ id: `Measure.${measure}` }, { currencyValue });
    },
    [currencyValue, formatMessage],
  );
}

import type { QueryState } from '@/store/slices/query/querySlice.ts';
import type { AppState } from '@/store/store.ts';

export type QueryParameters = QueryState;

export const queryParameterSelector = (state: AppState): QueryParameters => {
  const { filters, filtersEnabled } = state.query;
  return { ...state.query, filters: filtersEnabled ? filters : {} };
};

export function queryParamIsValid(queryParameters: QueryParameters): boolean {
  const { selectedHierarchies, selectedMeasureIds, cubeName } = queryParameters;

  const hierarchiesSelected = selectedHierarchies.length > 0;
  const atLeastOneMeasure = selectedMeasureIds.length > 0;

  return hierarchiesSelected && atLeastOneMeasure && cubeName !== '';
}

export function queryParamsAreValid(state: AppState) {
  return queryParamIsValid(queryParameterSelector(state));
}

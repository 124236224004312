export type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];

export const objectEntries: <T extends object>(obj: T) => ReadonlyArray<Entries<T>> =
  Object.entries;

export const strictEntries: <T extends object>(obj: Partial<T>) => ReadonlyArray<Entries<T>> =
  Object.entries;

export const objectKeys: <T extends object>(obj: T) => ReadonlyArray<keyof T> = Object.keys;
export const objectValues: <T extends object>(obj: T) => Array<T[keyof T]> = Object.values;

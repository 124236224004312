import { HelpCenter } from '@/components/common/sgwt/HelpCenter.tsx';
import { MicroFooter } from '@/components/common/sgwt/MicroFooter.tsx';

export function Footer() {
  return (
    <footer>
      <HelpCenter />
      <MicroFooter />
    </footer>
  );
}

/** this file is an entry point so it should remain as light as possible avoid using dependencies as much as you can.
 * see : index.html
 * see : initTheme.ts
 */

/*  eslint-disable no-storage/no-browser-storage */

import { logger } from '@/utils/libs/logger.ts';

export const themeKey = 'Theme';
export type Theme = 'DARK' | 'LIGHT';

const sgbsVersion = import.meta.env.VITE_SGBS_VERSION;
const cdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${sgbsVersion}`;

function variableSgbsUrls(theme: string | null) {
  const variation = theme === 'DARK' ? 'dark' : 'standard';
  return {
    sgbs: `${cdnUrl}/core/css/sg-bootstrap-${variation}.min.css`,
  };
}

export function initSgBootstrap(theme?: Theme) {
  updateThemeUrls(theme ?? getTheme());
  appendUniqueLinkToDocument('icons', `${cdnUrl}/icons/index.min.css`);
}

function updateThemeUrls(theme: Theme) {
  Object.entries(variableSgbsUrls(theme)).forEach(([id, url]) => {
    appendUniqueLinkToDocument(id, url);
  });
}

export function setTheme(theme: Theme) {
  updateThemeUrls(theme);
  logger.logInformation('User switched to theme {theme}', theme);
  // getMatomo().track("Workspace", "Switch theme", theme);
  saveToLocalStorage(theme);
}

export function getTheme(): Theme {
  const savedTheme = getFromLocalStorage();
  if (savedTheme != null) {
    return savedTheme?.toUpperCase() === 'DARK' ? 'DARK' : 'LIGHT';
  }
  return 'DARK';
}

function saveToLocalStorage(theme: Theme) {
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem(themeKey, theme);
  } catch {
    console.error('Unable to store theme, localStorage might be full');
  }
}

function getFromLocalStorage(): string | null {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.getItem(themeKey);
}

function appendUniqueLinkToDocument(id: string, url: string) {
  const linkElement = document.querySelector<HTMLLinkElement>(`link[id="${id}"]`);
  const newLinkElement = document.createElement('link');
  newLinkElement.rel = 'stylesheet';
  newLinkElement.id = id;
  newLinkElement.href = url;
  if (linkElement !== null) {
    newLinkElement.onload = () => {
      document.head.removeChild(linkElement);
    };
  }
  document.head.appendChild(newLinkElement);
}

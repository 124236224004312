import { createWebsocketConnection } from '@/core/webSocket/WebSocket.ts';
import { fetchAnalyticalStructure } from '@/store/slices/queryCache/queryCacheThunks.ts';
import { store } from '@/store/store.ts';

export async function analyticalStructureLoader(): Promise<void> {
  const wsUrl = store.getState().query.wsUrl;
  if (wsUrl !== undefined) {
    const dispatch = store.dispatch;
    if (store.getState().queryCache.analyticalStructure.length === 0) {
      try {
        const webSocketConnection = await createWebsocketConnection(wsUrl);
        await dispatch(fetchAnalyticalStructure(webSocketConnection));
      } catch (e) {
        // voluntary silent catch, we don't want the loader to throw, we handle the error in the component, by checking if the analytical structure is fulfilled
      }
    }
  }
}

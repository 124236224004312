import type { PropsWithChildren } from 'react';

import { useAppSelector } from '@/store/hooks.ts';
import { selectUserHasAnyPermission, type UserPermission } from '@/store/slices/user/userSlice.ts';

export function IfAnyPermission({
  permissions,
  children,
}: PropsWithChildren<{ permissions: UserPermission[] }>): JSX.Element | null {
  const hasPermission = useAppSelector(selectUserHasAnyPermission(...permissions));
  if (hasPermission) {
    return <>{children}</>;
  }
  return null;
}

import { useModal } from '@ebay/nice-modal-react';
import { useNavigate } from '@tanstack/react-router';

import {
  FIXED_CACHE_ORDERGROUP,
  usePostOrderGroupsMutation,
  type OrderGroupRequest,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import {
  HedgerSettingsModal,
  type HedgerModalResult,
  type HedgerSettingsModalProps,
} from '@/components/hedger/execution/settings/HedgerSettingsModal.tsx';

interface HedgerSettingsModalHookResult {
  show: (userRequest?: Partial<OrderGroupRequest>) => Promise<void>;
  remove: () => void;
}

export function useHedgerSettingModal(): HedgerSettingsModalHookResult {
  const [postOrderGroup] = usePostOrderGroupsMutation({
    fixedCacheKey: FIXED_CACHE_ORDERGROUP,
  });
  const modal = useModal(HedgerSettingsModal);
  const navigate = useNavigate();

  async function showModalAndNavigate(userRequest?: Partial<OrderGroupRequest>): Promise<void> {
    const modalProps: HedgerSettingsModalProps = {
      userRequest,
      navigate,
    };
    const modalResult = (await modal.show(modalProps)) as HedgerModalResult;

    try {
      const orderGroup = await postOrderGroup(modalResult).unwrap();
      await navigate({
        to: '/hedger/order/$orderGroupId',
        params: { orderGroupId: orderGroup.orderGroupId.toString() },
      });
    } catch (error: unknown) {
      handleHedgerError('Error while generating order group', error);
    }
  }

  // FIXME: unstable reference to a function
  return {
    show: showModalAndNavigate,
    remove: modal.remove,
  };
}

import { memo, useMemo } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

import type { ParsedResponse, RowData } from '@/core/parsing/parseResponse.ts';
import { getTableColumnsFromMdx } from '@/components/queryTools/query/gridTable/getTableColumnsFromMdx.ts';

interface TableProps {
  data: ParsedResponse;
  treeData: boolean;
}

export const QueryResultTable = memo(({ data, treeData }: TableProps): JSX.Element => {
  const tableColumns = useMemo(() => getTableColumnsFromMdx(data.columns), [data]);
  return (
    <div className="m-3 flex-grow-1 ag-theme-alpine ag-theme-era h-100">
      <AgGridReact<RowData>
        modules={[ClientSideRowModelModule, RangeSelectionModule, RowGroupingModule]}
        key={new Date().toString()}
        gridOptions={{
          enableRangeSelection: true,
          animateRows: true,
          defaultColDef: {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            initialWidth: 120,
          },
          autoGroupColumnDef: {
            width: 250,
          },
          treeData,
          getDataPath: rowData => rowData.dataPath,
          groupDefaultExpanded: -1,
        }}
        rowData={data.rowData}
        columnDefs={tableColumns}
      />
    </div>
  );
});

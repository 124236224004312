import { useMemo, type DependencyList } from 'react';
import { useEvent } from './useEvent.ts';
import { noop } from '@sgme/fp';

export type KeyPredicate = (event: KeyboardEvent) => boolean;
export type KeyFilter = null | undefined | string | ((event: KeyboardEvent) => boolean);
export type Handler = (event: KeyboardEvent) => void;

export interface UseKeyOptions {
  event?: 'keydown' | 'keypress' | 'keyup';
  target?: HTMLElement;
}

const createKeyPredicate = (keyFilter: KeyFilter): KeyPredicate =>
  typeof keyFilter === 'function'
    ? keyFilter
    : typeof keyFilter === 'string'
      ? (event: KeyboardEvent) => event.key === keyFilter
      : keyFilter
        ? () => true
        : () => false;

export function useKey(
  key: KeyFilter,
  fn: Handler = noop,
  opts: UseKeyOptions = {},
  deps: DependencyList = [key],
) {
  const { event = 'keydown', target } = opts;
  const useMemoHandler = useMemo(() => {
    const predicate: KeyPredicate = createKeyPredicate(key);
    const handler: Handler = handlerEvent => {
      if (predicate(handlerEvent)) {
        return fn(handlerEvent);
      }
    };
    return handler;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  useEvent(event, useMemoHandler, target);
}

import type { AnalysisSource } from '@/store/api/hedgerApi/hedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { presetKeyToString } from '@/store/slices/prefs/presetKey.ts';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';

type HedgerSettingsModalFooterProps = {
  onConfirm: () => void;
  source: AnalysisSource;
  disabled: boolean;
};

export function HedgerSettingsModalFooter(props: HedgerSettingsModalFooterProps) {
  const { source, onConfirm, disabled } = props;
  const showGreatPerimeter = source === 'GREAT';
  const activePerimeterPresetKey = useAppSelector(state => state.perimeterPresets.activePresetKey);

  return (
    <div className="d-flex">
      <ShowIf condition={showGreatPerimeter}>
        <span className="flex-shrink-0  align-self-center me-2 bg-azure-opacity-20 py-1 px-5 ">
          Perimeter used:{' '}
          <span className="fw-bold">{presetKeyToString(activePerimeterPresetKey)}</span>
        </span>
      </ShowIf>
      <CancelConfirmFooter confirmButtonProps={{ disabled }} onConfirm={onConfirm} />
    </div>
  );
}

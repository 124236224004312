import type { ValueFormatterParams } from '@ag-grid-community/core';
import { assertUnreachable } from '@sgme/fp';

import type { MeasureType } from '@/core/measures.ts';
import {
  numberFormatValues,
  type NegativeNumberFormat,
  type NumberFormat,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { mapDefinedValues } from '@/utils/libs/mapDefinedValues.ts';

type NumberFormatter = (num: number) => string;

const formatterCache = createFormatterCache({
  zeroDigits: {
    maximumFractionDigits: 0,
    signDisplay: 'never',
  },
  twoDigits: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: 'never',
  },
});

export function getCellValueFormatter(
  numberFormat: NumberFormat,
  negativeNumberFormat: NegativeNumberFormat,
  cellValueFormat: MeasureType,
) {
  return ({ value }: ValueFormatterParams) => {
    return formatValue(value, cellValueFormat, numberFormat, negativeNumberFormat);
  };
}

export function formatValue(
  value: any,
  cellValueFormat: MeasureType,
  numberFormat: NumberFormat,
  negativeNumberFormat: NegativeNumberFormat,
) {
  const isNumber = typeof value === 'number';
  if (!isNumber || cellValueFormat === 'text') {
    return value;
  }

  return formatNumericMeasure(value, numberFormat, cellValueFormat, negativeNumberFormat);
}

/*
 * Don't try to be clever here.
 * This function is called thousands of times (for each cell in the table)
 * Performance is number one priority here.
 */
function formatNumericMeasure(
  value: number,
  numberFormat: NumberFormat,
  format: Exclude<MeasureType, 'text'>,
  negativeNumberFormat: NegativeNumberFormat,
): string {
  if (isNaN(value)) {
    return ' ';
  }

  const formatters = formatterCache[numberFormat];

  let formatted: string;
  switch (format) {
    case 'percentage':
      formatted = formatters['twoDigits'](value * 100) + '%';
      break;
    case 'price':
      if (value === 0) {
        return '-';
      }
      formatted = formatters['twoDigits'](value);
      break;
    case 'currency':
      if (value === 0) {
        return '-';
      }
      formatted = formatters['zeroDigits'](value / 1000);
      break;
    case 'quantity':
      if (value === 0) {
        return '-';
      }
      formatted = formatters['zeroDigits'](value);
      break;
    default:
      assertUnreachable(format, 'Unhandled format');
  }

  if (value < 0) {
    return negativeNumberFormat === '-10' ? `-${formatted}` : `(${formatted})`;
  }
  return formatted;
}

function createFormatterCache<K extends string>(
  formatters: Record<K, Intl.NumberFormatOptions>,
): Record<NumberFormat, Record<K, NumberFormatter>> {
  const byLocaleEntries = numberFormatValues.map(locale => {
    return [locale, mapDefinedValues(formatters, opts => Intl.NumberFormat(locale, opts).format)];
  });
  return Object.fromEntries(byLocaleEntries);
}

import type { PropsWithChildren, ReactNode } from 'react';

export function ShowIf({
  children,
  condition,
}: PropsWithChildren<{ condition: boolean }>): ReactNode | null {
  if (condition) {
    return children;
  }
  return null;
}

import { formatDialect } from 'sql-formatter';

import { mdx } from '@/core/query/format/mdx/mdx.formatter.ts';

export function formatQuery(query: string): string {
  try {
    return formatDialect(query, { dialect: mdx });
  } catch (e: any) {
    console.warn(`Failed to format query`);
    return query;
  }
}

import { useMemo, type CSSProperties } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useRouter } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';
import { asSequence } from 'sequency';

import {
  useGetRecipientsQuery,
  type Recipient,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import {
  useGetScenariosQuery,
  type MarketConfig,
  type Scenario,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { Table } from '@/components/common/bootstrap/Table.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import {
  ViewFrameDataItems,
  type DataItem,
} from '@/components/hedger/manage/common/ViewFameDataItem.tsx';
import { getViewScenarioColumnTables } from '@/components/hedger/manage/scenarios/marketConfigs.tsx';
import { objectValues } from '@/utils/libs/entries.ts';

export interface MarketConfigRowData extends MarketConfig {
  rowId: string;
}

interface ViewScenarioFrameProps {
  scenario: Scenario;
  recipients: Recipient[];
}

export function ViewScenarios(): JSX.Element {
  const scenariosQueryResult = useGetScenariosQuery();
  const getRecipientsQuery = useGetRecipientsQuery();

  if (
    scenariosQueryResult.isLoading ||
    scenariosQueryResult.isUninitialized ||
    getRecipientsQuery.isLoading ||
    getRecipientsQuery.isUninitialized
  ) {
    return <Loader />;
  }
  if (scenariosQueryResult.isError) {
    return <FetchErrorView error={scenariosQueryResult.error} />;
  }
  if (getRecipientsQuery.isError) {
    return <FetchErrorView error={getRecipientsQuery.error} />;
  }

  const recipients = getRecipientsQuery.data.recipients;
  const Scenarios = asSequence(scenariosQueryResult.data.scenarios)
    .sortedBy(scenario => scenario.scenarioId)
    .map(scenario => (
      <ViewScenario scenario={scenario} key={scenario.scenarioId} recipients={recipients} />
    ))
    .toArray();

  return (
    <div className="d-flex flex-column mx-auto w-75 gap-3" style={{ minWidth: '60rem' }}>
      {Scenarios}
    </div>
  );
}

function ViewScenario({ scenario, recipients }: ViewScenarioFrameProps): JSX.Element {
  const { trackEvent } = useMatomo();
  const hasTeams = useAppSelector(userSlice.selectors.hasAnyTeam);
  const { navigate } = useRouter();

  const marketConfigs = objectValues(scenario.marketConfigs);
  const currency = scenario.ordersMetricsCurrency;
  const columns = useMemo(
    () => getViewScenarioColumnTables(recipients, currency),
    [currency, recipients],
  );

  function onEditClick() {
    trackEvent({
      category: 'Hedger',
      action: 'Edit scenario',
      name: `Edited scenario ID : ${scenario.scenarioId}`,
    });
    return navigate({
      to: '/hedger/scenarios/edit/$scenarioId',
      params: { scenarioId: scenario.scenarioId.toString() },
    });
  }

  function onCloneClick() {
    trackEvent({
      category: 'Hedger',
      action: 'Clone scenario',
      name: `Cloned scenario ID : ${scenario.scenarioId}`,
    });
    return navigate({
      to: '/hedger/scenarios/create',
      search: { cloneId: scenario.scenarioId },
    });
  }

  const frameDataItems: DataItem[] = [
    {
      title: 'Owner team',
      data: scenario.team,
    },
    {
      title: 'Currency',
      data: scenario.ordersMetricsCurrency,
    },
    {
      title: 'Deactivated product type',
      data: scenario.deactivatedProductTypes.join(', '),
    },
  ];

  return (
    <div className="d-flex flex-column flex-grow-1 bg-lvl2 gap-3 p-3" data-e2e="scenario-frame">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center  gap-3 ">
          <h4>{scenario.name}</h4>
        </div>
        <div className="d-flex flex-row-reverse gap-3 ">
          <Button size="lg" onClick={onEditClick} disabled={!hasTeams}>
            <div className="d-flex justify-content-between align-items-center ">
              <FormattedMessage id="Hedger.ScenariosModal.Edit" />
              <em className="icon ms-2 icon-md">edit </em>
            </div>
          </Button>
          <Button size="lg" onClick={onCloneClick} disabled={!hasTeams}>
            <div className="d-flex justify-content-between align-items-center ">
              <FormattedMessage id="Hedger.ScenariosModal.Clone" />
              <em className="icon ms-2 icon-md">content_copy </em>
            </div>
          </Button>
        </div>
      </div>
      <ViewFrameDataItems items={frameDataItems} />
      <Table columns={columns} rows={marketConfigs} getRowStyle={getRowStyle} />
    </div>
  );
}

const getRowStyle = (row: MarketConfig): CSSProperties | undefined => {
  if (!row.active) {
    return { opacity: '0.4' };
  }
};

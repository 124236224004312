import { createAsyncThunk } from '@reduxjs/toolkit';

import { validateAndFixVisualizationPresets } from '@/store/slices/prefs/validateAndFixVisualizationPresets.tsx';
import {
  visualizationPresetKeySchema,
  type VisualizationPresetKey,
} from '@/store/slices/prefs/visualizationPresetSchema.ts';
import type { VisualizationPreset } from '@/store/slices/prefs/visualizationPresetsSlice.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import type { AppState } from '@/store/store.ts';
import { loadProfilePresetDefaults } from '@/web/presets/loadProfilePresetDefaults.ts';
import { loadAndMergeByTeamSessionService } from '@/web/session/sessionApi.ts';
import { getSearchParameters } from '@/utils/libs/searchParams.ts';
import { getFromStorage, type StorageKey } from '@/utils/storage/storage.ts';

export const SELECTED_VISU_PRESET_LS_KEY: StorageKey<typeof visualizationPresetKeySchema> = {
  schema: visualizationPresetKeySchema,
  key: 'selectedVisualizationPreset',
  type: 'localStorage',
};

export interface InitAction {
  presets: VisualizationPreset[];
  activePresetKey: VisualizationPresetKey | undefined;
}

export const fetchVisualizationPresets = createAsyncThunk<InitAction, void, { state: AppState }>(
  'visualizationPresets/fetchVisualizationPresets',
  async (_, { getState }) => {
    const user = userSlice.selectors.user(getState());
    return loadAndMergeByTeamSessionService(
      'visualizationPresets',
      user.teams,
      validateAndFixVisualizationPresets,
    ).then(loadedPresets => {
      const profilePresets = loadProfilePresetDefaults(user.role);
      const presets: VisualizationPreset[] = profilePresets.concat(loadedPresets);
      const activePresetKey =
        getKeyFromUrlIfExist('visualizationPresetsKey') ??
        getFromStorage(SELECTED_VISU_PRESET_LS_KEY);
      return {
        presets,
        activePresetKey,
      };
    });
  },
);

function getKeyFromUrlIfExist(key: 'visualizationPresetsKey' | 'perimeterPresetsKey') {
  const urlSearchParams = getSearchParameters();

  if (getSearchParameters().has(key)) {
    const stringifiedActivePresetKey = getSearchParameters().get(key);

    if (stringifiedActivePresetKey === null) {
      return undefined;
    }
    const activePresetKey = JSON.parse(stringifiedActivePresetKey);
    urlSearchParams.delete(key);
    window.history.replaceState(null, document.title, `/?${urlSearchParams}`);
    return activePresetKey;
  }
}

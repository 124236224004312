import { logger } from '@/utils/libs/logger.ts';

export function getUrlParamAsInt(name: string): number | undefined {
  const value = getSearchParameters().get(name);
  const intValue = parseInt(value as string, 10);
  if (isNaN(intValue)) {
    return undefined;
  }
  return intValue;
}

export function getUrlParamAsBool(name: string): boolean | undefined {
  return getSearchParameters().has(name);
}

export function getSearchParameters(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

export function getUrlParamAsString<T extends string>(name: string): T | undefined {
  return (getSearchParameters().get(name) as T) ?? undefined;
}

export function getUrlParamsObjectValue<T extends Record<any, any> = Record<any, any>>(
  name: string,
): T | undefined {
  if (getSearchParameters().has(name)) {
    const params = getUrlParamAsString(name);
    if (params !== undefined) {
      try {
        return JSON.parse(params);
      } catch (error: unknown) {
        logger.logError('Error happened while trying to get parse params from URL', error);
        return undefined;
      }
    }
    return undefined;
  }
}

import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

import { validateOnePermission } from '@/components/router/core/validateOnePermission.tsx';

const queryToolParamsSchema = z.object({
  query: z.string().optional(),
});

export const Route = createFileRoute('/queryTools')({
  beforeLoad: validateOnePermission('seeDevTools'),
  validateSearch: search => queryToolParamsSchema.parse(search),
  component: lazyRouteComponent(
    () => import('@/components/queryTools/QueryTools.tsx'),
    'QueryTools',
  ),
});

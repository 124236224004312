/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-refresh/only-export-components */
import { getPathFromQueryId } from '@/core/webSocket/queryId.ts';
import type { WsEventEmitter } from '@/core/webSocket/types.ts';
import { selectCurrentInstance } from '@/store/slices/ui/uiSlice.ts';
import { store } from '@/store/store.ts';
import { trafficEventEmitter } from '@/components/equityRisk/traffic/trafficEventEmitter.ts';

export interface ComputeConstants {
  errorMargin: number;
  expectedMillis: number;
  meanRetentionFactor: number;
}

export class TrafficComputer {
  lastTimings: number[] = [];

  constructor(private constants: ComputeConstants) {}

  pushTimingAt(at: number): void {
    this.lastTimings.push(at);
  }

  computeMeanAt(now: number): number {
    this.lastTimings = this.removeBeforeTime(
      now - this.constants.meanRetentionFactor * this.constants.expectedMillis,
    );
    if (this.lastTimings.length === 0) {
      return NaN;
    }

    const latest = this.lastTimings.at(-1)!;
    if (now - latest > this.constants.expectedMillis) {
      return this.computeIntervalMean([...this.lastTimings, now]);
    }

    return this.computeIntervalMean(this.lastTimings);
  }

  private removeBeforeTime(cutoff: number): number[] {
    return this.lastTimings.filter(timing => timing >= cutoff);
  }

  private computeIntervalMean(timings: number[]): number {
    const [first, ...rest] = timings;
    let last = first;
    let sum = 0;
    for (const t of rest) {
      sum += t - last;
      last = t;
    }
    return sum / rest.length;
  }
}

function now(): number {
  return new Date().getTime();
}

export function registerTrafficWatcher(wsEventEmitter: WsEventEmitter) {
  const { errorMargin, meanRetentionFactor, expectedMillis } = selectCurrentInstance(
    store.getState(),
  ).trafficWatcher!;

  let disconnected = false;
  const computer = new TrafficComputer({ errorMargin, meanRetentionFactor, expectedMillis });

  function observe() {
    if (disconnected) {
      return;
    }

    const step = expectedMillis * errorMargin;
    const mean = computer.computeMeanAt(now());
    if (isNaN(mean)) {
      trafficEventEmitter.emit('colorChanged', { color: 'red', mean });
    } else if (mean > expectedMillis + step) {
      trafficEventEmitter.emit('colorChanged', { color: 'orange', mean });
    } else {
      trafficEventEmitter.emit('colorChanged', { color: 'green', mean });
    }
  }

  computer.pushTimingAt(now());
  const interval = setInterval(observe, expectedMillis);

  wsEventEmitter.on('cellSetData', ({ queryId }) => {
    if (isRootQuery(queryId)) {
      trafficEventEmitter.emit('colorChanged', { color: 'green', mean: 0 });
    }
  });
  wsEventEmitter.on('cellData', ({ queryId }) => {
    if (isRootQuery(queryId)) {
      computer.pushTimingAt(now());
    }
  });
  wsEventEmitter.on('close', () => {
    trafficEventEmitter.emit('colorChanged', { color: 'red', mean: 0 });
    disconnected = true;
    clearInterval(interval);
  });
}

function isRootQuery(queryId: string): boolean {
  return getPathFromQueryId(queryId) === '';
}

import {
  forwardRef,
  useCallback,
  useState,
  type ChangeEvent,
  type InputHTMLAttributes,
} from 'react';

interface NumberInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> {
  onChange?: (value: number) => void;
  value?: number;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>((props, ref) => {
  const { onChange, value, ...otherProps } = props;
  const [valueState, setValueState] = useState<string>(`${value}`);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.valueAsNumber;
      const valueStr = e.target.value;

      if (!isNaN(value)) {
        onChange?.(value);
      } else {
        onChange?.(0);
      }
      setValueState(valueStr);
    },
    [onChange],
  );

  return (
    <input
      ref={ref}
      {...otherProps}
      lang="en"
      type="number"
      value={valueState}
      onChange={handleChange}
      autoComplete="off"
    />
  );
});

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import {
  getAllMeasuresIncludingCache,
  getMeasureType,
  type AllMeasureId,
} from '@/core/measures.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import {
  selectRuleByIndex,
  setRuleForColumnThunk,
  type ColorRule,
  type ColorRuleOperator,
  type ColorRuleScope,
} from '@/store/slices/colorRules/colorsRulesSlice.ts';
import { AutoCompleteCombo } from '@/components/common/bootstrap/AutoCompleteCombo.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';
import { NumberInput } from '@/components/common/components/NumberInput.tsx';
import { useMeasureFormat } from '@/components/common/hooks/useMeasureFormat.tsx';
import {
  backgroundColors,
  textColors,
  type SgBackgroundColor,
  type SgTextColor,
} from '@/components/prefs/tabs/ColorRules/colors.ts';
import { getCurrencyLabel } from '@/utils/libs/currency.ts';

interface ColorRuleEditProps {
  editIndex: number;
  onCancel: any;
  onClose: () => void;
}

const allMeasures = getAllMeasuresIncludingCache();

export function ColorRuleEdit({ editIndex, onClose, onCancel }: ColorRuleEditProps) {
  const currentRule = useAppSelector(selectRuleByIndex(editIndex));
  const currency = useAppSelector(state => state.userPreferences.currencyValue);
  const formatMeasure = useMeasureFormat();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  function change<T extends keyof ColorRule>(key: T, value: ColorRule[T]) {
    const rule: ColorRule = {
      ...currentRule,
      [key]: value,
    };

    dispatch(setRuleForColumnThunk(rule, editIndex));
  }

  function onChangeBackground(color: SgBackgroundColor | undefined) {
    change('bgColor', color);
  }

  function onChangeTextColor(color: SgTextColor | undefined) {
    change('textColor', color);
  }

  function onChangeRuleScope(scope: ColorRuleScope) {
    if (scope === 'allColumns') {
      const rule: ColorRule = {
        ...currentRule,
        scope: scope,
      };
      dispatch(setRuleForColumnThunk(rule, editIndex));
    } else {
      const rule: ColorRule = {
        ...currentRule,
        scope: scope,
        coloredMeasureId: 'UnderlyerSpotRT',
        conditionMeasureId: 'UnderlyerSpotRT',
      };
      dispatch(setRuleForColumnThunk(rule, editIndex));
    }
  }

  function onChangeColoredMeasure(coloredMeasureId: AllMeasureId) {
    if (currentRule.scope === 'singleColumn') {
      dispatch(setRuleForColumnThunk({ ...currentRule, coloredMeasureId }, editIndex));
    }
  }

  function onChangeTargetMeasure(conditionMeasureId: AllMeasureId) {
    if (currentRule.scope === 'singleColumn') {
      dispatch(setRuleForColumnThunk({ ...currentRule, conditionMeasureId }, editIndex));
    }
  }

  function onChangeThreshold(threshold: number) {
    change('threshold', threshold);
  }

  function onChangeOperator(operator: ColorRuleOperator) {
    change('operator', operator);
  }

  function onChangeName(name: string) {
    change('name', name);
  }

  const unit = getUnitForRule(currentRule, currency);

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex justify-content-end gap-2">
        <Button flat variant="primary" className="btn-icon-start my-3" onClick={onClose}>
          <em className="icon">done</em>
          Confirm
        </Button>
        <Button flat variant="secondary" className="btn-icon-start my-3" onClick={onCancel}>
          <em className="icon">cancel</em>
          Cancel
        </Button>
      </div>

      <div className="input-container">
        <label htmlFor="ruleName">Rules name</label>
        <input
          id="ruleName"
          className="form-control"
          type="text"
          value={currentRule.name}
          onChange={e => onChangeName(e.target.value)}
        />
      </div>

      <ToggleButton
        formatId="ColorRules.Scope"
        activeValue={currentRule.scope}
        values={['allColumns', 'singleColumn']}
        formatter={type => formatMessage({ id: `ColorRules.Scope.${type}` })}
        onClick={onChangeRuleScope}
      />

      {currentRule.scope === 'singleColumn' && (
        <div className="input-container">
          <label htmlFor="measure">Column to color</label>
          <AutoCompleteCombo<AllMeasureId>
            id="measure"
            initialItems={allMeasures}
            selectedItem={currentRule.coloredMeasureId}
            onChange={onChangeColoredMeasure}
            itemToString={formatMeasure}
          />
        </div>
      )}

      {currentRule.scope === 'singleColumn' && (
        <div className="input-container">
          <label htmlFor="conditionMeasure">Condition column</label>
          <AutoCompleteCombo<AllMeasureId>
            id="conditionMeasure"
            initialItems={allMeasures}
            selectedItem={currentRule.conditionMeasureId}
            onChange={onChangeTargetMeasure}
            itemToString={formatMeasure}
          />
        </div>
      )}

      <div className="input-container">
        <label htmlFor="operator">Operator</label>
        <Select
          id="operator"
          itemsAsObjects={['greaterThan', 'lesserThan']}
          selectedItem={currentRule.operator}
          itemToString={(item: ColorRuleOperator) =>
            formatMessage({ id: `ColorRules.Operator.${item}` })
          }
          onChange={onChangeOperator}
        />
      </div>

      <div className="input-container">
        <label htmlFor="threshold">Threshold {unit}</label>
        <NumberInput
          id="threshold"
          className="form-control"
          value={currentRule.threshold}
          onChange={onChangeThreshold}
        />
      </div>

      <div className="display-4 mb-3">Background Color</div>
      <ColorPicker
        colors={backgroundColors}
        selectedColor={currentRule.bgColor}
        onChange={color => onChangeBackground(color)}
      />

      <div className="display-4 my-3">Text Color</div>
      <ColorPicker
        colors={textColors}
        selectedColor={currentRule.textColor}
        onChange={color => onChangeTextColor(color)}
      />
    </div>
  );
}

function getUnitForRule(rule: ColorRule, currency: string): string | undefined {
  if (rule.scope === 'allColumns') {
    return '(unit depends on column)';
  }
  if (rule.conditionMeasureId === undefined) {
    return undefined;
  }
  const measureType = getMeasureType(rule.conditionMeasureId);
  switch (measureType) {
    case 'percentage':
      return '(in %)';
    case 'currency':
      return `(in k${getCurrencyLabel(currency)})`;
    default:
      return undefined;
  }
}

interface ColorPickerProps<T extends string> {
  colors: readonly T[];
  selectedColor: T | undefined;
  onChange: (color: T | undefined) => void;
}

function ColorPicker<T extends string>({ onChange, colors, selectedColor }: ColorPickerProps<T>) {
  return (
    <div className="d-flex gap-1 flex-wrap">
      {colors.map(color => {
        return (
          <ColorCard
            key={color}
            color={color}
            onClick={() => onChange(color)}
            selectedColor={selectedColor}
          />
        );
      })}
      <ColorCard
        color={undefined}
        onClick={() => onChange(undefined)}
        selectedColor={selectedColor}
      ></ColorCard>
    </div>
  );
}

function ColorCard<T extends string>({
  color,
  onClick,
  selectedColor,
}: {
  color: T | undefined;
  onClick: () => void;
  selectedColor: T | undefined;
}) {
  const colorClass = color !== undefined ? color.replace('text-', 'bg-') : 'bg-transparent';
  return (
    <div
      title={color ?? 'No color'}
      onClick={onClick}
      className={classNames('cursor-pointer color-card', {
        selected: selectedColor === color,
      })}
    >
      <div className={classNames(colorClass, 'inner cursor-pointer')}>
        {color === undefined && <em className="icon cursor-pointer icon-md text-danger">close</em>}
      </div>
    </div>
  );
}

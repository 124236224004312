import groupBy from 'just-group-by';
import intersect from 'just-intersect';

import { objectEntries } from '@/utils/libs/entries.ts';

const separator = '.';
const subPerimeterKey = (perimeterKey: string, start: number, end?: number): string =>
  perimeterKey.split(separator).slice(start, end).join(separator);

export function isPerimeterKeyEqualOrReduced(
  maybeReducedPerimeterKey: string,
  perimeterKey: string,
): boolean {
  const splitMaybeReducedPerimeterKey = maybeReducedPerimeterKey.split(separator);
  const splitPerimeterKey = perimeterKey.split(separator);
  if (splitMaybeReducedPerimeterKey.length > splitPerimeterKey.length) {
    return false;
  }
  return splitMaybeReducedPerimeterKey.every((key, index) => key === splitPerimeterKey[index]);
}
//  Current and children
export function reducePerimeterKeys(
  fullPerimeterKeys: string[],
  selectedPerimeterKeys: string[],
): string[] {
  const reducedPerimeterKeys: string[] = [];
  if (fullPerimeterKeys.length === 0 || selectedPerimeterKeys.length === 0) {
    return reducedPerimeterKeys;
  }
  // The keys should be homogenous
  const perimeterKeyLength = fullPerimeterKeys[0].split(separator).length;

  for (let currentKeyIndex = 0; currentKeyIndex < perimeterKeyLength - 1; currentKeyIndex++) {
    //subPerimeterKey('pc/gop/pf') =>  ( currentKeyIndex=0 : pc )  | (currentKeyIndex=1: - pc/gop)
    const fullPerimeterGroupKeys = groupBy(fullPerimeterKeys, key =>
      subPerimeterKey(key, 0, currentKeyIndex + 1),
    );
    const selectedPerimeterGroupKeys = groupBy(selectedPerimeterKeys, key =>
      subPerimeterKey(key, 0, currentKeyIndex + 1),
    );

    for (const [fullPerimeterGroupKey, fullPerimeterGroupValues] of objectEntries(
      fullPerimeterGroupKeys,
    )) {
      const isAlreadyReduced = reducedPerimeterKeys.some(key =>
        isPerimeterKeyEqualOrReduced(key, fullPerimeterGroupKey),
      );

      if (isAlreadyReduced) {
        continue;
      }
      const selectedPerimeterGroupValues = selectedPerimeterGroupKeys[fullPerimeterGroupKey];
      if (selectedPerimeterGroupValues == null) {
        continue;
      }

      //subPerimeterKey('pc/gop/pf') => ( currentKeyIndex=0  : gop/pf )  | (currentKeyIndex=1: - pf )
      const selectedPerimeterChildrenKeys = selectedPerimeterGroupValues.map(key =>
        subPerimeterKey(key, currentKeyIndex + 1),
      );
      const fullPerimeterChildrenKeys = fullPerimeterGroupValues.map(key =>
        subPerimeterKey(key, currentKeyIndex + 1),
      );

      const childrenKeysIntersection = intersect(
        selectedPerimeterChildrenKeys,
        fullPerimeterChildrenKeys,
      );

      // Can be reduced - All children are equal
      if (childrenKeysIntersection.length === fullPerimeterChildrenKeys.length) {
        reducedPerimeterKeys.push(fullPerimeterGroupKey);
      }
      // Cannot be reduced, we will go deeper in the perimeterKey.
      else {
        if (currentKeyIndex === perimeterKeyLength - 2) {
          reducedPerimeterKeys.push(...selectedPerimeterGroupValues);
        }
      }
    }
  }
  return reducedPerimeterKeys;
}

// Allowed perimeters are either bigger perimeters ( less precise - the back end will return to appropriate data ),
// or exactly the full path intersection between allowedPerimeters and  activePerimeters  when it's not reduced
export function getAllowedActivePerimeters(
  activePerimeters: string[],
  allowedPerimeters: string[],
) {
  return activePerimeters.filter(activePerimeter =>
    allowedPerimeters.some(allowedReducedPerimeter =>
      isPerimeterKeyEqualOrReduced(activePerimeter, allowedReducedPerimeter),
    ),
  );
}

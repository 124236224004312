import { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { uiSlice, type ToastMessage } from '@/store/slices/ui/uiSlice.ts';

interface ToastProps {
  message: ToastMessage;
  variant?: 'primary' | 'danger' | 'info' | 'success';
}

export function Toast({ message, variant = 'primary' }: ToastProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { title, body, time } = message;

  const onClose = useCallback(() => {
    dispatch(uiSlice.actions.deleteToast(message.id));
  }, [message, dispatch]);

  const formattedTime =
    time !== undefined ? format(parseISO(time), 'dd LLL yyyy - HH:mm') : undefined;

  return (
    <div
      className={`toast toast-${variant} fade show mb-1`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <div className="fw-medium">
          {typeof title === 'string' ? (
            title
          ) : (
            <FormattedMessage id={title.id} values={title.values} />
          )}
        </div>
        <button
          type="button"
          className="mb-1 btn-close ms-auto js"
          data-dismiss="toast"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
      <div className="toast-body multi-line overflow-auto">
        {typeof body === 'string' ? body : <FormattedMessage id={body.id} values={body.values} />}
      </div>
      <div className="toast-footer d-flex flex-column">
        {formattedTime !== undefined ? (
          <div className="text-secondary fw-medium">{formattedTime}</div>
        ) : null}
      </div>
    </div>
  );
}

export function Toasts(): JSX.Element {
  const toastMessages = useAppSelector(state => state.ui.toastMessages);

  return (
    <div style={{ position: 'absolute', top: 70, right: 20, zIndex: 100 }}>
      {toastMessages.map(toastMessage => (
        <Toast
          key={toastMessage.id}
          message={toastMessage}
          variant={toastMessage.type.severity === 'error' ? 'danger' : 'primary'}
        />
      ))}
    </div>
  );
}

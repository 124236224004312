import { useState } from 'react';
import { useRouter, useSearch } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';

import {
  Currencies,
  DeactivatedValues,
  useCreateScenarioMutation,
  useGetScenariosQuery,
  type CurrencyType,
  type DeactivatedValuesType,
  type MarketConfigs,
  type Scenario,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { MultiselectDropdown } from '@/components/common/bootstrap/MultiselectDropdown.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { EditScenarioGrid } from '@/components/hedger/manage/scenarios/EditScenarioGrid.tsx';
import type { MutationTrigger } from '@/utils/libs/api.ts';
import type { StrictOmit } from '@/utils/libs/strictTypes.ts';
import { isQueryHookResultReady } from '@/utils/rtk/predicates.ts';
import { getUserInfo } from '@/utils/sgwt/userPermission.ts';

const getScenarioInputId = (key: keyof Scenario): string => `createScenario_${key}`;
type ScenarioInputs = StrictOmit<Scenario, 'scenarioId' | 'marketConfigs'>;
function getScenarioInit(
  teams: string[],
  scenarioInit?: Scenario,
): StrictOmit<Scenario, 'scenarioId'> {
  if (scenarioInit === undefined) {
    return {
      userId: getUserInfo()?.sesame_id ?? '',
      team: teams[0] ?? '',
      deactivatedProductTypes: ['UNKNOWN', 'INDEX', 'BASKET'],
      timeZone: '',
      groupedGopsRules: [],
      ordersMetricsCurrency: 'USD',
      name: '',
      deactivatedTickers: [],
      outputOptions: [],
      marketConfigs: {},
    };
  }
  return {
    ...scenarioInit,
    userId: getUserInfo()?.sesame_id ?? '',
    team: (teams.includes(scenarioInit.team) ? scenarioInit.team : teams[0]) ?? '',
    name: '',
  };
}

export function CreateScenario(): JSX.Element {
  const teams = useAppSelector(userSlice.selectors.teams);
  const [createScenarioTrigger, { isLoading, isError, originalArgs }] = useCreateScenarioMutation();
  const { navigate } = useRouter();
  const closeScenario = () => navigate({ to: '/hedger/scenarios' });
  const getScenarioResult = useGetScenariosQuery();
  const { cloneId } = useSearch({ from: '/hedger/scenarios/create' });

  if (isLoading || !isQueryHookResultReady(getScenarioResult)) {
    return <Loader />;
  }
  if (getScenarioResult.isError) {
    return <FetchErrorView error={getScenarioResult.error} />;
  }

  const scenarios = getScenarioResult.data?.scenarios;
  const scenarioToClone = scenarios.find(scenario => scenario.scenarioId === cloneId);

  if (cloneId !== undefined && scenarioToClone === undefined) {
    return (
      <ErrorPanel>
        No scenario of id <span className="fw-semibold">{cloneId}</span> .
      </ErrorPanel>
    );
  }

  const { marketConfigs, ...scenarioInputs } =
    isError && originalArgs !== undefined ? originalArgs : getScenarioInit(teams, scenarioToClone);

  return (
    <CreateScenarioFrame
      createScenario={createScenarioTrigger}
      closeScenario={closeScenario}
      scenarioInputsInit={scenarioInputs}
      marketConfigsInit={marketConfigs}
      teams={teams}
    />
  );
}

interface CreateScenarioFrameProps {
  createScenario: MutationTrigger<Omit<Scenario, 'scenarioId'>, Scenario>;
  closeScenario: () => void;
  scenarioInputsInit: ScenarioInputs;
  marketConfigsInit: MarketConfigs;
  teams: string[];
}

function CreateScenarioFrame({
  createScenario,
  closeScenario,
  scenarioInputsInit,
  marketConfigsInit,
  teams,
}: CreateScenarioFrameProps) {
  const [currentScenarioInputs, setCurrentScenarioInputs] =
    useState<ScenarioInputs>(scenarioInputsInit);
  const [currentMarketConfigs, setCurrentMarketConfigs] =
    useState<MarketConfigs>(marketConfigsInit);

  const onCreateClick = async () => {
    createScenario({
      marketConfigs: currentMarketConfigs,
      ...currentScenarioInputs,
    })
      .unwrap()
      .then(closeScenario)
      .catch(error => handleHedgerError('Error while creating scenario', error));
  };

  return (
    <div
      className="d-flex flex-column mx-auto flex-grow-1 w-75 gap-3 mb-3"
      style={{ minWidth: '60rem' }}
    >
      <div className="d-flex flex-column bg-lvl2 gap-3 p-3 border flex-grow-1 border-info">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-info fw-semibold">
            <FormattedMessage id={'Hedger.ScenariosModal.CreateScenario'} />
          </h4>
          <div className="d-flex gap-2">
            <Button size="lg" onClick={closeScenario}>
              <FormattedMessage id="Hedger.ScenariosModal.CreateFrame.Cancel" />
            </Button>
            <Button
              size="lg"
              discreet={true}
              className="btn-discreet-info "
              onClick={onCreateClick}
            >
              <FormattedMessage id="Hedger.ScenariosModal.CreateFrame.Create" />
            </Button>
          </div>
        </div>

        <div className="d-flex flex-wrap from-group-container flex-wrap column-gap-5">
          <div className="form-group-deprecated">
            <label htmlFor={getScenarioInputId('name')} className="form-label">
              <FormattedMessage id="Hedger.Scenario.Name" />
            </label>
            <input
              className="form-control form-control"
              value={currentScenarioInputs.name}
              onChange={event =>
                setCurrentScenarioInputs(state => ({ ...state, name: event.target.value }))
              }
              type="text"
              id={getScenarioInputId('name')}
            />
          </div>

          <div className="form-group-deprecated ">
            <label htmlFor={getScenarioInputId('team')} className="form-label">
              Owner team
            </label>
            <Select<string>
              id={getScenarioInputId('team')}
              itemsAsObjects={teams}
              selectedItem={currentScenarioInputs.team}
              onChange={team => setCurrentScenarioInputs(state => ({ ...state, team }))}
            />
          </div>
          <div className="form-group-deprecated">
            <label htmlFor={getScenarioInputId('ordersMetricsCurrency')} className="form-label">
              Currency
            </label>
            <Select<CurrencyType>
              id={getScenarioInputId('ordersMetricsCurrency')}
              itemsAsObjects={Currencies}
              selectedItemValue={currentScenarioInputs.ordersMetricsCurrency}
              onChange={currency =>
                setCurrentScenarioInputs(state => ({ ...state, ordersMetricsCurrency: currency }))
              }
            />
          </div>

          <MultiselectDropdown<DeactivatedValuesType>
            selectedItems={currentScenarioInputs.deactivatedProductTypes}
            itemsToFreeze={['UNKNOWN', 'INDEX', 'BASKET']}
            items={DeactivatedValues}
            onChange={deactivatedProductTypes => {
              setCurrentScenarioInputs(currentState => ({
                ...currentState,
                deactivatedProductTypes,
              }));
            }}
            label={'Deactivated product type'}
          />
        </div>
        <EditScenarioGrid
          currency={currentScenarioInputs.ordersMetricsCurrency}
          marketConfigsDefault={marketConfigsInit}
          onMarketConfigsUpdate={setCurrentMarketConfigs}
        />
      </div>
    </div>
  );
}

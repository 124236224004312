import './App.css';

import { Provider as ModalProvider } from '@ebay/nice-modal-react';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { Provider } from 'react-redux';

import { store } from '@/store/store.ts';
import { IntlProvider } from '@/components/common/context/IntlContext.tsx';
import { matomo } from '@/utils/matomo/matomo.ts';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import './ag-grid-theme-era.css';

import { SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';

import { RouterProvider } from '@/web/router/router.tsx';

export function App() {
  return (
    // <StrictMode>
    <SgwtWidgetContextProvider infrastructure="azure">
      <Provider store={store}>
        <IntlProvider>
          <MatomoProvider value={matomo}>
            <ModalProvider>
              <RouterProvider />
            </ModalProvider>
          </MatomoProvider>
        </IntlProvider>
      </Provider>
    </SgwtWidgetContextProvider>
    // </StrictMode>
  );
}

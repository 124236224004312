import type { ColDef, ValueFormatterParams, ValueSetterParams } from '@ag-grid-community/core';
import { asSequence } from 'sequency';

import type { MarketRef } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import type { CurrencyType, MarketConfig } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { Checkbox } from '@/components/common/bootstrap/Checkbox.tsx';
import type { ColumnTable } from '@/components/common/bootstrap/Table.tsx';
import { DeleteButton } from '@/components/hedger/manage/scenarios/DeleteButton.tsx';
import type { MarketConfigRowData } from '@/components/hedger/manage/scenarios/ViewScenarios.tsx';
import { ActiveSwitcher } from '@/components/hedger/order/ActiveSwitcher.tsx';
import { getCurrencyLabel } from '@/utils/libs/currency.ts';

export type MarketConfigColDef = ColDef<MarketConfigRowData>;

export const marketConfigsColDef: MarketConfigColDef[] = [
  {
    field: 'marketId',
    headerName: 'Market',
  },
  {
    field: 'active',
    headerName: 'Active',
    editable: false,
    autoHeight: true,
    cellRenderer: ActiveSwitcher,
  },
  { field: 'hedgePercent', headerName: '% to Hedge' },
  {
    field: 'greek',
    headerName: 'Greek',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['REAL_TIME', 'FIXING', 'INIT'],
    },
  },
  {
    field: 'advParticipationPercent',
    headerName: 'ADV limit',
  },
  { field: 'limitMin', headerName: 'Min order' },
  { field: 'limitMax', headerName: 'Max order' },
  {
    field: 'recipientId',
    headerName: 'Recipient',
    minWidth: 180,
  },
];
export function getViewScenarioColumnTables(
  recipients: Recipient[],
  currency: CurrencyType,
): ColumnTable<keyof MarketConfig>[] {
  const recipientsById = asSequence(recipients).associateBy(r => r.recipientId);
  const recipientValueFormatter = getRecipientValueFormatter(recipientsById);

  return marketConfigsColDef.map(marketConfig => ({
    field: marketConfig.field as keyof MarketConfig,
    headerName:
      marketConfig.field === 'limitMin' || marketConfig.field === 'limitMax'
        ? `${marketConfig.headerName} (k${getCurrencyLabel(currency)})`
        : marketConfig.headerName ?? marketConfig.field!,
    valueFormatter:
      marketConfig.field === 'recipientId'
        ? recipientValueFormatter
        : getValueFormatter(marketConfig.field),

    Component:
      marketConfig.field! !== 'active'
        ? undefined
        : ({ value }: { value: MarketConfig['active'] }) => <Checkbox checked={value} />,
  }));
}

export function getEditMarketConfigsColDef(
  recipients: Recipient[],
  marketRefs: MarketRef[],
  currency: CurrencyType,
): MarketConfigColDef[] {
  const currentMarketConfigsColDef: MarketConfigColDef[] = [];

  for (const colDef of marketConfigsColDef) {
    let item: MarketConfigColDef;
    const field = colDef.field;
    if (field === 'marketId') {
      item = { ...colDef, ...getSelectMarketColDef(marketRefs) };
    } else if (field === 'recipientId') {
      item = { ...colDef, ...getSelectRecipientColDef(recipients) };
    } else if (field === 'limitMin' || field === 'limitMax') {
      item = {
        editable: true,
        valueFormatter: ({ value }) => numberFormatter.format(value),
        valueGetter: ({ data }) => (data === undefined ? undefined : data[field] / 1000),
        valueSetter: ({ newValue, data }) => {
          data[field] = newValue * 1000;
          return true;
        },
        initialWidth: 150,
        ...colDef,
        headerName: `${colDef.headerName} (k${getCurrencyLabel(currency)})`,
      };
    } else {
      item = {
        editable: true,
        valueFormatter: getValueFormatter(field),
        initialWidth: 150,
        ...colDef,
      };
    }
    currentMarketConfigsColDef.push(item);
  }

  currentMarketConfigsColDef.push({
    field: 'delete',
    headerName: 'Delete',
    cellRenderer: DeleteButton,
    editable: false,
    width: 100,
  });

  return currentMarketConfigsColDef;
}

// FIXME
const numberFormatter = Intl.NumberFormat('fr');

export function getRecipientValueFormatter(recipients: Map<number, Recipient>) {
  return (params: Partial<ValueFormatterParams<MarketConfigRowData>>): string => {
    const recipientId = params.value;
    const recipientLabel = recipients.get(recipientId)?.recipientLabel;
    return recipientLabel ?? '';
  };
}

const getSelectRecipientColDef = (recipients: Recipient[]): MarketConfigColDef => {
  const recipientsById = asSequence(recipients).associateBy(r => r.recipientId);
  return {
    field: 'recipientId',
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: recipients.map(recipient => recipient.recipientId),
      searchType: 'match',
      allowTyping: true,
      filterList: true,
      highlightMatch: true,
    },
    cellEditorPopup: true,
    valueSetter: (params: ValueSetterParams<MarketConfigRowData>) => {
      params.data.recipientId = Number.parseInt(params.newValue);
      return true;
    },
    valueFormatter: getRecipientValueFormatter(recipientsById),
    editable: true,
  };
};

const getSelectMarketColDef = (marketRefs: MarketRef[]): MarketConfigColDef => ({
  field: 'marketId',
  headerName: 'Market',
  cellEditor: 'agRichSelectCellEditor',
  cellEditorParams: {
    values: marketRefs.map(marketRef => marketRef.marketId),
    searchType: 'match',
    allowTyping: true,
    filterList: true,
    highlightMatch: true,
  },
  cellEditorPopup: true,
  editable: true,
});

export function getValueFormatter(
  field: ColDef<MarketConfigRowData>['field'],
): (({ value }: Partial<ValueFormatterParams>) => string) | undefined {
  if (field === 'limitMin' || field === 'limitMax') {
    return ({ value }: Partial<ValueFormatterParams>) => numberFormatter.format(value / 1000);
  } else if (field === 'hedgePercent' || field === 'advParticipationPercent') {
    return ({ value }: Partial<ValueFormatterParams>) => value * 100 + '%';
  } else if (field === 'active') {
    return ({ value }: Partial<ValueFormatterParams>) => (value ? 'Yes' : 'No');
  } else {
    return undefined;
  }
}

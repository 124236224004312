import { createFileRoute } from '@tanstack/react-router';

import { rulesApi } from '@/store/api/rulesApi/rulesApi.ts';
import { rulesCategories, type RuleCategory } from '@/store/api/rulesApi/rulesModels.ts';
import { store } from '@/store/store.ts';
import { validateOnePermission } from '@/components/router/core/validateOnePermission.tsx';
import { RulesCategoryComponent } from '@/components/rules/RulesCategoryComponent.tsx';

function isRuleCategory(category: string): category is RuleCategory {
  return rulesCategories.includes(category as any);
}

function validateRuleCategory(params: Record<'category', string>): { category: RuleCategory } {
  if (isRuleCategory(params.category)) {
    return {
      category: params.category,
    };
  }
  throw new Error(`Could not parse rule category ${params.category}`);
}

export const Route = createFileRoute('/rules/$category')({
  component: RulesCategoryComponent,
  parseParams: rawParams => validateRuleCategory(rawParams),
  beforeLoad: validateOnePermission('viewRules', 'devci'),
  loader: async ({ params }) => {
    const rules = await store.dispatch(
      rulesApi.endpoints.getRulesByCategory.initiate(params.category),
    );
    return {
      rules: rules.data!,
    };
  },
});

import { redirect } from '@tanstack/react-router';

import { userSlice } from '@/store/slices/user/userSlice.ts';
import { store } from '@/store/store.ts';

export function authorizeIfUserHasTeam() {
  const hasTeam = userSlice.selectors.hasAnyTeam(store.getState());
  if (!hasTeam) {
    throw redirect({ to: '/notAuthorized', replace: true });
  }
}

import {
  getAllMeasureIds,
  getAllMeasuresIncludingCache,
  type AllMeasureId,
  type MeasureId,
} from '@/core/measures.ts';

export function isHierarchyColumn(colId: string): boolean {
  return colId === 'Hierarchy';
}

export function isColIdValid(colId: string) {
  return isHierarchyColumn(colId) || isColIdMeasureId(colId);
}

export function isColIdMeasureId(colId: string): colId is AllMeasureId {
  return getAllMeasuresIncludingCache().includes(colId as unknown as any);
}

export function isColIdStrictMeasureId(colId: string): colId is MeasureId {
  return getAllMeasureIds().includes(colId as unknown as any);
}

import { useEffect, useId, useState, type Dispatch, type SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type {
  FilterKind,
  HedgerOrderDefaultRelativeEndTime,
  HedgerOrderDefaultRelativeStartTime,
  HedgerOrderDefaultTime,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { exclusionModes, type ExclusionMode } from '@/store/slices/query/querySlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { TimePicker } from '@/components/common/bootstrap/TimePicker.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';
import type { DataPrefs } from '@/components/prefs/UserPrefs.tsx';
import type { IntlMessages } from '@/types/intl';
import { isProdEnv } from '@/utils/config/configUtils.ts';
import { getSgwtHelpCenter } from '@/utils/sgwt/help-center.ts';

interface DataTabProps {
  dataPrefs: DataPrefs;
  setDataPrefs: Dispatch<SetStateAction<DataPrefs>>;
}

export function DataTab({ dataPrefs, setDataPrefs }: DataTabProps) {
  function openHelpCenter() {
    const topicId = isProdEnv() ? 'top.qc4fdru3' : 'top.nfaa5hl2';
    const widget = getSgwtHelpCenter();
    widget.topic(topicId);
  }

  function userPrefSetter<T extends keyof DataPrefs, U extends DataPrefs[T]>(
    prop: T,
  ): (value: U) => void {
    return value => {
      setDataPrefs({
        ...dataPrefs,
        [prop]: value,
      });
    };
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <ExclusionModeSelector setDataPrefs={setDataPrefs} dataPrefs={dataPrefs} />

      <HedgerOrderTime
        hedgerOrderDefaultTime={dataPrefs.hedgerOrderDefaultTime}
        hedgerDefaultTimeHandler={userPrefSetter('hedgerOrderDefaultTime')}
        setDataPrefs={setDataPrefs}
        dataPrefs={dataPrefs}
      />

      <div className="d-flex align-items-center">
        <ToggleButton<FilterKind>
          formatId={'UserPrefs.Section.filter'}
          activeValue={dataPrefs.filterKind}
          values={['IMPACTING', 'NON_IMPACTING']}
          onClick={userPrefSetter('filterKind')}
        />

        <Button
          flat
          icon
          className="d-flex flex-center"
          aria-label="toggle menu"
          type="button"
          onClick={openHelpCenter}
        >
          <em className="icon icon-md" aria-hidden="true">
            help
          </em>
        </Button>
      </div>
      <div>
        <h3 className="py-2">Custom labels</h3>
        <Input
          label="Hierarchy.DeskCustomAxis1"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis1 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis1: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis2"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis2 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis2: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis3"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis3 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis3: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis4"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis4 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis4: val,
            })
          }
        />
        <Input
          label="Hierarchy.DeskCustomAxis5"
          value={dataPrefs.customHierarchiesLabels.DeskCustomAxis5 ?? ''}
          onChange={val =>
            userPrefSetter('customHierarchiesLabels')({
              ...dataPrefs.customHierarchiesLabels,
              DeskCustomAxis5: val,
            })
          }
        />
      </div>
    </div>
  );
}

interface InputProps {
  label: IntlMessages;
  value: string;
  onChange: (value: string) => void;
}

function Input({ label, value, onChange }: InputProps) {
  const id = label + useId();
  return (
    <div className="form-group-deprecated">
      <label htmlFor={id}>
        <FormattedMessage id={label} />
      </label>
      <input
        className="form-control"
        type="text"
        id={id}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
}

function ExclusionModeSelector(props: {
  dataPrefs: DataPrefs;
  setDataPrefs: Dispatch<SetStateAction<DataPrefs>>;
}) {
  const { formatMessage } = useIntl();

  function handleChange(exclusionMode: ExclusionMode) {
    props.setDataPrefs({
      ...props.dataPrefs,
      exclusionMode,
    });
  }

  return (
    <div className="input-container">
      <label htmlFor="exclusionMode">Exclusion Rule</label>
      <Select<ExclusionMode>
        id="exclusionMode"
        itemsAsObjects={exclusionModes}
        selectedItem={props.dataPrefs.exclusionMode}
        onChange={handleChange}
        itemToString={mode => formatMessage({ id: `ExclusionMode.${mode}` })}
      />
    </div>
  );
}

function HedgerOrderTime(props: {
  hedgerOrderDefaultTime: HedgerOrderDefaultTime;
  hedgerDefaultTimeHandler: (value: HedgerOrderDefaultTime) => void;
  dataPrefs: DataPrefs;
  setDataPrefs: Dispatch<SetStateAction<DataPrefs>>;
}) {
  function onStartTimeChange(value: string) {
    props.setDataPrefs({
      ...props.dataPrefs,
      hedgerOrderDefaultStartTime: value,
    });
  }

  function onEndTimeChange(value: string) {
    props.setDataPrefs({
      ...props.dataPrefs,
      hedgerOrderDefaultEndTime: value,
    });
  }

  const isAbsolute = props.hedgerOrderDefaultTime === 'ABSOLUTE';
  return (
    <div>
      <ToggleButton<HedgerOrderDefaultTime>
        formatId={'UserPrefs.Section.HedgerOrderDefaultTime'}
        activeValue={props.hedgerOrderDefaultTime}
        values={['RELATIVE', 'ABSOLUTE']}
        onClick={props.hedgerDefaultTimeHandler}
      />
      <div className="form-group-deprecated">
        <label>Hedger orders defaults start time UTC</label>

        {isAbsolute ? (
          <div className="py-1">
            <TimePicker
              time={props.dataPrefs.hedgerOrderDefaultStartTime ?? ''}
              onTimeChange={onStartTimeChange}
            />
          </div>
        ) : (
          <RelativeOrderTimePicker
            value={props.dataPrefs.hedgerOrderDefaultRelativeStartTime}
            onBlur={value =>
              props.setDataPrefs(previousState => ({
                ...previousState,
                hedgerOrderDefaultRelativeStartTime: value,
              }))
            }
          />
        )}
      </div>
      <div className="form-group-deprecated">
        <label>Hedger orders defaults end time UTC</label>
        {isAbsolute ? (
          <div className="py-1">
            <TimePicker
              time={props.dataPrefs.hedgerOrderDefaultEndTime ?? ''}
              onTimeChange={onEndTimeChange}
            />
          </div>
        ) : (
          <RelativeOrderTimePicker
            value={props.dataPrefs.hedgerOrderDefaultRelativeEndTime}
            onBlur={value =>
              props.setDataPrefs(previousState => ({
                ...previousState,
                hedgerOrderDefaultRelativeEndTime: value,
              }))
            }
          />
        )}
      </div>
    </div>
  );
}

function RelativeOrderTimePicker(props: {
  value: HedgerOrderDefaultRelativeStartTime | HedgerOrderDefaultRelativeEndTime;
  onBlur: (value: HedgerOrderDefaultRelativeStartTime | HedgerOrderDefaultRelativeEndTime) => void;
}) {
  const [state, setState] = useState<
    HedgerOrderDefaultRelativeStartTime | HedgerOrderDefaultRelativeEndTime
  >('');

  useEffect(() => {
    setState(props.value);
  }, [props.value]);

  return (
    <div className="d-flex gap-2">
      <div className="d-flex gap-2">
        <label className="text-secondary fw-medium form-label">Export Time UTC +</label>
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-icon-start">
            <em className="icon">schedule</em>
          </span>
        </div>
        <input
          type="number"
          className="form-control"
          onChange={e => setState(e.target.value)}
          onBlur={e => props.onBlur(e.target.value)}
          value={state ?? ''}
        />
      </div>
      <label className="text-secondary fw-medium form-label">minutes</label>
    </div>
  );
}

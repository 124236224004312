export const currencyValues = ['EUR', 'USD'] as const;
export type CurrencyValue = (typeof currencyValues)[number];

export function getCurrencyLabel(currency: string) {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
  }
}

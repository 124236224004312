import { createFileRoute } from '@tanstack/react-router';

import { analyticalStructureLoader } from '@/web/router/analyticalStructureLoader.ts';
import { validateOnePermission } from '@/components/router/core/validateOnePermission.tsx';
import { RulesPage } from '@/components/rules/RulesPage.tsx';

export const Route = createFileRoute('/rules')({
  component: RulesPage,
  loader: analyticalStructureLoader,
  // beforeLoad: validateOnePermission('viewRules'),
});

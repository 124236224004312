import { getUnregisterQueryRequest } from '@/core/query/activePivortRequests.ts';
import type { RegisterQueryRequestParams } from '@/core/webSocket/types.ts';
import type { MdxResponse, MdxStateData } from '@/types/mdx.ts';
import { getAuthorizationToken } from '@/utils/sgwt/sgwtConnect.ts';

export function getGreatServerResponse(
  query: RegisterQueryRequestParams,
  wsUrl: string,
): Promise<MdxStateData> {
  const queryId = query.data.queryId;
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(wsUrl + '?sgx=' + getAuthorizationToken());

    ws.onmessage = ({ data }) => {
      const message: MdxResponse = JSON.parse(data);

      if ('error' in message) {
        const messageError = message.error;
        console.error(message.error);
        if (typeof messageError === 'string') {
          reject(new Error(messageError));
        } else {
          reject(new Error(messageError.errorChain.map(e => e.message).join('\n') ?? 'Error'));
        }
      } else if (message.type === 'cellSetData' && message.queryId === queryId) {
        resolve(message.data);
      }
      ws.send(JSON.stringify(getUnregisterQueryRequest(queryId)));
      ws.close();
    };

    ws.onerror = () => {
      reject('Could not connect to the server');
    };

    ws.onopen = () => {
      ws.send(JSON.stringify(query));
    };
  });
}

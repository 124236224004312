import { memo, useCallback, type ChangeEvent, type ChangeEventHandler } from 'react';

import { Button } from '@/components/common/bootstrap/Button.tsx';
import type { TableData } from '@/components/hedger/manage/recipients/details/MultiEditTable.tsx';

export type Updater<T extends RowData[] | TableData[]> = (previousRowData: T) => T;

interface TableProps {
  rowDatas: RowData[];
  onUpdate: (updater: Updater<RowData[]>) => void;
}
export type RowData = {
  id: string;
  value: string;
  label: string;
};
export function EditTable({ rowDatas, onUpdate }: TableProps) {
  const onAddValue = useCallback(() => {
    onUpdate(previousRowDatas => [
      ...previousRowDatas,
      { id: `newline_${[previousRowDatas.length]}`, label: '', value: '' },
    ]);
  }, [onUpdate]);

  const onDelete = useCallback(
    (id: string) =>
      onUpdate(previousRowDatas => previousRowDatas.filter(rowData => rowData.id !== id)),
    [onUpdate],
  );
  const onChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      id: string,
      columnToUpdate: 'label' | 'value',
    ): ChangeEventHandler<HTMLInputElement> | undefined => {
      const update = event.currentTarget.value;
      if (update === undefined) {
        return;
      }
      onUpdate(previousRowDatas =>
        previousRowDatas.map(rowData => {
          if (rowData.id === id) {
            return { ...rowData, [columnToUpdate]: update };
          }
          return rowData;
        }),
      );
    },
    [onUpdate],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th>Label</th>
            <th>Value</th>
            <th className="text-center p-0">
              <i className="icon icon-sm">delete_outline</i>
            </th>
          </tr>
        </thead>
        <tbody>
          {rowDatas.map(({ id }) => {
            const rowData = rowDatas.find(tableData => tableData.id === id);
            return (
              <MemoEditRowData key={id} onChange={onChange} rowData={rowData} onDelete={onDelete} />
            );
          })}
        </tbody>
      </table>
      <Button className="d-flex gap-3" onClick={onAddValue}>
        <i className="icon icon-sm">add</i>
        Add value
      </Button>
    </div>
  );
}

const MemoEditRowData = memo(EditRowData);
type EditRowDataProps = {
  rowData?: RowData;
  onDelete: any;
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
    columnToUpdate: 'label' | 'value',
  ) => ChangeEventHandler<HTMLInputElement> | undefined;
};

function EditRowData(editRowDataProps: EditRowDataProps): JSX.Element | null {
  const { rowData, onChange, onDelete } = editRowDataProps;
  if (rowData === undefined) {
    return null;
  }
  const { id, label, value } = rowData;
  return (
    <tr key={id}>
      <td className="d-flex p-0 justify-content-center align-items-center align-items-stretch">
        <input
          className="d-flex form-control w-100  h-100 align-self-stretch"
          onChange={event => onChange(event, id, 'label')}
          type="text"
          value={label}
          id={id}
        />
      </td>
      <td className="p-0">
        <input
          className="d-flex  form-control "
          onChange={event => onChange(event, id, 'value')}
          type="text"
          value={value}
          id={id}
        />
      </td>
      <td width={'45px'} className="p-0">
        <Button
          className="d-flex btn btn-md btn-flat-secondary"
          flat
          variant="danger"
          onClick={() => onDelete(id)}
        >
          <i className="icon icon-sm">delete_outline</i>
        </Button>
      </td>
    </tr>
  );
}

import { FormattedMessage } from 'react-intl';

import type { IntlMessages } from '@/types/intl';

export function NoAnalysis({
  title,
  message,
}: {
  title: IntlMessages;
  message: IntlMessages;
}): JSX.Element {
  return (
    <div className="d-flex text-center text-secondary p-3  flex-grow-1">
      <div className="d-flex align-items-center justify-content-center flex-grow-1">
        <div>
          <em className="icon icon-xl">search</em>
          <h4>
            <FormattedMessage id={title} />
          </h4>
          <p>
            <FormattedMessage id={message} />
          </p>
        </div>
      </div>
    </div>
  );
}

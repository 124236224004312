import { useEffect } from 'react';
import { getRouteApi } from '@tanstack/react-router';

import { HedgerExecutionPage } from '@/components/hedger/execution/HedgerExecutionPage.tsx';
import { useHedgerSettingModal } from '@/components/hedger/execution/settings/useHedgerSettingModal.ts';
import { safeParseNumber } from '@/utils/libs/safeParseNumber.ts';

const hedgerExecutionSettingsRouteApi = getRouteApi('/hedger/settings/$scenarioId');

export function HedgerExecutionPageWithModal() {
  const { scenarioId } = hedgerExecutionSettingsRouteApi.useParams();
  const hedgerSettingModal = useHedgerSettingModal();

  useEffect(() => {
    hedgerSettingModal.show({ scenarioId: safeParseNumber(scenarioId) });

    return () => {
      hedgerSettingModal.remove();
    };
    // eslint-disable-next-line
  }, []);

  return <HedgerExecutionPage />;
}

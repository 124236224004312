import {
  cloneElement,
  useEffect,
  useRef,
  useState,
  type CSSProperties,
  type ReactNode,
} from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';

const DefaultStyle = {
  '--bs-tooltip-max-width': '300px',
} as CSSProperties;

export interface TooltipProps {
  contents: ReactNode;
  placement?: 'top' | 'left' | 'right' | 'bottom';
  children: JSX.Element;
  delay?: number;
  style?: CSSProperties;
}

export function Tooltip({
  children,
  contents,
  placement = 'bottom',
  delay,
  style = DefaultStyle,
}: TooltipProps) {
  const [open, setOpen] = useState(false);

  const arrowRef = useRef(null);
  const {
    x,
    y,
    strategy,
    context,
    refs,
    update,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip(), shift({ padding: 8 }), arrow({ element: arrowRef })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { delay }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref: refs.setReference, ...children.props }))}
      {open && (
        <div
          data-popper-placement={placement}
          {...getFloatingProps({
            ref: refs.setFloating,
            className: `tooltip fade bs-tooltip-auto show`,
            style: {
              position: strategy,
              top: y ?? '',
              left: x ?? '',
            },
          })}
        >
          <div
            className="tooltip-arrow"
            ref={arrowRef}
            style={{
              ...style,
              position: 'absolute',
              transform: `translate(${arrowX ?? 0}px, ${arrowY ?? 0}px)`,
            }}
          />
          <div className="tooltip-inner" style={style}>
            {contents}
          </div>
        </div>
      )}
    </>
  );
}

import { useId, type ChangeEventHandler, type PropsWithChildren } from 'react';
import { noop } from '@sgme/fp';
import classNames from 'classnames';

interface CheckboxProps {
  checked: boolean;
  label?: string;
  className?: string;
  labelClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export function Checkbox({
  checked,
  label,
  children,
  className,
  onChange = noop,
  labelClassName,
}: PropsWithChildren<CheckboxProps>) {
  const forId = useId();
  return (
    <div className={classNames(className, 'form-check')}>
      <input
        id={forId}
        className="form-check-input"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {label !== undefined || children !== undefined ? (
        <label htmlFor={forId} className={classNames(labelClassName, 'form-check-label')}>
          {children ?? label}
        </label>
      ) : null}
    </div>
  );
}

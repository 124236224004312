import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/notAuthorized')({
  component: NotAuthorizedPage,
});

function NotAuthorizedPage() {
  return (
    <div className="px-4 d-flex flex-column justify-content-center align-items-center">
      <h4>Not Authorized</h4>
      <p>You do not have the permissions to see this page</p>
    </div>
  );
}

import { Outlet } from '@tanstack/react-router';

import { RulesSideNav } from '@/components/rules/RulesSideNav.tsx';

export function RulesPage() {
  return (
    <div className="d-flex h-100">
      <RulesSideNav />
      <div className="d-flex flex-grow-1 p-4">
        <Outlet />
      </div>
    </div>
  );
}

import { createFileRoute } from '@tanstack/react-router';

import { CreateScenario } from '@/components/hedger/manage/scenarios/CreateScenario.tsx';
import { authorizeIfUserHasTeam } from '@/components/router/core/authorizeIfUserHasTeam.ts';
import { safeParseNumber } from '@/utils/libs/safeParseNumber.ts';

export const Route = createFileRoute('/hedger/scenarios/create')({
  component: CreateScenario,
  beforeLoad: authorizeIfUserHasTeam,
  validateSearch: (search: Record<string, unknown>): { cloneId: number | undefined } => ({
    cloneId: safeParseNumber(search['cloneId'] as string),
  }),
});

import type { z } from 'zod';

import { webApi } from '@/web/utils/webApi.ts';

interface SessionResponse<T> {
  version: number;
  owner: string;
  itemKey: string;
  itemValue: T;
  lastUpdate: string;
  id: string;
}

export type SessionServiceKey =
  | 'userPreferences'
  | 'perimeterPresets'
  | 'visualizationPresets'
  | 'colorRules'
  | 'hedger'
  | 'widgets';
const version = 0;

export async function loadUserSessionService(
  owner: string,
  key: SessionServiceKey,
): Promise<unknown> {
  try {
    const userPreferencesResponse = await webApi
      .get(`api/session/${encodeURIParam(owner)}/${key}/${version}/`)
      .json<SessionResponse<unknown>>();
    return userPreferencesResponse.itemValue;
  } catch {}
}

/**
 * We have problems with special chars in URLs. Probably due to
 * https://github.com/kubernetes/ingress-nginx/issues/5576
 */
function encodeURIParam(param: string): string {
  // return encodeURIComponent(param.replaceAll('/', ' '));
  return param.replaceAll('/', ' ');
}

export async function saveUserSessionService<S extends z.ZodType>(
  owner: string,
  key: SessionServiceKey,
  sessionPayload: z.infer<S>,
  schema: S,
): Promise<void> {
  try {
    return await webApi
      .put(`api/session/${encodeURIParam(owner)}/${key}/${version}/`, {
        json: schema.parse(sessionPayload),
      })
      .json<void>();
  } catch {}
}

export async function loadAndMergeByTeamSessionService<T>(
  key: SessionServiceKey,
  teams: string[],
  valueValidator: (array: unknown) => T[],
): Promise<T[]> {
  const values: T[][] = await Promise.all(
    teams.map(team => loadUserSessionService(team, key).then(valueValidator)),
  );
  return values.flat();
}

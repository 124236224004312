import type { ColDef, GridApi } from '@ag-grid-community/core';
import { isDefined } from '@sgme/fp';
import deepEqual from 'fast-deep-equal';

import {
  commonGridStateSchema,
  type CommonGridState,
} from '@/store/slices/prefs/commonGridStateSchema.ts';
import { isColGroupDef } from '@/utils/agGrid/agGrid.ts';

export function normalizePreset(preset: CommonGridState): CommonGridState {
  return {
    columnState: preset.columnState,
    columnGroupState: preset.columnGroupState.filter(g => g.open),
  };
}

function getColIds(gridApi: GridApi) {
  const colDefs = gridApi.getColumnDefs();
  if (colDefs === undefined) {
    return { groupIds: [], colIds: [] };
  }
  const colIds: string[] = [];
  const groupIds = [];

  for (const colDef of colDefs) {
    if (isColGroupDef(colDef)) {
      if (colDef.groupId !== undefined) {
        groupIds.push(colDef.groupId);
      }

      //! Only one level grouping for now, children must be simple colDef
      const colDefsChildren = (colDef.children as ColDef[])
        .map(colDef => colDef.colId)
        .filter(isDefined);
      colIds.push(...colDefsChildren);
      continue;
    }
    if (colDef.colId !== undefined) {
      colIds.push(colDef.colId);
    }
  }

  return { colIds, groupIds };
}
export function gridStateHasChangedFromPreset(
  preset: CommonGridState,
  gridState: CommonGridState,
  gridApi: GridApi,
): boolean {
  const { colIds, groupIds } = getColIds(gridApi);
  const { columnGroupState, columnState } = preset;

  // Removing from the preset columns not present on the current colDefs
  const filteredColumnGroupState = columnGroupState.filter(
    colGroup => groupIds.findIndex(availableGroupId => colGroup.groupId === availableGroupId) >= 0,
  );
  const filteredColumnState = columnState.filter(
    colState => colIds.findIndex(availableColId => colState.colId === availableColId) >= 0,
  );
  const filteredPreset = {
    ...preset,
    columnGroupState: filteredColumnGroupState,
    columnState: filteredColumnState,
  };

  return !deepEqual(normalizePreset(filteredPreset), normalizePreset(gridState));

  // return !debugDeepEqual(
  //   normalizePreset(preset),
  //   normalizePreset(gridState),
  //   'Preset (a) is different from gridState (b)',
  // );
}

export function applyGridState(gridApi: GridApi, gridState: CommonGridState): void {
  gridApi.applyColumnState({
    applyOrder: true,
    state: gridState.columnState,
  });
  for (const colGroup of gridApi.getColumnGroupState()) {
    gridApi.setColumnGroupOpened(
      colGroup.groupId,
      gridState.columnGroupState.find(g => g.groupId === colGroup.groupId)?.open ?? false,
    );
  }
}

export function captureFromGridState(gridApi: GridApi): CommonGridState {
  // FIXME: if a field has a typo, it will be ignored
  const gridState = commonGridStateSchema.parse({
    columnState: gridApi.getColumnState() ?? [],
    columnGroupState: gridApi.getColumnGroupState() ?? [],
    filterModel: gridApi.getFilterModel() ?? {},
  });

  return {
    ...gridState,
    columnGroupState: gridState.columnGroupState.filter(group => group.open),
  };
}

// function debugDeepEqual<T extends object | any[]>(a: T, b: T, comment: string): boolean {
//   const diffPatch = diff(a, b);
//   if (diffPatch.length === 0) {
//     return true;
//   }
//   console.warn(comment);
//   console.groupCollapsed('diffPatch');
//   console.warn(diffPatch);
//   console.groupEnd();
//   console.groupCollapsed('a');
//   console.warn(a);
//   console.groupEnd();
//   console.groupCollapsed('b');
//   console.warn(b);
//   console.groupEnd();
//   return false;
// }

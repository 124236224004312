import type { CellClassRules, ColDef, ValueGetterParams } from '@ag-grid-community/core';

import { getCellValueFormatter } from '@/core/format.ts';
import type { MeasureType } from '@/core/measures.ts';
import { hedgerApi, type OrderUpdate } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Order } from '@/store/api/hedgerApi/hedgerTypes.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import type {
  NegativeNumberFormat,
  NumberFormat,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { store } from '@/store/store.ts';
import { absValueComparator } from '@/components/equityRisk/gridMappings/comparators.ts';
import { getOrderGroupIdFromSessionStorage } from '@/components/hedger/getOrderGroupIdFromSessionStorage.ts';
import { ActiveSwitcher } from '@/components/hedger/order/ActiveSwitcher.tsx';
import { InactiveReasonsTooltip } from '@/components/hedger/order/InactiveReasonsTooltip.tsx';

export const hedgerOrderColumnFields = [
  'underlying',
  'eliotId',
  'rtGenOrderPassing',
  'way',
  'quantity',
  'deltaCurrency',
  'hedgePercent',
  'refSpot',
  'portfolio',
  'gop',
  'market',
  'active',
  'recipientId',
] as const;

export type HedgerOrderColumnField = (typeof hedgerOrderColumnFields)[number];
export interface OrderColumn extends ColDef<Order> {
  type?: 'rightAligned' | 'leftAligned';
  format?: MeasureType;
  field: HedgerOrderColumnField;
}

const orderColumns: OrderColumn[] = [
  {
    field: 'underlying',
    format: 'text',
    headerName: 'Underlying Bloomberg',
  },
  {
    field: 'eliotId',
    format: 'text',
    headerName: 'Underlying Eliot',
  },
  {
    field: 'rtGenOrderPassing',
    format: 'text',
    headerName: 'Underlying RTGen',
  },
  {
    field: 'way',
    format: 'text',
    valueGetter: (params: ValueGetterParams<Order>) => params.data?.way,
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    format: 'quantity',
    initialWidth: 100,
    type: 'rightAligned',
    editable: true,
    valueGetter: (params: ValueGetterParams<Order>) => params.data?.quantity,
  },
  {
    field: 'deltaCurrency',
    headerName: 'Delta',
    format: 'currency',
    initialWidth: 100,
    type: 'rightAligned',
    comparator: absValueComparator,
  },
  {
    field: 'hedgePercent',
    headerName: 'Hedge %',
    format: 'percentage',
    type: 'rightAligned',
    editable: true,
  },
  {
    field: 'refSpot',
    headerName: 'Spot',
    format: 'price',
    type: 'rightAligned',
  },

  {
    field: 'portfolio',
    format: 'text',
    type: 'rightAligned',
  },
  {
    field: 'gop',
    format: 'text',
  },
  {
    field: 'market',
    format: 'text',
  },
];

async function disableAllActiveOrders() {
  const orderGroupId = getOrderGroupIdFromSessionStorage();
  if (orderGroupId === undefined) {
    store.dispatch(addErrorToastThunk('Disabling active orders failed.', 'Error: id not found'));
    return;
  }
  const { data: orderGroup } = hedgerApi.endpoints?.getOrderGroup.select({ orderGroupId })(
    store.getState(),
  );
  if (orderGroup === undefined) {
    store.dispatch(addErrorToastThunk('Disabling active orders failed.'));
    return;
  }
  const updates: OrderUpdate[] = orderGroup.orders
    .filter(order => order.active)
    .map(({ orderId }) => {
      return {
        orderId,
        active: false,
      };
    });
  if (updates.length === 0) {
    return;
  }
  store.dispatch(hedgerApi.endpoints?.updateOrder.initiate({ orderGroupId, updates }));
}

const getCustomOrderColDefs = (recipients: Recipient[]): OrderColumn[] => {
  return [
    {
      sortIndex: 1,
      field: 'active',
      headerName: 'Active',
      minWidth: 100,
      autoHeight: true,
      cellRenderer: ActiveSwitcher,
      tooltipComponent: InactiveReasonsTooltip,
      tooltipField: 'active',
      mainMenuItems: params => [
        ...params.defaultItems,
        'separator',
        {
          name: 'Disable All',
          tooltip: 'Disable all the switchers on the column',
          action: disableAllActiveOrders,
        },
      ],
    },

    {
      sortIndex: 0,
      field: 'recipientId',
      headerName: 'Recipient',
      valueGetter: (params: ValueGetterParams<Order>) =>
        recipients.find(recipient => recipient.recipientId === params.data?.recipientId)
          ?.recipientLabel ?? '',
    },
  ];
};

export function getOrderColDefs(
  recipients: Recipient[],
  numberFormat: NumberFormat,
  negativeNumberFormat: NegativeNumberFormat,
): ColDef<Order>[] {
  const orderColDefs: ColDef<Order>[] = orderColumns.map(
    ({ format, ...orderColumn }: OrderColumn): ColDef<Order> => {
      return {
        ...orderColumn,
        type: orderColumn.type,
        cellClassRules: getHedgerCellClassRules(orderColumn),
        valueFormatter:
          format !== undefined
            ? getCellValueFormatter(numberFormat, negativeNumberFormat, format)
            : undefined,
      };
    },
  );

  return orderColDefs.concat(getCustomOrderColDefs(recipients));
}

function getHedgerCellClassRules(_: OrderColumn): CellClassRules<Order> {
  return {
    'text-danger': ({ value, data }) => value < 0 && data?.active === true,
  };
}

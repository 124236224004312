import { z } from 'zod';

export const columnStateSchema = z.object({
  sort: z.union([z.literal('asc'), z.literal('desc'), z.null()]).default(null),
  width: z.number().optional(),
  colId: z.string(),
  hide: z.boolean().default(false),
  pinned: z.union([z.literal('left'), z.literal('right'), z.boolean(), z.undefined(), z.null()]),
});
export const columnGroupStateSchema = z.object({
  groupId: z.string(),
  open: z.boolean().default(false),
});

export const commonGridStateSchema = z.object({
  columnGroupState: z.array(columnGroupStateSchema).catch([]),
  columnState: z.array(columnStateSchema).default([]),
  filterModel: z.record(z.any()).optional().catch({}),
});

export type CommonColumnGroupState = z.infer<typeof columnGroupStateSchema>;
export type CommonColumnState = z.infer<typeof columnStateSchema>;
export type CommonGridState = z.infer<typeof commonGridStateSchema>;

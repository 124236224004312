export interface PresetKey {
  team: string;
  name: string;
}

export function presetKeyToString(presetKey: PresetKey): string {
  return `${presetKey.team}/${presetKey.name}`;
}

export function toPresetKey(preset: PresetKey): PresetKey {
  return {
    name: preset.name,
    team: preset.team,
  };
}

export function keyEquals(keyLikeA: PresetKey, keyLikeB: PresetKey): boolean {
  const presetKeyA = toPresetKey(keyLikeA);
  const presetKeyB = toPresetKey(keyLikeB);
  return presetKeyA.team === presetKeyB.team && presetKeyA.name === presetKeyB.name;
}

import { FormattedMessage } from 'react-intl';

import { Tooltip, type TooltipProps } from '@/components/common/bootstrap/Tooltip.tsx';
import type { IntlMessages } from '@/types/intl';

interface Props extends Omit<TooltipProps, 'contents'> {
  messageId: IntlMessages;
  messageValues?: Record<string, any>;
}

export function MessageTooltip({ messageId, messageValues, ...props }: Props) {
  return (
    <Tooltip {...props} contents={<FormattedMessage id={messageId} values={messageValues} />} />
  );
}

import { memo, useCallback } from 'react';

import { Button } from '@/components/common/bootstrap/Button.tsx';
import {
  EditTable,
  type RowData,
  type Updater,
} from '@/components/hedger/manage/recipients/details/EditTable.tsx';

interface MultiEditTableProps {
  tableDatas: TableData[];
  onUpdate: (updater: Updater<TableData[]>) => void;
  buttonTexts: {
    delete: string;
    add: string;
  };
}

export type TableData = {
  id: string;
  name: string;
  data: RowData[];
};

export function MultiEditTable({
  tableDatas,
  onUpdate,
  buttonTexts,
}: MultiEditTableProps): JSX.Element {
  const onAddClick = () =>
    onUpdate(previousTableDatas => [
      {
        id: `newtable_${previousTableDatas.length}`,
        data: [],
        name: '',
      },
      ...previousTableDatas,
    ]);
  const onDeleteTableData = useCallback(
    (id: string) =>
      onUpdate(previousTableDatas => previousTableDatas.filter(tableData => tableData.id !== id)),
    [onUpdate],
  );

  const onEditTableUpdate = useCallback(
    (id: TableData['id'], updatedTableData: Partial<Omit<TableData, 'id'>>) => {
      onUpdate(previousTablesData =>
        previousTablesData.map(tableData => {
          if (tableData.id === id) {
            return {
              ...tableData,
              ...updatedTableData,
              id,
            };
          }
          return tableData;
        }),
      );
    },
    [onUpdate],
  );
  return (
    <div className="d-flex flex-column gap-4">
      <Button className="d-flex justify-content-center  gap-3 fw-medium" onClick={onAddClick}>
        <i className="icon icon-md">add</i>
        New {buttonTexts.add}
      </Button>
      {tableDatas.map(tableData => (
        <MemoEditTableData
          deleteButtonText={buttonTexts.delete}
          key={tableData.id}
          tableData={tableData}
          onEdit={onEditTableUpdate}
          onDelete={onDeleteTableData}
        />
      ))}
    </div>
  );
}

interface TableDataProps {
  tableData: TableData;
  onEdit: (id: TableData['id'], updatedTableData: Partial<Omit<TableData, 'id'>>) => void;
  onDelete: (id: TableData['id']) => void;
  deleteButtonText: string;
}

const MemoEditTableData = memo(EditTableData);

function EditTableData(tableDataProps: TableDataProps) {
  const { tableData, onEdit, onDelete, deleteButtonText } = tableDataProps;
  const { id, name, data } = tableData;

  const onEditTableUpdate = useCallback(
    (rowDataUpdater: Updater<RowData[]>) => {
      onEdit(id, {
        data: rowDataUpdater(data),
      });
    },
    [data, id, onEdit],
  );

  return (
    <div className="bg-lvl2 p-3" key={id}>
      <div className="d-flex mb-2 gap-1">
        <input
          className="form-control"
          onChange={event =>
            onEdit(id, {
              name: event.currentTarget.value,
            })
          }
          type="text"
          value={name}
          id={id}
        />
        <Button
          variant="danger"
          flat={true}
          className="d-flex flex-shrink-0 gap-3"
          onClick={() => onDelete(id)}
        >
          Delete {deleteButtonText} <i className="icon icon-sm ">delete_outline</i>
        </Button>
      </div>
      <EditTable rowDatas={data} onUpdate={onEditTableUpdate} />
    </div>
  );
}

import type { RowData } from '@/components/hedger/manage/recipients/details/EditTable.tsx';
import type { TableData } from '@/components/hedger/manage/recipients/details/MultiEditTable.tsx';
import { objectKeys } from '@/utils/libs/entries.ts';

export const mapToRowDatas = (data: Record<string, string>): Array<RowData> =>
  objectKeys(data).map(key => ({
    id: key,
    label: key,
    value: data[key],
  }));

export const mapFromRowDatas = (rowDatas: RowData[]): Record<string, string> =>
  Object.fromEntries(rowDatas.map(rowData => [rowData.label, rowData.value]));

export const mapToTableDatas = (data: Record<string, Record<string, string>>): Array<TableData> =>
  objectKeys(data).map(key => ({
    id: key,
    name: key,
    data: mapToRowDatas(data[key]),
  }));

export const mapFromTableDatas = (
  tableDatas: TableData[],
): Record<string, Record<string, string>> =>
  Object.fromEntries(
    tableDatas.map(tableData => [tableData.name, mapFromRowDatas(tableData.data)]),
  );

export function Loader(): JSX.Element {
  return (
    <div
      className="w-100 d-flex justify-content-around align-items-center"
      style={{ minHeight: 100 }}
    >
      <div className="spinner spinner-xl" role="status"></div>
    </div>
  );
}

import { customAlphabet } from 'nanoid';

import { getUrlParamAsString } from '@/utils/libs/searchParams.ts';

const nanoid = customAlphabet('1234567890abcdefghijklmopqrstuvwxyz', 21);

export function makeQueryId(path: string) {
  const suffixValue = getUrlParamAsString('queryIdSuffix');

  if (suffixValue !== undefined) {
    return `${path}_${nanoid()}@${suffixValue}`;
  }

  return `${path}_${nanoid()}`;
}

export function getPathFromQueryId(queryId: string): string {
  return queryId.split('_').at(0) ?? '';
}

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { z } from 'zod';

import { allHierarchies } from '@/core/hierachies.ts';
import { getAllMeasureIds, type MeasureId } from '@/core/measures.ts';
import type { QueryState } from '@/store/slices/query/querySlice.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { WidgetTypes, type WidgetQueryParams } from '@/store/slices/widget/widgetTypes.ts';
import type { AppState, AppThunk } from '@/store/store.ts';
import { loadUserSessionService, saveUserSessionService } from '@/web/session/sessionApi.ts';

export const widgetParamsValueSchema = z
  .object({
    value: z.number(),
  })
  .optional();
export const widgetConfigParamsSchema = z.object({
  spotVarThreshold: widgetParamsValueSchema,
  numberOfLines: widgetParamsValueSchema,
  rowAggregation: z.object({ value: z.enum(allHierarchies) }).optional(),
  columnAggregation: z.object({ value: z.enum(allHierarchies) }).optional(),
  metric: z.object({ value: z.enum(getAllMeasureIds() as [MeasureId, ...MeasureId[]]) }).optional(),
});
export const widgetConfigSchema = z.object({
  id: z.string(),
  type: z.enum(WidgetTypes),
  name: z.string(),
  params: widgetConfigParamsSchema,
  size: z.object({ width: z.number(), height: z.number() }).optional(),
});

export type WidgetConfig = z.infer<typeof widgetConfigSchema>;
export type WidgetConfigParams = z.infer<typeof widgetConfigParamsSchema>;

export interface WidgetState {
  config: WidgetConfig[];
}

const initialState: WidgetState = {
  config: [],
};

const validationSchema = z.object({
  // TODO: validate each array element individually
  config: z.array(widgetConfigSchema),
});

export function validateWidgetState(data: unknown): WidgetState | undefined {
  const parse = validationSchema.safeParse(data);
  return parse.success ? parse.data : undefined;
}

export const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,

  reducers: {
    set: (state, action: PayloadAction<WidgetState>) => {
      return action.payload;
    },
    setValue: (state, action: PayloadAction<Partial<WidgetState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWidgetState.fulfilled, (_, action) => {
      if (action.payload !== undefined) {
        return action.payload;
      }
    });
  },
});

/**
 * Save the configuration of the widget in session service AND the store
 */
export function saveWidgetConfigToSessionServiceThunk(config: WidgetConfig[]): AppThunk {
  return async (dispatch, getState) => {
    dispatch(widgetsSlice.actions.set({ config }));

    const email = userSlice.selectors.email(getState());
    await saveUserSessionService(
      email,
      'widgets',
      {
        config,
      },
      z.object({ config: z.array(widgetConfigSchema) }),
    );
  };
}

export const selectWidgetQueryParams = createSelector(
  [state => state.query],
  function (query: QueryState): WidgetQueryParams {
    const { perimeters, cubeName, cubeMode, valueDate, exclusionMode } = query;
    return { perimeters, cubeName, cubeMode, valueDate, exclusionMode };
  },
);

export const fetchWidgetState = createAsyncThunk<
  WidgetState | undefined,
  void,
  { state: AppState }
>('widgetsSlice/fetchWidgetState', async (_, { getState }) => {
  const user = userSlice.selectors.user(getState());
  return loadUserSessionService(user.email, 'widgets').then(validateWidgetState);
});
export function selectWidgetConfigs(state: AppState): WidgetConfig[] {
  return state.widgets.config;
  // return state.widgets.config.filter(conf => WidgetsById.has(conf.id));
}

import { addMinutes } from 'date-fns';

import type { GenerateGroupFilesParams } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { UserPreferences } from '@/store/slices/prefs/userPreferencesSlice.ts';

export function getHedgerOrderTime({
  hedgerOrderDefaultEndTime,
  hedgerOrderDefaultRelativeEndTime,
  hedgerOrderDefaultRelativeStartTime,
  hedgerOrderDefaultStartTime,
  hedgerOrderDefaultTime,
}: UserPreferences): Pick<GenerateGroupFilesParams, 'startTimeUtc' | 'endTimeUtc'> {
  const isAbsolute = hedgerOrderDefaultTime !== 'RELATIVE';

  const startTime = isAbsolute ? hedgerOrderDefaultStartTime : hedgerOrderDefaultRelativeStartTime;
  const endTime = isAbsolute ? hedgerOrderDefaultEndTime : hedgerOrderDefaultRelativeEndTime;

  const startDate = new Date();
  const endDate = new Date();

  if (startTime === undefined || endTime === undefined) {
    return { startTimeUtc: startDate.toISOString(), endTimeUtc: endDate.toISOString() };
  }

  const hedgerOrderTimeGetter = isAbsolute ? getAbsoluteUtcTime : getRelativeUtcTime;

  return {
    startTimeUtc: hedgerOrderTimeGetter(startDate, startTime),
    endTimeUtc: hedgerOrderTimeGetter(endDate, endTime),
  };
}

function getAbsoluteUtcTime(date: Date, time: string): string {
  const [startHours, startMinutes] = time.split(':');
  date.setUTCHours(parseInt(startHours));
  date.setUTCMinutes(parseInt(startMinutes));
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);

  return date.toISOString();
}

function getRelativeUtcTime(date: Date, time: string): string {
  return addMinutes(date, parseInt(time)).toISOString();
}

import { useEffect } from 'react';

export const isBrowser = typeof window !== 'undefined';

const defaultTarget = isBrowser ? window : undefined;

export function useEvent<K extends keyof WindowEventMap>(
  name: K,
  handler: (ev: WindowEventMap[K]) => any,
): void;
export function useEvent<T extends HTMLElement, K extends keyof HTMLElementEventMap>(
  name: K,
  handler: (ev: HTMLElementEventMap[K]) => any,
  target?: T,
): void;
export function useEvent(name: any, handler: (ev: any) => any, target: any = defaultTarget) {
  useEffect(() => {
    target.addEventListener(name, handler);
    return () => {
      target.removeEventListener(name, handler);
    };
  }, [name, handler, target]);
}

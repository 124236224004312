import { createAsset } from 'use-asset';

import {
  getQueryPayload,
  parseToQueryResult,
  type QueryResult,
} from '@/components/queryTools/query/common.ts';
import { getGreatServerResponse } from '@/components/queryTools/query/greatServer/getGreatServerResponse.ts';

export const queryResponseAsset = createAsset(
  async (wsUrl: string, queryPayload: string, currentQuery: string): Promise<QueryResult> => {
    const payload = getQueryPayload(queryPayload, currentQuery);
    const data = await getGreatServerResponse(payload, wsUrl);

    return parseToQueryResult(data);
  },
);

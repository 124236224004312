import { type ColumnState } from '@ag-grid-community/core';
import { asSequence } from 'sequency';
import { z } from 'zod';

import { allHierarchies, type Hierarchy } from '@/core/hierachies.ts';
import {
  columnStateSchema,
  commonGridStateSchema,
} from '@/store/slices/prefs/commonGridStateSchema.ts';
import { isColIdValid } from '@/components/equityRisk/gridMappings/isColIdValid.ts';

const filtersSchema = z.record(z.any()).optional().catch({});

const commonGrid = commonGridStateSchema.merge(
  z.object({
    columnState: z
      .array(columnStateSchema)
      .transform(columnState => columnState.filter(isColumnStateValid))
      .default([]),
  }),
);

export const visualizationPresetSchema = commonGrid.merge(
  z.object({
    hierarchies: z
      .array(z.string())
      .transform(hierarchies => hierarchies.filter(isHierarchyValid))
      .default([]),
    name: z.string(),
    filters: filtersSchema,
    filtersEnabled: z.boolean().catch(true),
    team: z.string(),
  }),
);
export const visualizationPresetKeySchema = visualizationPresetSchema.pick({
  team: true,
  name: true,
});
export type VisualizationPresetKey = z.infer<typeof visualizationPresetKeySchema>;

const hierarchiesMap = asSequence(allHierarchies).associateBy(h => h as string);

function isHierarchyValid(h: string): h is Hierarchy {
  return hierarchiesMap.has(h);
}

function isColumnStateValid(colState: ColumnState) {
  return isColIdValid(colState.colId);
}

type SubQueryHookResult<D> = {
  data?: D;
  isUninitialized: boolean;
  isLoading: boolean;
};
/*
Checks whether or not derivated booleans isUninitialized and isLoading are ready 
* */
export function isQueryHookResultReady<T extends SubQueryHookResult<D>, D>(
  queryHookResult: T,
): queryHookResult is T & { data: NonNullable<D> } {
  return !queryHookResult.isUninitialized && !queryHookResult.isLoading;
}

import { SgwtAccountCenter } from '@sgwt/sgwt-widgets-react';
import { useIntl } from 'react-intl';

export function AccountCenter(): JSX.Element {
  const intl = useIntl();
  return (
    <SgwtAccountCenter
      availableLanguages={['fr', 'en']}
      authentication="sg-connect"
      mode="sg-markets"
      language={intl.locale}
      producerCode="sgm_eqd_riskana"
    />
  );
}

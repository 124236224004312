import { Axes, type AxeDefinition, type AxisPosition, type MdxStateData } from '@/types/mdx.ts';

export type RowData = { dataPath: string[] } & Record<string, unknown>;

export interface ParsedResponse {
  columns: MdxColumn[];
  rowData: RowData[];
  hierarchies: string[];
}

type MdxColumnType = 'hierarchy' | 'data';

export interface MdxColumn {
  path: string[];
  type: MdxColumnType;
}

function computeDataPath(position: AxisPosition[]): string[] {
  return position.reduce((acc, position, index) => {
    const pathElements = position.namePath;
    if (index === 0) {
      return pathElements;
    }

    const subCode = pathElements[1];
    if (subCode === undefined) {
      return acc;
    }
    return [...acc, subCode];
  }, [] as string[]);
}

function mapHierarchiesToObject(rowsAxis: AxeDefinition, rowIndex: number): RowData {
  const position = rowsAxis.positions[rowIndex];
  const dimensionsValues = rowsAxis.hierarchies.reduce(
    (acc, current, index) => {
      acc[current.hierarchy] = position[index].namePath?.at(-1);
      return acc;
    },
    {} as { [k: string]: string | undefined },
  );

  return {
    ...dimensionsValues,
    dataPath: computeDataPath(position),
  };
}

export function parseResponse(data: MdxStateData): ParsedResponse {
  const axes = data.axes;
  const columnsAxis = axes.find(axe => axe.id === Axes.COLUMNS)!;
  const rowsAxis = axes.find(axe => axe.id === Axes.ROWS)!;

  const hierarchyColumns: MdxColumn[] = rowsAxis.hierarchies.map(it => ({
    path: [it.dimension],
    type: 'hierarchy',
  }));

  const columnsNames: MdxColumn[] = columnsAxis.positions.map(pos => {
    return {
      path: pos.flatMap(it => it.namePath),
      type: 'data',
    };
  });

  const numRows = rowsAxis.positions.length;
  const numCols = columnsAxis.positions.length;
  const rowData = Array.from({ length: numRows }).map((_, rowIndex) => {
    return mapHierarchiesToObject(rowsAxis, rowIndex);
  });

  data.cells.forEach(cell => {
    const { rowIndex, colIndex } = computeCoords(cell.ordinal, numCols);

    const colName = columnsNames[colIndex].path.join('/');
    rowData[rowIndex][colName] = cell.value;
  });

  return {
    columns: [...hierarchyColumns, ...columnsNames],
    rowData,
    hierarchies: ['AllMembers', ...rowsAxis.hierarchies.map(h => h.hierarchy)],
  };
}

interface Coordinates {
  rowIndex: number;
  colIndex: number;
}

export function computeCoords(ordinal: number, numCols: number): Coordinates {
  const rowIndex = Math.floor(ordinal / numCols);
  const colIndex = ordinal % numCols;
  return {
    rowIndex,
    colIndex,
  };
}

import type { TradingToolTemplate } from '@/store/api/hedgerApi/hedgerApi.ts';

export type TradingToolTemplateKey = { orderTypeLabel: string; transmissionLabel: string };

export function getTradingToolTemplateByKey(
  tradingToolTemplates: TradingToolTemplate[],
  { orderTypeLabel, transmissionLabel }: TradingToolTemplateKey,
): TradingToolTemplate | undefined {
  return tradingToolTemplates.find(
    tradingToolTemplate =>
      tradingToolTemplate.orderTypeLabel === orderTypeLabel &&
      tradingToolTemplate.transmissionLabel === transmissionLabel,
  );
}
export function getSelectableTradingToolLabels(
  selectedTradingToolTemplate: TradingToolTemplate,
  tradingToolTemplates: TradingToolTemplate[],
) {
  if (selectedTradingToolTemplate === undefined) {
    return { transmissionLabels: [], orderTypeLabels: [] };
  }
  const transmissionLabels = [...new Set(tradingToolTemplates.map(ttt => ttt.transmissionLabel))];
  const orderTypeLabels = [
    ...new Set(
      tradingToolTemplates
        .filter(ttt => ttt.transmissionLabel === selectedTradingToolTemplate.transmissionLabel)
        .map(x => x.orderTypeLabel),
    ),
  ];
  return { transmissionLabels, orderTypeLabels };
}

import { z, ZodNumber } from 'zod';

import { getFromStorage, type StorageKey } from '@/utils/storage/storage.ts';

export const HEDGER_CURRENT_ORDER_GROUP_SESSION_KEY: StorageKey<ZodNumber> = {
  schema: z.number().int(),
  key: 'HEDGER_CURRENT_ORDER_GROUP',
  type: 'sessionStorage',
};

export function getOrderGroupIdFromSessionStorage(): number | undefined {
  return getFromStorage(HEDGER_CURRENT_ORDER_GROUP_SESSION_KEY);
}

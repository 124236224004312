import classNames from 'classnames';
import { useIntl } from 'react-intl';

import type { IntlMessages } from '@/types/intl';

export function Tabs<T extends string>({
  tabNames,
  active,
  onSelect,
  className = '',
  getMessage,
}: {
  tabNames: readonly T[];
  active: string | undefined;
  onSelect: (value: T) => void;
  className?: string;
  getMessage?: (tabName: T) => IntlMessages;
}) {
  const { formatMessage } = useIntl();
  const classes = classNames(className, 'nav nav-underline nav-lg nav-hover pt-4');
  return (
    <ul className={classes}>
      {tabNames.map((tabName: T) => (
        <li key={tabName} className="nav-item">
          <a
            className={classNames('nav-link', 'cursor-pointer', {
              'active cursor-default': tabName === active,
            })}
            onClick={() => onSelect(tabName)}
          >
            {getMessage !== undefined ? formatMessage({ id: getMessage(tabName) }) : tabName}
          </a>
        </li>
      ))}
    </ul>
  );
}

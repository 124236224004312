import type { ICellRendererParams } from '@ag-grid-community/core';

import { Switcher } from '@/components/common/bootstrap/Switcher.tsx';

export function ActiveSwitcher(params: ICellRendererParams): JSX.Element | null {
  const { value, node, column } = params;
  const switchField = column?.getColId()!;
  if (switchField === undefined) {
    return null;
  }
  const onChange = (onChangeValue: boolean) => node.setDataValue(switchField, onChangeValue);
  return <Switcher id={node.id!} colored={true} checked={value} onChange={onChange} />;
}

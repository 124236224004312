import { createAsyncThunk } from '@reduxjs/toolkit';

import { makeQueryId } from '@/core/webSocket/queryId.ts';
import type { WebSocketConnection } from '@/core/webSocket/types.ts';
import { userPreferencesSlice } from '@/store/slices/prefs/userPreferencesSlice.ts';
import type {
  AnalyticalStructureRowData,
  ProfitCenterLongNameRowData,
  UnderlyerCacheRowData,
} from '@/store/slices/queryCache/queryCacheSlice.ts';
import type { AppState } from '@/store/store.ts';

export const fetchAnalyticalStructure = createAsyncThunk<
  AnalyticalStructureRowData[],
  WebSocketConnection,
  { state: AppState }
>('users/fetchAnalyticalStructure', async (webSocketConnection, { getState }) => {
  const contextValues = userPreferencesSlice.selectors.contextValues(getState());
  const cubeName = getState().query.cubeName;

  return makeOnShotQuery<AnalyticalStructureRowData>(
    webSocketConnection,
    makeQueryId('Cache_AnalyticalStructure'),
    contextValues,
    `
        SELECT NON EMPTY{ Measures.[contributors.COUNT]}
        ON COLUMNS,
            NON EMPTY { [ProfitCenter].[ProfitCenter].[ProfitCenter] * [GOP].[GOP].[GOP] * [PortFolio].[PortFolio].[PortFolio] } ON ROWS
        FROM
            ${cubeName}
    `,
  );
});
export const fetchCurrencyCache = createAsyncThunk<
  UnderlyerCacheRowData[],
  WebSocketConnection,
  { state: AppState }
>('users/fetchCurrencyCache', async (webSocketConnection, { getState }) => {
  const contextValues = userPreferencesSlice.selectors.contextValues(getState());
  const cubeName = getState().query.cubeName;

  return makeOnShotQuery<UnderlyerCacheRowData>(
    webSocketConnection,
    makeQueryId('Cache_Currency'),
    contextValues,
    `
        SELECT NON EMPTY{ [UnderlyerCode].[UnderlyerCode].[UnderlyerCode] * [UnderlyerCurrency].[UnderlyerCurrency].[UnderlyerCurrency]}
        ON ROWS,
            { [Measures].[contributors.COUNT]} ON COLUMNS
        FROM ${cubeName}
        WHERE {
            [ValuationType].[ValuationType].[Intraday]
            , [ValuationType].[ValuationType].[Official]
            }
    `,
  );
});
export const fetchProfitCenters = createAsyncThunk<
  ProfitCenterLongNameRowData[],
  WebSocketConnection,
  { state: AppState }
>('users/fetchProfitCenters', async (webSocketConnection, { getState }) => {
  const contextValues = userPreferencesSlice.selectors.contextValues(getState());
  const cubeName = getState().query.cubeName;

  return makeOnShotQuery<ProfitCenterLongNameRowData>(
    webSocketConnection,
    makeQueryId('Cache_ProfitCenter'),
    contextValues,
    `
        SELECT NON EMPTY{ Measures.[contributors.COUNT]}
        ON COLUMNS,
            NON EMPTY { [ProfitCenter].[ProfitCenter].[ProfitCenter] * [ProfitCenterDesc].[ProfitCenterDesc].[ProfitCenterDesc] } ON ROWS
        FROM
            ${cubeName}
    `,
  );
});

async function makeOnShotQuery<T>(
  webSocketConnection: WebSocketConnection,
  queryId: string,
  contextValues: Record<string, string>,
  query: string,
): Promise<T[]> {
  const iterator = webSocketConnection.queryGenerator(
    {
      queryId,
      queryGroup: 'cache',
      mdx: query,
      initialState: 'PAUSED',
      contextValues,
    },
    {
      limit: 1,
    },
  );
  for await (const cellSetDataEvent of iterator) {
    return cellSetDataEvent.add as T[];
  }
  return [];
}

import EventEmitter from 'events';

export type TrafficColor = 'green' | 'orange' | 'red';

export type CssTrafficColor = 'success' | 'warning' | 'danger';

export type TrafficEvents = {
  colorChanged: [{ color: TrafficColor; mean: number }];
};

export type TrafficEventEmitter = EventEmitter<TrafficEvents>;

export const trafficEventEmitter = new EventEmitter<TrafficEvents>();

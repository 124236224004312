import { Suspense, type PropsWithChildren } from 'react';

import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { Catch } from '@/components/common/utils/Catch.tsx';

interface SuspenseWrapperProps {
  errorRenderer?: (props: { error: Error }) => JSX.Element;
}

export const SuspenseWrapper = ({
  errorRenderer,
  children,
}: PropsWithChildren<SuspenseWrapperProps>) => {
  return (
    <Catch errorRenderer={errorRenderer}>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </Catch>
  );
};

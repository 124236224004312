import { createRootRoute } from '@tanstack/react-router';

import { RootRouteComponent } from '@/components/router/core/RootRouteComponent.tsx';
import { matomo } from '@/utils/matomo/matomo.ts';

export const Route = createRootRoute({
  component: RootRouteComponent,
  beforeLoad: () => {
    matomo.trackPageView({ href: window.location.href });
  },
});

import { z } from 'zod';

export function validateAndLog<S extends z.ZodType>(
  type: string,
  schema: S,
  state: unknown,
): z.infer<S> | undefined {
  const parseResult = schema.safeParse(state);
  if (parseResult.success) {
    return parseResult.data;
  } else {
    if (state !== undefined) {
      console.error('Error validating ', type, ':', parseResult.error.issues);
      console.error('Faulty object was', state);
    }
    return undefined;
  }
}

import { getPathFromQueryId } from '@/core/webSocket/queryId.ts';
import type { WsEventEmitter } from '@/core/webSocket/types.ts';
import { createWebsocketConnection } from '@/core/webSocket/WebSocket.ts';
import {
  fetchAnalyticalStructure,
  fetchCurrencyCache,
  fetchProfitCenters,
} from '@/store/slices/queryCache/queryCacheThunks.ts';
import { store } from '@/store/store.ts';
import { registerTrafficWatcher } from '@/components/equityRisk/traffic/registerTrafficWatcher.tsx';
import { createError } from '@/utils/error/createError.ts';
import { perfLogger } from '@/utils/libs/perfLogger.ts';

export const equityRiskLoader = async () => {
  const wsUrl = store.getState().query.wsUrl;
  if (wsUrl === undefined) {
    throw createError('Error.GreatServerNoUrl');
  }

  const dispatch = store.dispatch;
  const webSocketConnection = await createWebsocketConnection(wsUrl);
  await Promise.all([
    dispatch(fetchCurrencyCache(webSocketConnection)),
    dispatch(fetchAnalyticalStructure(webSocketConnection)),
    dispatch(fetchProfitCenters(webSocketConnection)),
  ]);

  registerPerfLogger(webSocketConnection.wsEventEmitter);
  registerTrafficWatcher(webSocketConnection.wsEventEmitter);

  return { webSocketConnection };
};

function registerPerfLogger(wsEventEmitter: WsEventEmitter) {
  perfLogger.startTimer();
  wsEventEmitter.on('cellSetData', ({ queryId }) => {
    const path = getPathFromQueryId(queryId);
    if (path === '') {
      perfLogger.time('rootQueryResponse');
    }
    perfLogger.createHistogram(queryId);
  });
  wsEventEmitter.on('cellData', ({ queryId }) => {
    perfLogger.recordHistogramTime(queryId);
  });
  wsEventEmitter.on('end', ({ queryId }) => {
    perfLogger.destroyHistogram(queryId);
  });
}

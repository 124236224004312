import type EventEmitter from 'events';
import type {
  ColDef,
  ColGroupDef,
  Column,
  ColumnGroupOpenedEvent,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GetMainMenuItemsParams,
  GridApi,
  IRowNode,
  SortChangedEvent,
} from '@ag-grid-community/core';

export type GridStateEvent =
  | ColumnMovedEvent
  | ColumnPinnedEvent
  | ColumnVisibleEvent
  | SortChangedEvent
  | ColumnResizedEvent
  | FilterChangedEvent
  | ColumnGroupOpenedEvent;

// remove if ag grid correctly type the "type" property
export type TypedGridStateEvent =
  | (ColumnMovedEvent & { type: 'columnMoved' })
  | (ColumnPinnedEvent & { type: 'columnPinned' })
  | (ColumnVisibleEvent & { type: 'columnVisible' })
  | (SortChangedEvent & { type: 'sortChanged' })
  | (ColumnResizedEvent & { type: 'columnResized' })
  | (FilterChangedEvent & { type: 'filterChanged' })
  | (ColumnGroupOpenedEvent & { type: 'columnGroupOpened' });

export type GridEvents = {
  gridChanged: [{}];
  visibleColumnsChanged: [{ visibleColumns: Column[] }];
  rowClosed: [{ dataPath: Array<string> }];
};
export type GridStateEventEmitter = EventEmitter<GridEvents>;

export function isColGroupDef(colOrGroupDef: ColGroupDef | ColDef): colOrGroupDef is ColGroupDef {
  return (colOrGroupDef as ColGroupDef).groupId !== undefined;
}

export function expandAllColumnsAction<T>(params: GetMainMenuItemsParams<T>, expand: boolean) {
  return () => {
    const colOrGroupDefs = params.api.getColumnDefs();
    const colGroupDefIds =
      colOrGroupDefs?.filter(isColGroupDef).map(colGroupDef => colGroupDef.groupId!) ?? [];

    for (const colGroupId of colGroupDefIds) {
      params.api.setColumnGroupOpened(colGroupId, expand);
    }
  };
}

export function getCurrentRowData<T>(gridApi: GridApi<T>): T[] {
  const currentRowData: T[] = [];
  gridApi.forEachNode(({ data }: IRowNode<T>) => data && currentRowData.push(data));
  return currentRowData;
}

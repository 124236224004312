import { createFileRoute } from '@tanstack/react-router';

import { createWebsocketConnection } from '@/core/webSocket/WebSocket.ts';
import { fetchAnalyticalStructure } from '@/store/slices/queryCache/queryCacheThunks.ts';
import { store } from '@/store/store.ts';
import { ExportToolsPage } from '@/components/queryTools/export/ExportToolsPage.tsx';
import { validateOnePermission } from '@/components/router/core/validateOnePermission.tsx';
import { createError } from '@/utils/error/createError.ts';

export const Route = createFileRoute('/export')({
  component: ExportToolsPage,
  beforeLoad: validateOnePermission('seeDevTools'),
  // No loader cache. https://tanstack.com/router/v1/docs/guide/data-loading#using-shouldreload-and-gctime-to-opt-out-of-caching
  gcTime: 0,
  shouldReload: false,
  loader: async () => {
    const wsUrl = store.getState().query.wsUrl;
    if (wsUrl === undefined) {
      throw createError('Error.GreatServerNoUrl');
    }

    const websocket = await createWebsocketConnection(wsUrl);
    await Promise.all([store.dispatch(fetchAnalyticalStructure(websocket))]);
    return { websocket };
  },
});

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';
import { z } from 'zod';

import { keyEquals, toPresetKey } from '@/store/slices/prefs/presetKey.ts';
import { validateAndLog } from '@/store/slices/prefs/validateAndLog.ts';
import { fetchAnalyticalStructure } from '@/store/slices/queryCache/queryCacheThunks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import type { AppState, AppThunk } from '@/store/store.ts';
import {
  loadAndMergeByTeamSessionService,
  saveUserSessionService,
} from '@/web/session/sessionApi.ts';
import { reducePerimeterKeys } from '@/components/gridTable/tools/reducePerimeterKeys.ts';
import { removeAllFromArray, removeOneFromArray } from '@/utils/arrays.ts';
import { getUrlParamsObjectValue } from '@/utils/libs/searchParams.ts';
import { getFromStorage, type StorageKey } from '@/utils/storage/storage.ts';

const perimeterRegex = /(\[\w+])(\.\[\w+])*/;

const perimeterPresetSchema = z.object({
  perimeters: z.array(z.string().regex(perimeterRegex)),
  team: z.string(),
  name: z.string(),
});

const perimeterPresetKeySchema = perimeterPresetSchema.pick({ team: true, name: true });
export type PerimeterPresetKey = z.infer<typeof perimeterPresetKeySchema>;

export const DEFAULT_PERIMETER_PRESET_KEY: PerimeterPresetKey = {
  team: 'DEFAULT',
  name: 'DEFAULT',
};

export type PerimeterPreset = z.infer<typeof perimeterPresetSchema>;

const initialState: PerimeterPresetsState = {
  activePresetKey: DEFAULT_PERIMETER_PRESET_KEY,
  presets: [],
  draftPerimeters: [],
};

export const SELECTED_PERIMETER_PRESET_LS_KEY: StorageKey<typeof perimeterPresetKeySchema> = {
  schema: perimeterPresetKeySchema,
  key: 'selectedPerimeterPreset',
  type: 'localStorage',
};

export interface PerimeterPresetsState {
  activePresetKey: PerimeterPresetKey;
  presets: PerimeterPreset[];
  draftPerimeters: string[];
}

export const perimeterPresetsSlice = createSlice({
  name: 'perimeterPresets',
  initialState,
  reducers: {
    resetDraftPerimeterPreset: state => {
      const { name, team } = state.activePresetKey;
      const activePreset = state.presets.find(
        preset => preset.name === name && preset.team === team,
      );
      state.draftPerimeters = activePreset?.perimeters ?? [];
    },
    setDraftPerimeterPreset: (state, action: PayloadAction<string[]>) => {
      state.draftPerimeters = action.payload;
    },
    deletePerimeterPresets: (
      state,
      action: PayloadAction<{ presetKeys: PerimeterPresetKey[] }>,
    ) => {
      const presetKeys = action.payload.presetKeys;
      return {
        ...state,
        presets: removeAllFromArray(state.presets, p =>
          presetKeys.some(key => key.team === p.team && key.name === p.name),
        ),
        activePresetKey: DEFAULT_PERIMETER_PRESET_KEY,
      };
    },

    setValue: (state, action: PayloadAction<Partial<PerimeterPresetsState>>) => {
      return { ...state, ...action.payload };
    },
  },

  selectors: {
    selectPerimeterPresetKeys: createSelector(
      [(state: PerimeterPresetsState) => state.presets],
      presets => presets.map(toPresetKey),
    ),
  },

  extraReducers: builder => {
    builder
      .addCase(fetchAnalyticalStructure.fulfilled, (state, action) => {
        const analyticalStructureRowData = action.payload;
        const allPerimeters = analyticalStructureRowData.map(
          ({ ProfitCenter, GOP, PortFolio }) => `[${ProfitCenter}].[${GOP}].[${PortFolio}]`,
        );
        const perimeters = reducePerimeterKeys(allPerimeters, allPerimeters);
        const defaultPerimeterPreset: PerimeterPreset = {
          name: DEFAULT_PERIMETER_PRESET_KEY.name,
          team: DEFAULT_PERIMETER_PRESET_KEY.team,
          perimeters,
        };
        if (state.presets[0]?.name !== DEFAULT_PERIMETER_PRESET_KEY.name) {
          state.presets.unshift(defaultPerimeterPreset);
        }
      })
      .addCase(fetchPerimeterPresets.fulfilled, (state, action) => {
        const presets = action.payload;
        const storedActivePresetKey =
          getUrlParamsObjectValue<PerimeterPresetKey>('perimeterPresetsKey') ??
          getFromStorage(SELECTED_PERIMETER_PRESET_LS_KEY);

        state.presets = presets;
        state.activePresetKey =
          storedActivePresetKey !== undefined &&
          presets.some(p => keyEquals(p, storedActivePresetKey))
            ? storedActivePresetKey
            : DEFAULT_PERIMETER_PRESET_KEY;
      });
  },
});

function validateValues(data: unknown[]): PerimeterPreset[] {
  return data
    .map(value => {
      const parsed = perimeterPresetSchema.safeParse(value);
      return parsed.success ? parsed.data : undefined;
    })
    .filter(isDefined);
}

const validationSchema = z.array(z.any()).transform(validateValues);

export function validatePerimeterPresets(array: unknown): PerimeterPreset[] {
  return validateAndLog('Perimeters', validationSchema, array) ?? [];
}

export function addNewPerimeterPresetToSessionServiceThunk(
  newPerimeterPreset: PerimeterPreset,
): AppThunk {
  return async (dispatch, getState) => {
    const { team } = newPerimeterPreset;
    const { perimeterPresets } = getState();

    const newPerimeterPresets = [
      ...removeOneFromArray(
        perimeterPresets.presets,
        ({ team, name }) => team === newPerimeterPreset.team && name === newPerimeterPreset.name,
      ),
      newPerimeterPreset,
    ];

    dispatch(perimeterPresetsSlice.actions.setValue({ presets: newPerimeterPresets }));

    const presetsByTeam = newPerimeterPresets.filter(
      perimeterPreset => perimeterPreset.team === team,
    );
    await saveUserSessionService(
      team,
      'perimeterPresets',
      presetsByTeam,
      z.array(perimeterPresetSchema),
    );
  };
}

export function selectActivePerimeterPreset(appState: AppState): PerimeterPreset | undefined {
  const activePerimeterPresetKey = appState.perimeterPresets.activePresetKey;
  return appState.perimeterPresets.presets.find(
    (preset: PerimeterPreset) =>
      preset.name === activePerimeterPresetKey.name &&
      preset.team === activePerimeterPresetKey.team,
  );
}

export function deletePerimeterPresetFromSessionServiceThunk(
  team: string,
  presetNames: string[],
): AppThunk {
  return async (dispatch, getState) => {
    const presetKeys: PerimeterPresetKey[] = presetNames.map(name => ({ team, name }));
    dispatch(perimeterPresetsSlice.actions.deletePerimeterPresets({ presetKeys }));
    const {
      perimeterPresets: { presets },
    } = getState();

    const presetsOfTeam = presets.filter(p => p.team === team);
    await saveUserSessionService(
      team,
      'perimeterPresets',
      presetsOfTeam,
      z.array(perimeterPresetSchema),
    );
  };
}

export const fetchPerimeterPresets = createAsyncThunk<PerimeterPreset[], void, { state: AppState }>(
  'perimeterPresets/fetchPerimeterPresets',
  async (_, { getState }) => {
    const user = userSlice.selectors.user(getState());
    return loadAndMergeByTeamSessionService(
      'perimeterPresets',
      user.teams,
      validatePerimeterPresets,
    );
  },
);

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import type { Hierarchy } from '@/core/hierachies.ts';

export interface DraftQueryState {
  draftSelectedHierarchies: Hierarchy[];
  liveUpdates: boolean;
  liveUpdatesPausedTime: string | undefined;
}

const initialState: DraftQueryState = {
  draftSelectedHierarchies: [],
  liveUpdates: true,
  liveUpdatesPausedTime: undefined,
};

export const draftQuerySlice = createSlice({
  name: 'query',
  initialState: initialState,
  reducers: {
    setDraftHierarchies: (state, action: PayloadAction<Hierarchy[]>) => {
      state.draftSelectedHierarchies = action.payload;
    },

    setLiveUpdates: (state, action: PayloadAction<boolean>) => {
      state.liveUpdates = action.payload;
      state.liveUpdatesPausedTime = action.payload ? undefined : format(new Date(), 'HH:mm');
    },
  },
});

import type { VisualizationPreset } from '@/store/slices/prefs/visualizationPresetsSlice.ts';

export const mainViewDeltaDefaultPresets: VisualizationPreset[] = [
  {
    name: 'Delta - Default',
    team: 'DEFAULT',
    columnState: [
      {
        hide: false,
        sort: null,
        colId: 'Hierarchy',
        width: 447,
        pinned: 'left',
      },
      {
        hide: true,
        sort: null,
        colId: 'Currency',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'UnderlyerSpotRTMove',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'UnderlyerSpotRT',
        width: 120,
        pinned: 'left',
      },
      {
        hide: true,
        sort: null,
        colId: 'UnderlyerSpotRisk',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'Spot0',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'DeltaCurrencyIntraday',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'DeltaOpenRTIntraday',
        width: 155,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'NDeltaOpenMove',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'XGamma',
        width: 140,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'XGammaDiag',
        width: 140,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'DeltaJumpOpenCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'DeltaJumpOpen',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'NDeltaOpenMoveRTDisplayCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'CrossGammaCrossOpenRTDisplayCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'CrossGammaDiagOpenRTDisplayCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: 'desc',
        colId: 'DeltaExcludingFixingOpenRTDisplayCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'DeltaOpenIntraday',
        width: 135,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'DeltaDailyCurrency',
        width: 130,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'DeltaDaily',
        width: 170,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'DeltaCurrencyOfficial',
        width: 130,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'DeltaOpenOfficial',
        width: 160,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'Gamma',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'GammaDaily',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'GammaOpen',
        width: 150,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'Vega',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'VegaDaily',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'VegaOpen',
        width: 130,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'Theta',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'ThetaDaily',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'ThetaOpen',
        width: 140,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'PnlTotalE',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'Pnl0E',
        width: 150,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'PnlThetaE',
        width: 120,
        pinned: null,
      },
      {
        colId: 'PnlGammaE',
        hide: true,
        pinned: null,
        sort: null,
        width: 120,
      },
      {
        colId: 'PnlDeltaDayE',
        hide: true,
        pinned: null,
        sort: null,
        width: 120,
      },
      {
        hide: false,
        sort: null,
        colId: 'SpotSource',
        width: 120,
        pinned: null,
      },
      {
        hide: false,
        sort: null,
        colId: 'SpotUpdate',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'CrossGammaTransDisplayCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'CrossGammaTransOpenDisplayCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'CrossGammaTransDailyDisplayCurrency',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'ProductQuantity',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'ProductQuantityOpen',
        width: 120,
        pinned: null,
      },
      {
        hide: true,
        sort: null,
        colId: 'ProductQuantityDaily',
        width: 120,
        pinned: null,
      },
    ],
    hierarchies: [
      'ProfitCenterDesc',
      'UnderlyerCode',
      'UnderlyerType',
      'GOP',
      'PortFolio',
      'PayoffType',
      'ProductType',
      'ProductCode',
      'DealId',
      'ResetId',
      'DefId',
      'ClpId',
    ],
    columnGroupState: [
      {
        open: true,
        groupId: 'DeltaCurrencyIntraday_group',
      },
    ],
    filtersEnabled: true,
  },
];

import { useState, type ChangeEvent } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { getRouteApi, useRouter } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';

import {
  Currencies,
  DeactivatedValues,
  useDeleteScenarioMutation,
  useGetScenariosQuery,
  useUpdateScenarioMutation,
  type CurrencyType,
  type DeactivatedValuesType,
  type MarketConfigs,
  type Scenario,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { MultiselectDropdown } from '@/components/common/bootstrap/MultiselectDropdown.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { DeleteModal } from '@/components/hedger/manage/common/DeleteModal.tsx';
import { EditHeaderFrame, type TitleIds } from '@/components/hedger/manage/common/FrameHeader.tsx';
import { EditScenarioGrid } from '@/components/hedger/manage/scenarios/EditScenarioGrid.tsx';
import { safeParseNumber } from '@/utils/libs/safeParseNumber.ts';

export type ScenarioData = Omit<Scenario, 'scenarioId' | 'marketConfigs'>;

interface EditScenarioFrameProps {
  scenario: Scenario;
  deleteScenario: (item: Pick<Scenario, 'scenarioId'>) => { unwrap: () => Promise<Scenario> };
  updateScenario: (item: Scenario) => { unwrap: () => Promise<Scenario> };
  closeScenario: () => void;
}

const scenarioTitleIds: TitleIds = {
  Title: 'Hedger.ScenariosModal.EditScenario.Title',
  Delete: 'Hedger.ScenariosModal.EditScenario.Delete',
};

const hedgerEditScenarioRouteApi = getRouteApi('/hedger/scenarios/edit/$scenarioId');

export function EditScenario(): JSX.Element {
  const { scenarioId } = hedgerEditScenarioRouteApi.useParams();
  const {
    scenario,
    isLoading: isGetLoading,
    isUninitialized: isGetScenarioUninitialized,
    isError: isGetScenarioError,
    error: getScenarioError,
  } = useGetScenariosQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      scenario: result.data?.scenarios.find(
        scenario => scenario.scenarioId === safeParseNumber(scenarioId),
      ),
    }),
  });
  const [updateScenario, { isLoading: isUpdateLoading, originalArgs, isError }] =
    useUpdateScenarioMutation();
  const [deleteScenario, { isLoading: isDeleteLoading }] = useDeleteScenarioMutation();
  const { navigate } = useRouter();
  const closeScenario = () => navigate({ to: '/hedger/scenarios' });

  if (isGetLoading || isGetScenarioUninitialized || isUpdateLoading || isDeleteLoading) {
    return <Loader />;
  }
  if (isGetScenarioError) {
    return <FetchErrorView error={getScenarioError} />;
  }

  if (scenario === undefined) {
    return (
      <ErrorPanel>
        No scenario of id <span className="fw-semibold">{scenarioId}</span>.
      </ErrorPanel>
    );
  }
  const currentEditScenario = isError && originalArgs != null ? originalArgs : scenario;

  return (
    <EditScenarioFrame
      scenario={currentEditScenario}
      deleteScenario={deleteScenario}
      updateScenario={updateScenario}
      closeScenario={closeScenario}
    />
  );
}

function EditScenarioFrame({
  scenario,
  deleteScenario,
  updateScenario,
  closeScenario,
}: EditScenarioFrameProps): JSX.Element {
  const { marketConfigs, ...initScenarioData } = scenario;
  const teams = useAppSelector(userSlice.selectors.teams);
  const [currentScenarioData, setCurrentScenarioData] = useState<ScenarioData>(initScenarioData);
  const [currentMarketConfigs, setCurrentMarketConfigs] = useState<MarketConfigs>(marketConfigs);
  const deleteModal = useModal(DeleteModal);

  const getScenarioInputId = (key: keyof ScenarioData): string => `${scenario.scenarioId}_${key}`;

  const onSaveClick = async () => {
    const updatedScenario = {
      scenarioId: scenario.scenarioId,
      ...currentScenarioData,
      marketConfigs: currentMarketConfigs,
    };
    updateScenario(updatedScenario)
      .unwrap()
      .then(closeScenario)
      .catch(error => handleHedgerError('Error while saving scenario', error));
  };

  const onDeleteClick = () => {
    const onConfirmClick = async () => {
      deleteScenario({ scenarioId: scenario.scenarioId })
        .unwrap()
        .then(closeScenario)
        .catch(error => handleHedgerError('Error while deleting scenario', error));
    };
    deleteModal.show({
      onConfirmClick,
      type: 'Scenario',
    });
  };

  const onScenarioDataChange = (value: ChangeEvent<HTMLInputElement>) => {
    const key = value.currentTarget.id.split('_')[1] as keyof ScenarioData;
    const currentValue = value.currentTarget.value;
    setCurrentScenarioData(currentState => ({ ...currentState, [key]: currentValue }));
  };
  const onTeamChange = (team: string) => {
    setCurrentScenarioData(currentState => ({ ...currentState, team }));
  };
  const onCurrencyChange = (currency: CurrencyType) => {
    setCurrentScenarioData(currentState => ({ ...currentState, ordersMetricsCurrency: currency }));
  };

  return (
    <div className="d-flex flex-column mx-auto w-75 gap-3 flex-grow-1 mb-3">
      <div className="d-flex flex-column bg-lvl2 gap-3 p-3 border flex-grow-1 border-info">
        <EditHeaderFrame
          titleIds={scenarioTitleIds}
          onCancelClick={closeScenario}
          onSaveClick={onSaveClick}
          onDeleteClick={onDeleteClick}
        />
        <div className="d-flex flex-wrap from-group-container flex-wrap column-gap-5">
          <div className="form-group-deprecated form-label">
            <label htmlFor={getScenarioInputId('name')} className="form-label">
              <FormattedMessage id="Hedger.Scenario.Name" />
            </label>
            <input
              className="form-control"
              onChange={onScenarioDataChange}
              type="text"
              value={currentScenarioData.name}
              id={getScenarioInputId('name')}
            />
          </div>
          <div className="form-group-deprecated">
            <label htmlFor={getScenarioInputId('team')} className="form-label">
              Owner team
            </label>
            <Select<string>
              id={getScenarioInputId('team')}
              itemsAsObjects={teams}
              selectedItemValue={currentScenarioData.team}
              onChange={onTeamChange}
            />
          </div>
          <div className="form-group-deprecated">
            <label htmlFor={getScenarioInputId('ordersMetricsCurrency')} className="form-label">
              Currency
            </label>
            <Select<CurrencyType>
              id={getScenarioInputId('ordersMetricsCurrency')}
              itemsAsObjects={Currencies}
              selectedItemValue={currentScenarioData.ordersMetricsCurrency}
              onChange={onCurrencyChange}
            />
          </div>
          <MultiselectDropdown<DeactivatedValuesType>
            selectedItems={currentScenarioData.deactivatedProductTypes}
            itemsToFreeze={['UNKNOWN', 'INDEX', 'BASKET']}
            items={DeactivatedValues}
            onChange={deactivatedProductTypes => {
              setCurrentScenarioData(currentState => ({
                ...currentState,
                deactivatedProductTypes,
              }));
            }}
            label={'Deactivated product type'}
          />
        </div>
        <EditScenarioGrid
          marketConfigsDefault={marketConfigs}
          onMarketConfigsUpdate={setCurrentMarketConfigs}
          currency={currentScenarioData.ordersMetricsCurrency}
        />
      </div>
    </div>
  );
}

import type EventEmitter from 'events';
import { useEffect, type DependencyList } from 'react';

// those type bellong to EventEmitter lib, but they  are not exported so we have to copy them...
type EventMap<T> = Record<keyof T, any[]> | DefaultEventMap;
type DefaultEventMap = [never];
type Key<K, T> = T extends DefaultEventMap ? string | symbol : K | keyof T;
type Listener<K, T, F> = T extends DefaultEventMap
  ? F
  : K extends keyof T
    ? T[K] extends unknown[]
      ? (...args: T[K]) => void
      : never
    : never;

type Listener1<K, T> = Listener<K, T, (...args: any[]) => void>;

export function useEventEmitter<T extends EventMap<T>, K extends keyof T>(
  eventEmitter: EventEmitter<T>,
  event: Key<K, T>,
  listener: Listener1<K, T>,
  deps: DependencyList = [listener],
) {
  return useEffect(() => {
    eventEmitter.on(event, listener);
    return () => {
      eventEmitter.off(event, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

import type { GetContextMenuItemsParams } from '@ag-grid-community/core';

/**
 * A factory to create an expand/collapse function for getContextMenuItems in ag-grid.
 * @param params the ag-grid param
   @param expanded expand = false will return a collapse function, expand = true will return an expand function
 * @param withCousins whether or not it should also expand/collapse the cousin node
 */
export function expandOrCollapseFactory(
  params: GetContextMenuItemsParams,
  expanded: boolean,
  withCousins = false,
) {
  return () =>
    params.api.forEachNode(node => {
      if (
        node.level === params.node?.level &&
        (withCousins || node.parent?.id === params.node?.parent?.id)
      ) {
        params.api.setRowNodeExpanded(node, expanded);
      }
    });
}

import type { VisualizationPreset } from '@/store/slices/prefs/visualizationPresetsSlice.ts';
import type { UserRole } from '@/store/slices/user/userSlice.ts';
import { defaultProfilePresets } from '@/web/presets/profilePresets/defaultProfilePresets.ts';
import { mainViewDeltaDefaultPresets } from '@/web/presets/profilePresets/mainViewDelta.ts';
import { pnlDefaultPresets } from '@/web/presets/profilePresets/pnlPresets.ts';

export const DEFAULT_PRESET_NAME = 'DEFAULT';

// This object is used to define which presets are linked to a profile, with the default one being named DEFAULT_PRESET_NAME
// To add visualization presets for a specific profile, add the preset file in the folder profilePresets
// If you add a new file, do not forget to add the presets to the allDefaultProfilePresetNames file
export const presetOverrides: Record<UserRole, VisualizationPreset[]> = {
  Support: [...defaultProfilePresets, ...pnlDefaultPresets, ...mainViewDeltaDefaultPresets],
  HedgerAmer: [...defaultProfilePresets],
  TraderDLP: [...defaultProfilePresets],
  TraderExo: [...defaultProfilePresets, ...pnlDefaultPresets, ...mainViewDeltaDefaultPresets],
  TraderFSI: [...defaultProfilePresets],
  TraderIDX: [...defaultProfilePresets],
  Management: [...defaultProfilePresets],
  Devci: [...defaultProfilePresets],
  ReadOnly: [...defaultProfilePresets],
};

export function loadProfilePresetDefaults(userRole: UserRole): VisualizationPreset[] {
  return presetOverrides[userRole] ?? [];
}

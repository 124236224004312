import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';

import { equityRiskLoader } from '@/components/equityRisk/equityRiskLoader.tsx';
import { validateOnePermission } from '@/components/router/core/validateOnePermission.tsx';
import { EquityErrorComponent } from '@/components/router/EquityErrorComponent.tsx';

export const Route = createFileRoute('/')({
  beforeLoad: validateOnePermission('viewEquityRisk', 'devci'),

  loader: equityRiskLoader,
  component: lazyRouteComponent(
    () => import('@/components/equityRisk/EquityRiskPage'),
    'EquityRiskPage',
  ),
  errorComponent: EquityErrorComponent,
  wrapInSuspense: false,
  // No loader cache. https://tanstack.com/router/v1/docs/guide/data-loading#using-shouldreload-and-gctime-to-opt-out-of-caching
  gcTime: 0,
  shouldReload: false,
});

import { FormattedMessage } from 'react-intl';

import { BorderItem } from '@/components/common/bootstrap/BorderItem.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import type { IntlMessages } from '@/types/intl';

export type TitleIds = Record<'Delete' | 'Title', IntlMessages>;
export interface EditFrameHeaderProps {
  onCancelClick: () => void;
  onSaveClick: () => void;
  onDeleteClick?: () => void;
  titleIds: TitleIds;
  disableDelete?: boolean;
}

export function EditHeaderFrame({
  onCancelClick,
  onSaveClick,
  titleIds,
  onDeleteClick,
  disableDelete = false,
}: EditFrameHeaderProps): JSX.Element {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <h4 className="text-info fw-semibold">
        <FormattedMessage id={titleIds['Title']} />
      </h4>
      <div className="d-flex gap-4">
        <Button
          size="lg"
          disabled={disableDelete}
          discreet={true}
          className="d-flex gap-4 btn-discreet-danger"
          onClick={onDeleteClick}
        >
          <FormattedMessage id={titleIds['Delete']} />
        </Button>
        <BorderItem />
        <div className="d-flex gap-2">
          <Button size="lg" onClick={onCancelClick}>
            <FormattedMessage id="Hedger.ScenariosModal.EditFrame.Cancel" />
          </Button>
          <Button size="lg" discreet={true} className="btn-discreet-info " onClick={onSaveClick}>
            <FormattedMessage id="Hedger.ScenariosModal.EditFrame.Save" />
          </Button>
        </div>
      </div>
    </div>
  );
}

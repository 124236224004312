export const mdxStructuralKeywords = [
  'AS',
  'AXIS',
  'CASE',
  'CELL',
  'DIMENSION',
  'DRILLTHROUGH',
  'ELSE',
  'END',
  'EXISTING',
  'EMPTY',
  'FIRSTROW',
  'FROM',
  'MAXROWS',
  'MEMBER',
  'NON',
  'ON',
  'PROPERTIES',
  'RETURN',
  'SELECT',
  'SET',
  'THEN',
  'VISUAL',
  'WHEN',
  'WHERE',
  'WITH',
];
export const mdxAxisKeywords = ['CHAPTERS', 'COLUMNS', 'PAGES', 'ROWS', 'SECTIONS', 'SLICER'];
export const functionKeywords = [
  'Abs',
  'AddCalculatedMembers',
  'Aggregate',
  'AllMembers',
  'Ancestor',
  'Ancestors',
  'Ascendants',
  'Avg',
  'Axis',
  'BottomCount',
  'BottomPercent',
  'BottomSum',
  'CalculationCurrentPass',
  'CalculationPassValue',
  'CASE Statement',
  'CDate',
  'Children',
  'ClosingPeriod',
  'CoalesceEmpty',
  'Correlation',
  'Count',
  'Cousin',
  'Covariance',
  'CovarianceN',
  'Crossjoin',
  'Current',
  'CurrentMember',
  'CurrentOrdinal',
  'CustomData',
  'DataMember',
  'DefaultMember',
  'Descendants',
  'Dimension',
  'Dimensions',
  'Distinct',
  'DistinctCount',
  'Divide',
  'DrilldownLevel',
  'DrilldownLevelBottom',
  'DrilldownLevelTop',
  'DrilldownMember',
  'DrilldownMemberBottom',
  'DrilldownMemberTop',
  'DrillupLevel',
  'DrillupMember',
  'Error',
  'Except',
  'Exists',
  'Extract',
  'Filter',
  'FirstChild',
  'FirstSibling',
  'Generate',
  'Head',
  'Hierarchize',
  'Hierarchy',
  'IIf',
  'Instr',
  'Intersect',
  'IsAncestor',
  'IsDate',
  'IsEmpty',
  'IsGeneration',
  'IsLeaf',
  'IsSibling',
  'Item',
  'Lag',
  'LastChild',
  'LastPeriods',
  'LastSibling',
  'Lead',
  'Leaves',
  'Level',
  'Levels',
  'LinkMember',
  'LinRegIntercept',
  'LinRegPoint',
  'LinRegR2',
  'LinRegSlope',
  'LinRegVariance',
  'LookupCube',
  'Max',
  'MeasureGroupMeasures',
  'Median',
  'Members',
  'MemberToStr',
  'MemberValue',
  'Min',
  'Mtd',
  'Name',
  'NameToSet',
  'NextMember',
  'NonEmpty',
  'NonEmptyCrossjoin',
  'OpeningPeriod',
  'Order',
  'Ordinal',
  'ParallelPeriod',
  'Parent',
  'PeriodsToDate',
  'Predict',
  'PrevMember',
  'Properties',
  'Qtd',
  'Rank',
  'Rgb',
  'RollupChildren',
  'Root',
  'SetToArray',
  'SetToStr',
  'Siblings',
  'Stddev',
  'StddevP',
  'Stdev',
  'StdevP',
  'StripCalculatedMembers',
  'StrToMember',
  'StrToSet',
  'StrToTuple',
  'StrToValue',
  'Subset',
  'Sum',
  'Tail',
  'This',
  'ToggleDrillState',
  'TopCount',
  'TopPercent',
  'TopSum',
  'TupleToStr',
  'Union',
  'UniqueName',
  'UnknownMember',
  'Unorder',
  'UserName',
  'ValidMeasure',
  'Value',
  'Var',
  'Variance',
  'VarianceP',
  'VarP',
  'VisualTotals',
  'Wtd',
  'Ytd',
];
export const kpiKeywords = [
  'KPICurrentTimeMember',
  'KPIExpiry',
  'KPIGoal',
  'KPIStart',
  'KPIStatus',
  'KPITrend',
  'KPIValue',
  'KPIWeight',
];
export const argumentKeywords = [
  'ALL',
  'DIMENSIONS',
  'IGNORE',
  'END',
  'ASC',
  'DESC',
  'BASC',
  'BDESC',
  'POST',
  'AFTER',
  'BEFORE',
  'BEFORE_AND_AFTER',
  'LEAVES',
  'SELF',
  'SELF_AND_AFTER',
  'SELF_AND_BEFORE',
  'SELF_BEFORE_AFTER',
  'EXCLUDEEMPTY',
  'INCLUDEEMPTY',
  'RECURSIVE',
  'INCLUDE_CALC_MEMBERS',
  'CONSTRAINED',
  'TYPED',
];
export const memberPropertyKeywords = [
  'ANCESTOR_CAPTION',
  'CATALOG_NAME',
  'CHILDREN_CARDINALITY',
  'CUBE_NAME',
  'DESCRIPTION',
  'DISPLAY_INFO',
  'DIMENSION_UNIQUE_NAME',
  'HIERARCHY_UNIQUE_NAME',
  'IS_DATAMEMBER',
  'IS_PLACEHOLDERMEMBER',
  'IS_UNKNOWNMEMBER',
  'LEVEL_NUMBER',
  'LEVEL_UNIQUE_NAME',
  'MEMBER_CAPTION',
  'MEMBER_GUID',
  'MEMBER_KEY',
  'MEMBER_NAME',
  'MEMBER_ORDINAL',
  'MEMBER_TYPE',
  'MEMBER_VALUE',
  'MEMBER_UNIQUE_NAME',
  'PARENT_COUNT',
  'PARENT_LEVEL',
  'PARENT_UNIQUE_NAME',
  'SCHEMA_NAME',
  'SKIPPED_LEVELS',
  'UNARY_OPERATOR',
  'UNIQUE_NAME',
];
export const cellPropertyKeywords = [
  'BACK_COLOR',
  'CELL_ORDINAL',
  'FONT_FLAGS',
  'FONT_NAME',
  'FONT_SIZE',
  'FORE_COLOR',
  'FORMAT',
  'FORMAT_STRING',
  'FORMATTED_VALUE',
  'VALUE',
];
export const booleanOperatorKeywords = ['AND', 'IS', 'NOT', 'OR', 'XOR'];
export const booleanKeywords = ['FALSE', 'NULL', 'TRUE'];

import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { store } from '@/store/store.ts';
import { getHedgerError } from '@/components/hedger/common/getHedgerErrorMessage.ts';
import { logger } from '@/utils/libs/logger.ts';

export function handleHedgerError(title: string, error: unknown): void {
  const dispatch = store.dispatch;
  const hedgerError = getHedgerError(error);
  if (hedgerError === undefined) {
    logger.logError('Unknown hedger error {error_s}', JSON.stringify(error));
    dispatch(addErrorToastThunk('Unknown hedger error'));
    return;
  }

  const joinedErrorMessage = hedgerError.errorMessages
    .map(({ field, message }) => `${field}: ${message}`)
    .join('\n');

  dispatch(addErrorToastThunk(joinedErrorMessage, title));
  logger.logError(`${title} {error_s}`, joinedErrorMessage);
}

import type { RiskAnalysis, StormAnalysis, StormInfo } from '@/store/api/hedgerApi/hedgerApi.ts';

export interface AnalysisRow {
  stormInfo: StormInfo;
  riskAnalysis: RiskAnalysis;
}

export function toAnalysisRows(allAnalyses: StormAnalysis[]): AnalysisRow[] {
  return allAnalyses.flatMap(analysis =>
    analysis.rtRiskAnalyses.map(riskAnalysis => ({
      stormInfo: analysis.stormInfo,
      riskAnalysis,
    })),
  );
}

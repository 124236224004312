import { memo, useMemo, useState } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type {
  CellValueChangedEvent,
  GridApi,
  GridReadyEvent,
  RowDataUpdatedEvent,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';

import { useGetRefsMarketsQuery } from '@/store/api/hedgerApi/hedgerApi.ts';
import { useGetRecipientsQuery } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import type { CurrencyType, MarketConfigs } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { getEditMarketConfigsColDef } from '@/components/hedger/manage/scenarios/marketConfigs.tsx';
import type { MarketConfigRowData } from '@/components/hedger/manage/scenarios/ViewScenarios.tsx';
import { getCurrentRowData } from '@/utils/agGrid/agGrid.ts';
import { objectValues } from '@/utils/libs/entries.ts';
import { isQueryHookResultReady } from '@/utils/rtk/predicates.ts';

interface EditMarketConfigGridProps {
  marketConfigsDefault?: MarketConfigs;
  onMarketConfigsUpdate: (marketConfigs: MarketConfigs) => void;
  currency: CurrencyType;
}

const defaultRowData = {
  active: true,
  hedgePercent: 0,
  greek: '',
  marketId: '',
  advParticipationPercent: 0,
  limitMax: 0,
  limitMin: 0,
  recipientId: 0,
};

export const EditScenarioGrid = memo(EditMarketConfigsGrid);

function EditMarketConfigsGrid({
  marketConfigsDefault = {},
  onMarketConfigsUpdate,
  currency,
}: EditMarketConfigGridProps) {
  const getRefsMarketsQuery = useGetRefsMarketsQuery();

  const { data: recipientData } = useGetRecipientsQuery();
  const [gridApi, setGridApi] = useState<GridApi<MarketConfigRowData>>();
  const rowData: MarketConfigRowData[] = useMemo(
    () =>
      objectValues(marketConfigsDefault).map((marketConfig, index) => ({
        ...marketConfig,
        rowId: index.toString(),
      })),
    [marketConfigsDefault],
  );

  const [lastRowIndex, setLastRowIndex] = useState(rowData.length - 1);

  if (!isQueryHookResultReady(getRefsMarketsQuery)) {
    return <Loader />;
  }

  if (getRefsMarketsQuery.isError) {
    return <FetchErrorView error={getRefsMarketsQuery.error} />;
  }

  const onGridReady = ({ api }: GridReadyEvent<MarketConfigRowData>) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  const marketConfigsColDef = getEditMarketConfigsColDef(
    recipientData?.recipients ?? [],
    getRefsMarketsQuery.data.markets ?? [],
    currency,
  );

  const addNewLine = () => {
    if (gridApi == null) {
      return;
    }
    const rowId = `${lastRowIndex + 1}`;
    const newRowData = {
      rowId,
      ...defaultRowData,
    };
    gridApi.applyTransaction({ add: [newRowData] });
    gridApi.ensureNodeVisible(newRowData);
    setLastRowIndex(previousValue => lastRowIndex + 1);
  };

  const onRowDataUpdated = (rowDataUpdated: RowDataUpdatedEvent<MarketConfigRowData>) => {
    if (gridApi === undefined) {
      return;
    }
    const currentMarketConfigs: MarketConfigs = getMarketConfigsFromRowData(
      getCurrentRowData(rowDataUpdated.api),
    );
    onMarketConfigsUpdate(currentMarketConfigs);
  };

  const onCellValueChanged = (event: CellValueChangedEvent<MarketConfigRowData>) => {
    const currentMarketConfigs: MarketConfigs = getMarketConfigsFromRowData(
      getCurrentRowData<MarketConfigRowData>(event.api),
    );
    onMarketConfigsUpdate(currentMarketConfigs);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="display-5">Markets</div>
        <Button discreet={true} variant="primary" onClick={addNewLine}>
          Add new line
        </Button>
      </div>

      <AgGridReact<MarketConfigRowData>
        modules={[ClientSideRowModelModule, RangeSelectionModule, RichSelectModule]}
        className="ag-theme-alpine ag-theme-era"
        onCellValueChanged={onCellValueChanged}
        singleClickEdit={true}
        onRowDataUpdated={onRowDataUpdated}
        columnDefs={marketConfigsColDef}
        enableRangeSelection={true}
        rowData={rowData}
        onGridReady={onGridReady}
        getRowId={rowData => `${rowData.data.rowId}`}
      />
    </>
  );
}

function getMarketConfigsFromRowData(rowData: MarketConfigRowData[]): MarketConfigs {
  return rowData.reduce((acc, marketConfigRowData) => {
    const { rowId, ...marketConfig } = marketConfigRowData;
    acc[marketConfig.marketId] = marketConfig;
    return acc;
  }, {} as MarketConfigs);
}

import type { PropsWithChildren } from 'react';
import Editor, { type OnMount } from '@monaco-editor/react';
import type { editor } from 'monaco-editor/esm/vs/editor/editor.api';

import { useAppSelector } from '@/store/hooks.ts';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';

type MyEditorParams = PropsWithChildren<{
  value?: string;
  title?: string;
  defaultValue?: string;
  language: string;
  onMount?: OnMount;
  onChange?: (value: string) => void;
  options?: editor.IStandaloneEditorConstructionOptions;
}>;

export function MyEditor({
  value,
  title,
  defaultValue,
  onChange,
  language,
  children,
  onMount,
  options,
}: MyEditorParams) {
  const theme = useAppSelector(state => state.ui.theme);

  return (
    <div className="d-flex flex-column h-100 align-items-center">
      {title !== undefined ? <h2>{title}</h2> : null}
      <Editor
        className="flex-grow"
        theme={theme === 'LIGHT' ? 'light' : 'vs-dark'}
        // height="calc(100% - 100px)"
        options={options}
        onMount={onMount}
        defaultLanguage={language}
        loading={<Loader />}
        defaultValue={defaultValue}
        value={value}
        onChange={v => onChange?.(v!)}
      />
      <div>{children}</div>
    </div>
  );
}

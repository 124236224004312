import { useCallback, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { type Updater } from '@/components/hedger/manage/recipients/details/EditTable.tsx';
import {
  mapFromTableDatas,
  mapToTableDatas,
} from '@/components/hedger/manage/recipients/details/mappers.ts';
import {
  MultiEditTable,
  type TableData,
} from '@/components/hedger/manage/recipients/details/MultiEditTable.tsx';
import type { EditAdditionalFieldsGrid } from '@/components/hedger/manage/recipients/EditRecipientGrid.tsx';
import type { IntlMessages } from '@/types/intl';

export type MultiEditTableModalProps = {
  name: string;
  titleId?: IntlMessages;
  buttonTexts: { add: string; delete: string };
  updateAdditionalField: EditAdditionalFieldsGrid['updateAdditionalField'];
  data: Record<string, Record<string, string>>;
};

export const MultiEditTableModal = NiceModal.create(function EditTableModal(
  props: MultiEditTableModalProps,
) {
  const { data: dataInit, titleId, name, updateAdditionalField } = props;

  const modal = useModal();

  const [tableDatas, setTableDatas] = useState<TableData[]>(mapToTableDatas(dataInit));

  const onEditMultiTableUpdate = useCallback((updater: Updater<TableData[]>) => {
    setTableDatas(tableDatas => updater(tableDatas));
  }, []);

  const onConfirmClick = () => {
    const additionalField = mapFromTableDatas(tableDatas);

    updateAdditionalField(name, additionalField);
    modal.remove();
  };

  return (
    <BootstrapModal
      style={{ minHeight: '28em', minWidth: '51em' }}
      footer={
        <div className="d-flex gap-1">
          <Button className={'ps-3 pe-3 pt-2 pb-2'} flat size="lg" onClick={() => modal.remove()}>
            Cancel
          </Button>
          <Button
            className={'ps-3 pe-3 pt-2 pb-2'}
            variant="primary"
            size="lg"
            onClick={onConfirmClick}
          >
            Confirm
          </Button>
        </div>
      }
      titleId={titleId}
    >
      <MultiEditTable
        tableDatas={tableDatas}
        onUpdate={onEditMultiTableUpdate}
        buttonTexts={props.buttonTexts}
      />
    </BootstrapModal>
  );
});

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { CommonGridState } from '@/store/slices/prefs/commonGridStateSchema.ts';

export interface EquityRiskState {
  gridState: CommonGridState | undefined;
}

const initialState: EquityRiskState = {
  gridState: undefined,
};
export const equityRiskSlice = createSlice({
  name: 'equity',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<EquityRiskState>) => {
      return action.payload;
    },
    setValue: (state, action: PayloadAction<Partial<EquityRiskState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

import { LogBuilder } from '@sgme/logger';
import { Int32Histogram } from 'hdr-histogram-js';

import { getPathFromQueryId } from '@/core/webSocket/queryId.ts';
import { getConfig } from '@/utils/config/config.ts';
import { isNode } from '@/utils/libs/isNode.ts';
import { authTokenFactory } from '@/utils/libs/logger.ts';

let perfLogBuilder = LogBuilder();

if (!isNode) {
  if (process.env.NODE_ENV === 'production') {
    perfLogBuilder = perfLogBuilder
      .enableConsole({ minLevel: 'INFO' })
      .enableServerLogs({
        storeName: 'perf',
        url: `${getConfig().apiUrl}/api/eventLogs`,
        prefix: '[PERF]',
      })
      .setAuthTokenFactory(authTokenFactory);
  } else {
    perfLogBuilder = perfLogBuilder.enableConsole({ minLevel: 'INFO' });
  }
}

export const internalPerfLogger = perfLogBuilder.build();

type EventType = 'rootQueryResponse' | 'gridReady' | 'dataReceived';

export interface EventLog extends Record<any, any> {
  event: EventType;
}

interface HistogramEntry {
  path: string;
  histogram: Int32Histogram;
  lastTime: number;
}

function getTime() {
  return new Date().getTime();
}

const INTERVAL_TIMEOUT = 1_000 * 60 * 10; // 10 minutes

class PerfLogger {
  private histograms: Record<string, HistogramEntry> = {};
  private intervalId = setInterval(() => this.onInterval(), INTERVAL_TIMEOUT);
  private startTime: number = 0;

  createHistogram(queryId: string) {
    const histogram = new Int32Histogram(250, 5000, 3);
    this.histograms[queryId] = {
      histogram,
      path: getPathFromQueryId(queryId),
      lastTime: getTime(),
    };
  }

  recordHistogramTime(queryId: string) {
    const entry = this.histograms[queryId];
    if (entry !== undefined) {
      const time = getTime();
      const delta = time - entry.lastTime;
      entry.histogram.recordValue(delta);
      entry.lastTime = time;
    }
  }

  destroyHistogram(queryId: string) {
    this.histograms[queryId]?.histogram.destroy();
    delete this.histograms[queryId];
  }

  startTimer() {
    this.startTime = getTime();
  }

  time(eventName: EventType) {
    const time = getTime();
    const delta = time - this.startTime;
    this.log({ event: eventName, time: delta });
  }

  private log(eventLog: EventLog) {
    internalPerfLogger.logInformation('{@data}', eventLog);
  }

  private onInterval() {
    Object.values(this.histograms).forEach(({ histogram, path }) => {
      // TODO: add threshold to avoid sending near empty histogram?
      const data: EventLog = {
        event: 'dataReceived',
        path,
        mean: histogram.mean,
        minValue: histogram.minNonZeroValue,
        maxValue: histogram.maxValue,
        p75: histogram.getValueAtPercentile(75),
        p99: histogram.getValueAtPercentile(99),
        count: histogram.totalCount,
      };
      this.log(data);
      histogram.reset();
    });
  }
}

export const perfLogger = new PerfLogger();

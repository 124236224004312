import { LogBuilder } from '@sgme/logger';

import { getConfig } from '@/utils/config/config.ts';
import { isNode } from '@/utils/libs/isNode.ts';
import { sgwtConnect } from '@/utils/sgwt/sgwtConnect.ts';

export function authTokenFactory() {
  if (sgwtConnect.isAuthorized()) {
    const authToken = sgwtConnect.getAuthorizationHeader();
    if (authToken) return authToken;
  }
  throw new Error('No authorization header or token. Disconnected ?!');
}

let logBuilder = LogBuilder();

if (!isNode) {
  if (process.env.NODE_ENV === 'production') {
    logBuilder = logBuilder
      .enableConsole({ minLevel: 'INFO' })
      .enableServerLogs({
        storeName: 'main',
        url: `${getConfig().apiUrl}/api/log`,
      })
      .setAuthTokenFactory(authTokenFactory);
  } else {
    logBuilder = logBuilder.enableConsole({ minLevel: 'INFO' });
  }
}

export const logger = logBuilder.build();

import { useRef } from 'react';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

import { Button } from '@/components/common/bootstrap/Button.tsx';
import { MessageTooltip } from '@/components/common/bootstrap/MessageTooltip.tsx';

export function HelpRequestButton(): JSX.Element {
  const ref = useRef(null);
  const { sgwtHelpCenter } = useSgwtWidgets();
  return (
    <MessageTooltip messageId="HelpRequest.Title">
      <Button
        ref={ref}
        flat
        icon
        variant="primary"
        size="lg"
        className="ms-1"
        onClick={() => sgwtHelpCenter?.form()}
      >
        <em className="icon">feedback</em>
      </Button>
    </MessageTooltip>
  );
}

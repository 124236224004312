import { useCallback, type PropsWithChildren } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { Button, type ButtonProps } from '@/components/common/bootstrap/Button.tsx';
import { useKey } from '@/components/common/hooks/useKey.ts';

interface CancelConfirmFooterProps {
  onClose?: () => void;
  closeButtonProps?: ButtonProps;
  closeLabel?: string;
}

export function CloseFooter({
  onClose,
  closeButtonProps,
  closeLabel,
  children,
}: PropsWithChildren<CancelConfirmFooterProps>): JSX.Element {
  const modal = useModal();
  const closeModal = useCallback(() => {
    modal.remove();
  }, [modal]);

  const handleClose = useCallback(() => {
    onClose?.();
    closeModal();
  }, [closeModal, onClose]);

  useKey(e => e.ctrlKey && e.key === 'Enter' && !closeButtonProps?.disabled, handleClose);

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex align-self-end gap-2">
        <Button
          title="Ctrl+Enter"
          size="lg"
          variant="primary"
          {...closeButtonProps}
          onClick={handleClose}
        >
          {closeLabel ?? 'Close'}
        </Button>
      </div>
      {children}
    </div>
  );
}

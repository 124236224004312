import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import type {
  AnalysisSource,
  GreatSource,
  OrderGroupRequest,
  StormDetails,
  StormSource,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { selectActivePerimeterPreset } from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import { presetKeyToString } from '@/store/slices/prefs/presetKey.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { selectUserHasAnyPermission } from '@/store/slices/user/userSlice.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CloseFooter } from '@/components/common/bootstrap/CloseFooter.tsx';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import { ScenarioSelector } from '@/components/hedger/execution/common/ScenarioSelector.tsx';
import { SourceSelector } from '@/components/hedger/execution/common/SourceSelector.tsx';
import { HedgerSettingsModalFooter } from '@/components/hedger/execution/settings/HedgerSettingsModalFooter.tsx';
import { NoAnalysis } from '@/components/hedger/execution/settings/NoAnalysis.tsx';
import {
  StormAnalysisSelector,
  StormInstancesSelector,
} from '@/components/hedger/execution/storm/StormSelectionPanel.tsx';
import { getUrl } from '@/utils/libs/getUrl.ts';

export type HedgerModalResult = OrderGroupRequest;

export type HedgerSettingsModalProps = {
  userRequest?: Partial<OrderGroupRequest>;
  navigate: (opts: any) => Promise<void>;
};

type HedgerSettingsInitData = {
  userRequestSourceInit: AnalysisSource;
  userRequestInstanceNameInit: string | undefined;
  userRequestScenarioIdInit: number | undefined;
};

export const HedgerSettingsModal = NiceModal.create(function HedgerSettingsModal({
  userRequest,
  navigate,
}: HedgerSettingsModalProps) {
  const dispatch = useAppDispatch();
  const modal = useModal();

  const availableSources = useAvailableAnalysisSource();
  const { userRequestInstanceNameInit, userRequestSourceInit, userRequestScenarioIdInit } =
    getHedgerSettingsInitData(userRequest, availableSources);

  const activePerimeterPreset = useAppSelector(selectActivePerimeterPreset);
  const activePerimeterPresetKey = useAppSelector(state => state.perimeterPresets.activePresetKey);
  const isGreatAvailable = useAppSelector(queryCacheSlice.selectors.isStateInitialized);
  const wsUrl = useAppSelector(state => state.query.wsUrl);

  const [selectedScenarioId, setSelectedScenarioId] = useState<number | undefined>(
    userRequestScenarioIdInit,
  );

  const [selectedSource, setSelectedSource] = useState<AnalysisSource>(
    isGreatAvailable ? userRequestSourceInit : 'STORM',
  );
  const defaultPerimeters = useAppSelector(queryCacheSlice.selectors.profitCenters);

  const [selectedStormInstanceName, setSelectedStormInstanceName] = useState<string | undefined>(
    userRequestInstanceNameInit,
  );
  const [stormDetails, setStormDetails] = useState<StormDetails[]>([]);

  if (availableSources.length === 0) {
    return <ErrorModal />;
  }

  const handleConfirm = () => {
    let source: GreatSource | StormSource | undefined;

    if (selectedSource === 'GREAT') {
      const greatInstance = getUrl(wsUrl)?.host;
      if (greatInstance === undefined) {
        dispatch(
          addErrorToastThunk(
            'Your Great Server websocket URL is incorrect, or you may not have access to any cube',
          ),
        );
        return;
      }
      const selectedPerimeterList = activePerimeterPreset?.perimeters ?? defaultPerimeters ?? [];
      source = {
        sourceType: selectedSource,
        greatDetails: {
          greatInstance,
          selectedPerimeterList,
          perimeterPresetName: presetKeyToString(activePerimeterPresetKey),
        },
      };
    } else {
      source = { sourceType: selectedSource, stormDetails };
    }

    const result: HedgerModalResult = {
      scenarioId: selectedScenarioId!,
      source,
    };

    modal.resolve(result);
  };

  const handleSourceChange = (source: AnalysisSource) => {
    setSelectedSource(source);
    if (source === 'GREAT') {
      setSelectedStormInstanceName(undefined);
      setStormDetails([]);
    }
  };

  async function onManageScenarioClick() {
    await navigate({ to: '/hedger/scenarios' });
  }

  const handleManageScenariosClick = () => {
    modal.remove();
    onManageScenarioClick();
  };

  const showStormSelectors = selectedSource === 'STORM';
  const showSourceSelector = availableSources.length > 1 && isGreatAvailable;

  const buttonEnabled =
    (selectedScenarioId !== undefined && showStormSelectors && stormDetails.length > 0) ||
    selectedSource === 'GREAT';

  return (
    <BootstrapModal
      titleId="Hedging.Settings.Title"
      closeOnClickAway={false}
      footer={
        <HedgerSettingsModalFooter
          disabled={!buttonEnabled}
          onConfirm={handleConfirm}
          source={selectedSource}
        />
      }
      style={{ minWidth: '70em' }}
    >
      <ScenarioSelector
        selectedScenarioId={selectedScenarioId}
        onChange={scenarioId => setSelectedScenarioId(scenarioId)}
        onManageScenariosClick={handleManageScenariosClick}
      />
      <ShowIf condition={showSourceSelector}>
        <SourceSelector onClick={handleSourceChange} activeSource={selectedSource} />
      </ShowIf>
      <ShowIf condition={showStormSelectors}>
        <StormInstancesSelector
          onChangeStormInstance={setSelectedStormInstanceName}
          selectedStormName={selectedStormInstanceName}
        />
        {selectedStormInstanceName !== undefined ? (
          <StormAnalysisSelector
            onSelectStormDetails={setStormDetails}
            selectedStormName={selectedStormInstanceName}
            selectedStormDetails={stormDetails}
          />
        ) : (
          <NoAnalysis
            title="HedgerApi.NoInstanceSelected.Title"
            message="HedgerApi.NoInstanceSelected.Message"
          />
        )}
      </ShowIf>
    </BootstrapModal>
  );
});

function getHedgerSettingsInitData(
  userRequest: Partial<OrderGroupRequest> | undefined,
  availableSources: AnalysisSource[],
): HedgerSettingsInitData {
  const userRequestScenarioIdInit = userRequest?.scenarioId;

  const userRequestSource = userRequest?.source;
  const userRequestSourceInit = userRequestSource?.sourceType ?? availableSources[0] ?? 'GREAT';
  const userRequestInstanceNameInit =
    userRequestSource?.sourceType === 'STORM'
      ? userRequestSource.stormDetails.at(0)?.stormName
      : undefined;

  return { userRequestInstanceNameInit, userRequestSourceInit, userRequestScenarioIdInit };
}

function useAvailableAnalysisSource(): AnalysisSource[] {
  const greatSourcePerm = useAppSelector(selectUserHasAnyPermission('useHedgerSourceGreat'));
  const stormSourcePerm = useAppSelector(selectUserHasAnyPermission('useHedgerSourceStorm'));
  const sources: AnalysisSource[] = [];
  if (greatSourcePerm) {
    sources.push('GREAT');
  }
  if (stormSourcePerm) {
    sources.push('STORM');
  }
  return sources;
}

function ErrorModal() {
  return (
    <BootstrapModal
      titleId="Hedging.Settings.Title"
      closeOnClickAway={false}
      footer={<CloseFooter />}
      style={{ minWidth: '70em' }}
    >
      <ErrorPanel>No data source allowed for Hedger. Please contact support</ErrorPanel>
    </BootstrapModal>
  );
}

import { isDefined } from '@sgme/fp';
import { diff } from 'just-diff';
import { z } from 'zod';

import { validateAndLog } from '@/store/slices/prefs/validateAndLog.ts';
import { visualizationPresetSchema } from '@/store/slices/prefs/visualizationPresetSchema.ts';
import type { VisualizationPreset } from '@/store/slices/prefs/visualizationPresetsSlice.ts';
import { fixVisuPreset } from '@/components/equityRisk/gridMappings/fixVisuPreset.ts';

export function validateAndFixVisualizationPresets(array: unknown): VisualizationPreset[] {
  const visualizationPresets = validateVisualizationPresets(array);
  return visualizationPresets.map(fixPresetAndLogDiff);
}

function fixPresetAndLogDiff(visuPreset: VisualizationPreset): VisualizationPreset {
  const fixed = fixVisuPreset(visuPreset);
  if (import.meta.env.DEV) {
    const diffPatch = diff(visuPreset, fixed);
    if (diffPatch.length > 0) {
      console.warn(`VisualisationPreset ${visuPreset.name} contained errors and was fixed:`);
      console.groupCollapsed('diff');
      console.warn(diffPatch);
      console.groupEnd();
      console.groupCollapsed('fixed');
      console.warn(fixed);
      console.groupEnd();
    }
  }
  return fixed;
}

function validateValues(data: unknown[]): VisualizationPreset[] {
  return data
    .map(value => {
      const parsed = visualizationPresetSchema.safeParse(value);
      return parsed.success ? parsed.data : undefined;
    })
    .filter(isDefined);
}

const validationSchema = z.array(z.any()).transform(validateValues);

export function validateVisualizationPresets(array: unknown): VisualizationPreset[] {
  return validateAndLog('VisualizationPresetsState', validationSchema, array) ?? [];
}

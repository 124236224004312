import { stripWhitespaces } from '@/core/query/mdx/utils.ts';
import type {
  ActionQueryRequestParams,
  RegisterQueryRequestParams,
  UnregisterQueryRequestParams,
} from '@/core/webSocket/types.ts';

export function getActionQueryRequest(
  id: string,
  action: 'PAUSE' | 'RESUME',
): ActionQueryRequestParams {
  return { data: id, action };
}

// #Subscribers
export function getRegisterQueryRequest(
  id: string,
  mdx: string,
  initialState: 'STARTED' | 'PAUSED',
  contextValues = {},
): RegisterQueryRequestParams {
  return {
    action: 'REGISTER',
    data: {
      streamId: id,
      queryId: id,
      mdxQuery: {
        mdx: stripWhitespaces(mdx),
        context: contextValues,
      },
      initialState,
    },
  };
}

export function getUnregisterQueryRequest(queryId: string): UnregisterQueryRequestParams {
  return {
    action: 'UNREGISTER',
    data: queryId,
  };
}

import type { CSSProperties, ReactNode } from 'react';

export interface ColumnTable<K> {
  field: K;
  headerName: string;
  valueFormatter?: ({ value }: { value: any }) => string;
  // Add a valueGetter ?
  Component?: ({ value }: { value: any }) => ReactNode;
}

interface TableProps<T> {
  rows: T[];
  columns: ColumnTable<keyof T>[];
  getRowStyle?: (row: T) => CSSProperties | undefined;
}

function getRowValue<T extends Record<string, any>>(
  row: T,
  column: ColumnTable<keyof T>,
): ReactNode {
  const { valueFormatter, field, Component } = column;

  const value = row[field];
  if (value === undefined) {
    return '-';
  }
  if (Component != null) {
    return <Component value={value} />;
  }
  if (valueFormatter === undefined) {
    return value;
  }

  return valueFormatter({ value });
}

export function Table<T extends Record<string, any>>(tableProps: TableProps<T>): JSX.Element {
  const { columns, rows, getRowStyle } = tableProps;
  const headers = columns.map(column => column.headerName!);

  return (
    <div style={{ maxHeight: '200px', overflow: 'auto' }}>
      <table className="table table-sm table-bordered  ">
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header} scope="col">
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((row, index) => {
            const style = getRowStyle !== undefined ? getRowStyle(row) : undefined;
            return (
              <tr style={{ ...style }} key={index}>
                {columns.map((column, index) => (
                  <td key={index}> {getRowValue(row, column)}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

import { EventEmitter } from 'events';
import { isAnyOf, type UnknownAction } from '@reduxjs/toolkit';
import isDeepEqual from 'fast-deep-equal';
import { asSequence } from 'sequency';

import { startAppListening } from '@/store/listenerMiddleware.ts';
import {
  queryParameterSelector,
  queryParamIsValid,
  type QueryParameters,
} from '@/store/selectors/QueryParameterSelector.ts';
import {
  selectWidgetConfigs,
  selectWidgetQueryParams,
  widgetsSlice,
  type WidgetConfig,
} from '@/store/slices/widget/widgetsSlice.ts';
import type { WidgetQueryParams } from '@/store/slices/widget/widgetTypes.ts';

startAppListening({
  predicate: (action, currentState, previousState) => {
    const queryParameters = queryParameterSelector(currentState);

    if (isThunk(action) || !queryParamIsValid(queryParameters)) {
      return false;
    }
    const previousQueryParameters = queryParameterSelector(previousState);

    return !isDeepEqual(queryParameters, previousQueryParameters);
  },
  effect: (action, api) => {
    const queryParameters = queryParameterSelector(api.getState());
    console.warn('queryParameters changed', queryParameters);
    storeEventEmitter.emit('onQueryParametersChanged', queryParameters);
  },
});

startAppListening({
  predicate: (action, currentState, previousState) => {
    if (isThunk(action)) {
      return false;
    }
    return currentState.draftQuery.liveUpdates !== previousState.draftQuery.liveUpdates;
  },
  effect: (_, listenerApi) => {
    const liveUpdates = listenerApi.getState().draftQuery.liveUpdates;
    storeEventEmitter.emit('liveUpdateChanged', liveUpdates);
  },
});

const widgetsSliceMatcher = isAnyOf(widgetsSlice.actions.set, widgetsSlice.actions.setValue);
startAppListening({
  predicate: (action, currentState, previousState) => {
    if (!widgetsSliceMatcher(action)) {
      return false;
    }
    // Order change of widgetConfigs should not refresh query
    const currentWidgetConfigs = currentState.widgets.config.toSorted((a, b) =>
      a.id.localeCompare(b.id),
    );
    const previousWidgetConfigs = previousState.widgets.config.toSorted((a, b) =>
      a.id.localeCompare(b.id),
    );

    return !isDeepEqual(currentWidgetConfigs, previousWidgetConfigs);
  },
  effect: (_, { getOriginalState, getState }) => {
    const updatedWidgetConfigsParams: Record<string, WidgetConfig['params']> = {};

    const currentWidgetConfigsParams = asSequence(selectWidgetConfigs(getState())).associate(
      ({ id, params }) => [id, params],
    );
    const previousWidgetConfigsParams = asSequence(
      selectWidgetConfigs(getOriginalState()),
    ).associate(({ id, params }) => [id, params]);

    for (const key of currentWidgetConfigsParams.keys()) {
      const currentWidgetConfigParams = currentWidgetConfigsParams.get(key);
      const previousWidgetConfigParams = previousWidgetConfigsParams.get(key);

      if (
        currentWidgetConfigParams !== undefined &&
        !isDeepEqual(currentWidgetConfigParams, previousWidgetConfigParams)
      ) {
        updatedWidgetConfigsParams[key] = currentWidgetConfigParams;
      }
    }

    storeEventEmitter.emit('onWidgetConfigParamsUpdate', updatedWidgetConfigsParams);
  },
});

const isWidgetQueryParamsValid = (widgetQueryParams: WidgetQueryParams) =>
  widgetQueryParams.cubeName !== '';
startAppListening({
  predicate: (action, currentState, previousState) => {
    const currentWidgetQueryParams = selectWidgetQueryParams(currentState);
    const previousWidgetQueryParams = selectWidgetQueryParams(previousState);
    if (isThunk(action) || !isWidgetQueryParamsValid(currentWidgetQueryParams)) {
      return false;
    }

    return !isDeepEqual(currentWidgetQueryParams, previousWidgetQueryParams);
  },
  effect: (_, listenerApi) => {
    const widgetQueryParams = selectWidgetQueryParams(listenerApi.getState());

    storeEventEmitter.emit('onWidgetQueryParamsUpdate', widgetQueryParams);
  },
});

export type StoreEvents = {
  onQueryParametersChanged: [QueryParameters];
  onWidgetConfigParamsUpdate: [Record<string, WidgetConfig['params']>];
  onWidgetQueryParamsUpdate: [WidgetQueryParams];
  liveUpdateChanged: [boolean];
  colorChanged: [{}];
};

export const storeEventEmitter = new EventEmitter<StoreEvents>();

function isThunk(action: UnknownAction) {
  return action.type === undefined;
}

import type { ITooltipParams } from '@ag-grid-community/core';

import type { Order } from '@/store/api/hedgerApi/hedgerTypes.ts';

export function InactiveReasonsTooltip(props: ITooltipParams<Order>): JSX.Element | null {
  const inactiveReasons = props.data?.inactiveReasons;
  if (inactiveReasons === undefined || inactiveReasons.length === 0) {
    return null;
  }

  return (
    <div className="tooltip show fade text-nowrap bs-tooltip-right " role="tooltip">
      {inactiveReasons?.map((ir, index) => {
        return (
          <div key={index} className=" tooltip-inner text-start mw-100">
            <span className="fw-bold">{ir.type} :</span> {ir.message}
          </div>
        );
      })}
    </div>
  );
}

import { useCallback, type ChangeEvent } from 'react';
import classNames from 'classnames';
import groupBy from 'just-group-by';

import { objectEntries } from '@/utils/libs/entries.ts';

interface SelectProps<Item> {
  className?: string;
  itemsAsObjects: readonly Item[];
  selectedItem?: Item;
  selectedItemValue?: string;
  itemToString?: (item: Item, index: number, selected: boolean) => string;
  itemValue?: (item: Item) => string;
  onChange?: (change: Item) => void;
  id?: string;
  groupFn?: (item: Item) => string;
  defaultItemValue?: string;
}

export function Select<Item>({
  itemsAsObjects,
  itemToString = i => (i == null ? '' : `${i}`),
  itemValue = i => `${i}`,
  selectedItem,
  selectedItemValue,
  groupFn,
  className,
  onChange,
  id,
  defaultItemValue,
}: SelectProps<Item>): JSX.Element {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      const item = itemsAsObjects.find(item => itemValue(item) === value);
      if (item != null) {
        onChange?.(item);
      }
    },
    [onChange, itemsAsObjects, itemValue],
  );

  const groups: Record<string, readonly Item[]> =
    groupFn !== undefined ? groupBy(itemsAsObjects, groupFn) : { '': itemsAsObjects };

  const classes = classNames(className, 'form-select');
  const selectedValue =
    selectedItemValue ?? (selectedItem === undefined ? undefined : itemValue(selectedItem));
  return (
    <select
      value={selectedValue}
      className={classes}
      id={id}
      onChange={handleChange}
      defaultValue={defaultItemValue}
    >
      {objectEntries(groups).map(([category, items], groupIndex) => {
        const options = items.map((item, index) => {
          const value = itemValue(item);
          const selected = value === selectedValue;
          const display = itemToString(
            item,
            groupIndex * Object.keys(groups).length + index,
            selected,
          );
          return (
            <option value={value} key={value}>
              {display}
            </option>
          );
        });
        if (category === '') {
          return options;
        } else {
          return (
            <optgroup key={category} label={category}>
              {options}
            </optgroup>
          );
        }
      })}
    </select>
  );
}

import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';

import { fetchVisualizationPresets } from '@/store/slices/prefs/fetchVisualizationPresets.ts';
import { keyEquals } from '@/store/slices/prefs/presetKey.ts';
import {
  visualizationPresetSchema,
  type VisualizationPresetKey,
} from '@/store/slices/prefs/visualizationPresetSchema.ts';
import type { AppState, AppThunk } from '@/store/store.ts';
import { DEFAULT_PRESET_NAME } from '@/web/presets/loadProfilePresetDefaults.ts';
import { saveUserSessionService } from '@/web/session/sessionApi.ts';
import { removeAllFromArray, removeOneFromArray } from '@/utils/arrays.ts';

export type VisualizationPreset = z.infer<typeof visualizationPresetSchema>;

export interface VisualizationPresetsState {
  activePresetKey: VisualizationPresetKey;
  presets: VisualizationPreset[];
}

export const DEFAULT_VISU_PRESET_KEY = { team: 'DEFAULT', name: DEFAULT_PRESET_NAME };

const initialState: VisualizationPresetsState = {
  activePresetKey: DEFAULT_VISU_PRESET_KEY,
  presets: [],
};

export const visualizationPresetsSlice = createSlice({
  name: 'visualizationPreset',
  initialState: initialState,
  reducers: {
    setValue: (state, action: PayloadAction<Partial<VisualizationPresetsState>>) => {
      return { ...state, ...action.payload };
    },
    deleteVisualizationPresets: (
      state,
      action: PayloadAction<{ presetKeys: VisualizationPresetKey[] }>,
    ) => {
      const presetKeys = action.payload.presetKeys;
      return {
        presets: removeAllFromArray(state.presets, p =>
          presetKeys.some(key => key.team === p.team && key.name === p.name),
        ),
        activePresetKey: DEFAULT_VISU_PRESET_KEY,
      };
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchVisualizationPresets.fulfilled, (_, action) => {
      const { activePresetKey, presets } = action.payload;

      const foundKey: VisualizationPresetKey =
        activePresetKey !== undefined && presets.some(p => keyEquals(p, activePresetKey))
          ? activePresetKey
          : DEFAULT_VISU_PRESET_KEY;

      return {
        activePresetKey: foundKey,
        presets,
      };
    });
  },
});

export function deleteVisualizationPresetFromSessionServiceThunk(
  team: string,
  presetNames: string[],
): AppThunk {
  return async (dispatch, getState) => {
    const presetKeys: VisualizationPresetKey[] = presetNames.map(name => ({ team, name }));
    dispatch(visualizationPresetsSlice.actions.deleteVisualizationPresets({ presetKeys }));
    const {
      visualizationPresets: { presets },
    } = getState();

    const presetsOfTeam = presets.filter(p => p.team === team);
    await saveUserSessionService(
      team,
      'visualizationPresets',
      presetsOfTeam,
      z.array(visualizationPresetSchema),
    );
  };
}

export function addNewVisualizationPresetToSessionServiceThunk(
  newVisualizationPreset: Omit<VisualizationPreset, 'filtersEnabled' | 'hierarchies' | 'filters'>,
): AppThunk {
  return async (dispatch, getState) => {
    const visualizationPresets = getState().visualizationPresets.presets;
    const hierarchies = getState().draftQuery.draftSelectedHierarchies;
    const { filters, filtersEnabled } = getState().query;

    const { team, name } = newVisualizationPreset;
    const deleteDuplicate = removeOneFromArray(
      visualizationPresets,
      p => p.team === team && p.name === name,
    );

    const presetToSave: VisualizationPreset = {
      ...newVisualizationPreset,
      filtersEnabled,
      hierarchies,
      filters,
    };

    const newArr = [...deleteDuplicate, presetToSave];
    dispatch(visualizationPresetsSlice.actions.setValue({ presets: newArr }));

    const teamPresets = newArr.filter(p => p.team === team);

    await saveUserSessionService(
      team,
      'visualizationPresets',
      teamPresets,
      z.array(visualizationPresetSchema),
    );
  };
}

export function selectActiveVisualizationPreset(state: AppState): VisualizationPresetKey {
  return state.visualizationPresets.activePresetKey;
}

export function selectActivePreset(state: AppState): VisualizationPreset {
  const activePreset = state.visualizationPresets.activePresetKey;
  return selectVisualizationPreset(state, activePreset);
}

export function selectVisualizationPreset(
  state: AppState,
  presetKey: VisualizationPresetKey,
): VisualizationPreset {
  const selected = maybeSelectVisualizationPreset(state, presetKey);
  if (selected === undefined) {
    throw new Error(`Preset not found ${presetKey.name}`);
  }
  return selected;
}

function maybeSelectVisualizationPreset(
  state: AppState,
  preset: VisualizationPresetKey,
): VisualizationPreset | undefined {
  return state.visualizationPresets.presets.find(
    p => p.team === preset.team && p.name === preset.name,
  );
}

export function selectAllVisualizationPresets(state: AppState): VisualizationPreset[] {
  return state.visualizationPresets.presets;
}

export const selectAllPresetsKeys = createSelector([selectAllVisualizationPresets], presets =>
  presets.map(preset => ({
    name: preset.name,
    team: preset.team,
  })),
);

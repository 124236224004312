export function absValueComparator(valueA: number, valueB: number) {
  return absNaN(valueA) - absNaN(valueB);
}

function absNaN(value: number) {
  if (isNaN(value)) {
    return 0;
  }
  return Math.abs(value);
}

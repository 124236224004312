import { useCallback, type ComponentType, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useSelect, type UseSelectStateChange } from 'downshift';

import { Button, type ButtonProps } from '@/components/common/bootstrap/Button.tsx';

type DropButtonProps = Pick<
  ButtonProps,
  'disabled' | 'size' | 'flat' | 'variant' | 'id' | 'hidden'
>;

type ItemToString<Item> = (item: Item, index: number, selected: boolean) => string;
type LabelToString<Item> = (item: Item | undefined) => string;

interface DropdownProps<Item> extends DropButtonProps {
  className?: string;
  itemsAsObjects: Item[];
  selectedItem?: Item;
  itemToString?: ItemToString<Item>;
  labelToString?: LabelToString<Item>;
  onChange?: (change: Item) => void;
  onItemClick?: (clicked: Item) => void;
  align?: 'left' | 'right';
  direction?: 'up' | 'down' | 'left' | 'right';
  ItemRenderer?: ComponentType<{ item: Item }>;
}

export function Dropdown<Item>({
  children,
  className,
  itemsAsObjects,
  selectedItem,
  itemToString,
  labelToString,
  onChange,
  id,
  align,
  direction = 'down',
  onItemClick,
  ItemRenderer,
  ...buttonProps
}: PropsWithChildren<DropdownProps<Item>>): JSX.Element {
  const handleChange = useCallback(
    (change: UseSelectStateChange<Item>) => {
      if (change.selectedItem != null) {
        onChange?.(change.selectedItem);
      }
    },
    [onChange],
  );

  const safeItemToString = useCallback(
    (item: Item, index: number, selected: boolean) => {
      return itemToString?.(item, index, selected) ?? `${item}`;
    },
    [itemToString],
  );

  const safeLabelToString = useCallback(
    (item: Item | undefined) => {
      if (labelToString === undefined && item != null) {
        return safeItemToString(item, -1, false);
      }
      return labelToString?.(item) ?? `${item}`;
    },
    [labelToString, safeItemToString],
  );

  const onStateChange = (e: UseSelectStateChange<Item>) => {
    if (e.type === useSelect.stateChangeTypes.ItemClick) {
      const item = itemsAsObjects[highlightedIndex];
      if (item != null) {
        onItemClick?.(item);
      }
    } else if (e.type === useSelect.stateChangeTypes.ToggleButtonKeyDownEnter) {
      if (e.selectedItem != null) {
        onItemClick?.(e.selectedItem);
      }
    }
  };

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    id,
    items: itemsAsObjects,
    selectedItem,
    onSelectedItemChange: handleChange,
    onStateChange,
    // isOpen: true,
  });
  const buttonClasses = className ?? 'dropdown-toggle';
  const dropdownClasses = classNames(
    'dropdown-menu',
    { 'dropdown-menu-right': align === 'right' },
    { 'dropdown-menu-left': align === 'left' },
    { show: isOpen },
  );
  return (
    <div className={`drop${direction}`}>
      <Button
        {...buttonProps}
        className={buttonClasses}
        {...getToggleButtonProps()}
        data-bs-toggle="dropdown"
      >
        {children ?? safeLabelToString(selectedItem)}
      </Button>
      <div className={dropdownClasses} {...getMenuProps()}>
        {itemsAsObjects.map((item, index) => {
          return (
            <div
              className={`dropdown-item cursor-default ${
                index === highlightedIndex ? 'selected' : ''
              }`}
              key={index}
              {...getItemProps({
                item,
                index,
              })}
            >
              {ItemRenderer !== undefined ? (
                <ItemRenderer item={item} />
              ) : (
                safeItemToString(item, index, selectedItem === item)
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

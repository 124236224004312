import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';

import { getConfig } from '@/utils/config/config.ts';

export function MicroFooter(): JSX.Element {
  const { environment } = getConfig();

  return (
    <SgwtMiniFooter
      type="micro"
      cookiesConsent={environment !== 'devci'}
      design="light"
      mode="sg-markets"
      contactUsByHelpCenter="sgwt-help-center"
      accessibilityCompliance="none"
      accessibilityLink="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://tree-rt.sgmarkets.com"
    />
  );
}

import { createFileRoute, getRouteApi } from '@tanstack/react-router';

import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { HedgerOrderPage } from '@/components/hedger/order/HedgerOrderPage.tsx';
import { validateOnePermission } from '@/components/router/core/validateOnePermission.tsx';
import { safeParseNumber } from '@/utils/libs/safeParseNumber.ts';

const hedgerOrderRouteApi = getRouteApi('/hedger/order/$orderGroupId');

export const Route = createFileRoute('/hedger/order/$orderGroupId')({
  beforeLoad: validateOnePermission('viewHedger', 'devci'),
  component: () => {
    const { orderGroupId } = hedgerOrderRouteApi.useParams();
    const orderGroupIdNum = safeParseNumber(orderGroupId);
    if (orderGroupIdNum === undefined) {
      // TODO: no button to edit order
      return <ErrorPanel>Order Id not found</ErrorPanel>;
    }
    return <HedgerOrderPage orderGroupId={orderGroupIdNum} />;
  },
});

import { useRef, useState } from 'react';
import { useRouter } from '@tanstack/react-router';

import { formatQuery } from '@/core/query/format/formatQuery.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { CopyButton } from '@/components/common/components/CopyButton.tsx';
import { useClickAway } from '@/components/common/hooks/useClickAway.ts';
import { useEventEmitter } from '@/components/common/hooks/useEventEmitter.ts';
import { useKey } from '@/components/common/hooks/useKey.ts';
import { queryEventEmitter } from '@/components/common/layout/queryEventEmitter.ts';

type OnQueryParams = { mdx: string; queryGroup: string; queryId: string };
export type QueryEvents = {
  onQuery: [OnQueryParams];
};

export function QueryPanelButton() {
  const [showPanel, setShowPanel] = useState(false);
  const [queries, setQueries] = useState<OnQueryParams[]>([]);
  useEventEmitter(queryEventEmitter, 'onQuery', query => {
    setTimeout(() => {
      setQueries(queries => queries.concat({ ...query, mdx: formatQuery(query.mdx) }));
    });
  });

  return (
    <div>
      <Button
        accessKey="p"
        onClick={() => setShowPanel(true)}
        flat
        variant="primary"
        size="lg"
        className="btn-icon-start"
      >
        <em className="icon icon-md">search</em>Queries
      </Button>

      {showPanel ? (
        <QueryPanel
          onClearClick={() => setQueries([])}
          queries={queries}
          onClose={() => setShowPanel(false)}
        />
      ) : null}
    </div>
  );
}

interface DevPanelProps {
  onClose: () => void;
  queries: OnQueryParams[];
  onClearClick: () => void;
}

function QueryPanel({ onClose, onClearClick, queries }: DevPanelProps): JSX.Element {
  const ref = useRef(null);
  useKey('Escape', onClose);
  useClickAway(ref, onClose);

  return (
    <div
      ref={ref}
      className="card shadow-max"
      style={{
        position: 'fixed',
        zIndex: 20,
        height: '100vh',
        left: 0,
        top: 0,
        minWidth: 400,
        maxWidth: '50vw',
        overflowY: 'scroll',
      }}
    >
      <div className="p-3">
        <div className="d-flex flex-between">
          <h3>Queries</h3>
          <Button variant="danger" className="btn-icon-start" onClick={onClearClick}>
            <em className="icon">recycling</em>
            Clear queries
          </Button>
        </div>
        <div className="list-group list-group-flush">
          {queries.map((query, index) => (
            <QueryCard key={index} query={query} />
          ))}
        </div>
      </div>
    </div>
  );
}

interface QueryCardProps {
  query: OnQueryParams;
}

function QueryCard({ query }: QueryCardProps): JSX.Element {
  const router = useRouter();

  return (
    <div className="list-group-item border-light flex-column">
      <div className="d-flex justify-content-between mb-1">
        <h5>
          {query.queryGroup} {query.queryId.split('_')[0]}
        </h5>
        <div className="d-flex gap-1">
          <CopyButton text={query.mdx} />
          <Button
            className="btn-icon-start"
            onClick={() => router.navigate({ to: '/queryTools', search: { query: query.mdx } })}
          >
            <em className="icon">search</em>
            See in query tools
          </Button>
        </div>
      </div>
      {/*<div className="card-body truncate" style={{ maxHeight: 200 }}>*/}
      <pre className="bg-lvl2" style={{ maxHeight: 200 }}>
        {query.mdx}
      </pre>
      {/*</div>*/}
    </div>
  );
}

import { getMeasureById, type Measure, type MeasureId } from '@/core/measures.ts';
import type { FilterMeasure, QueryFilters } from '@/core/query/mdx/filter/filterModel.ts';
import type { CubeMode } from '@/types/AppConfig.ts';
import { strictEntries } from '@/utils/libs/entries.ts';

export const SG_CUBE_NAME = 'SGME_delta_v44';
export const GREAT_CUBE_NAME = 'GREATCube';

export function stripWhitespaces(s: string): string {
  return s.replace(/\s+/gu, ' ');
}

export function filterAvailableMeasures(measureIds: MeasureId[], cubeMode: CubeMode): Measure[] {
  return measureIds.map(getMeasureById).filter(measure => isMeasureAvailable(measure, cubeMode));
}

export function removeFiltersOnNonExistingMeasures(
  filters: QueryFilters,
  cubeMode: CubeMode,
): QueryFilters {
  return Object.fromEntries(
    strictEntries(filters).map(([hierarchy, measureFilters]) => {
      const cleanMeasureFilters = Object.fromEntries(
        strictEntries(measureFilters).filter(([measureId]) =>
          isValidMeasureId(measureId, cubeMode),
        ),
      );
      return [hierarchy, cleanMeasureFilters];
    }),
  );
}

function isValidMeasureId(measureId: FilterMeasure, cubeMode: CubeMode): boolean {
  if (measureId === 'Hierarchy') {
    return true;
  }
  const measure = getMeasureById(measureId);
  return measure !== undefined && isMeasureAvailable(measure, cubeMode);
}

function isMeasureAvailable(measure: Measure, cubeMode: CubeMode): boolean {
  return cubeMode === 'sgCube' || measure.availableOnGreat;
}

export const backgroundColors = [
  'bg-valencia',
  'bg-apricot',
  'bg-puerto-rico',
  'bg-cornflower-blue',
  'bg-spicy-mix',
  'bg-faded-jade',
  'bg-royal-blue',
  'bg-heather',
  'bg-victoria',
  'bg-azure',
  'bg-mako',
  'bg-mars',
  'bg-mercury',
  'bg-manatee',
  'bg-dodger-blue',
  'bg-turquoise-blue',
  'bg-pacific-blue',
  'bg-buttercup',
  'bg-copper-rust',
  'bg-east-bay',
  'bg-plantation',
] as const;
export type SgBackgroundColor = (typeof backgroundColors)[number];

export const textColors = [
  'text-danger',
  'text-warning',
  'text-success',
  'text-info',
  'text-primary',
  'text-light',
  'text-dark',
] as const;
export type SgTextColor = (typeof textColors)[number];

interface BackgroundColorOfRule {
  type: 'bgColor';
  cssClass: SgBackgroundColor;
}
interface TextColorOfRule {
  type: 'textColor';
  cssClass: SgTextColor;
}

export type ColorOfRule = BackgroundColorOfRule | TextColorOfRule;

export const textColorsOfRule: ColorOfRule[] = textColors.map(cssClass => ({
  type: 'textColor',
  cssClass,
}));
export const backgroundColorsOfRule: ColorOfRule[] = backgroundColors.map(cssClass => ({
  type: 'bgColor',
  cssClass,
}));
export const allColorsOfRules: ColorOfRule[] = [...backgroundColorsOfRule, ...textColorsOfRule];

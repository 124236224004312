import { useId } from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetScenariosQuery } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';

interface ScenarioSelectorProps {
  selectedScenarioId: number | undefined;
  onChange: (scenarioId: number | undefined) => void;
  onManageScenariosClick: () => void;
}

export function ScenarioSelector({
  onChange,
  selectedScenarioId,
  onManageScenariosClick,
}: ScenarioSelectorProps): JSX.Element {
  const id = useId();
  const scenariosQueryResult = useGetScenariosQuery();
  if (scenariosQueryResult.isLoading || scenariosQueryResult.isUninitialized) {
    return <Loader />;
  }

  if (scenariosQueryResult.isError) {
    return <FetchErrorView error={scenariosQueryResult.error} />;
  }

  const emptyScenario = {
    scenarioId: undefined,
  };
  const scenarios = scenariosQueryResult.data.scenarios;

  return (
    <div className="d-flex gap-3">
      <div className="form-group-deprecated">
        <label htmlFor={id} className="form-label">
          <FormattedMessage id="Hedging.Scenario" />
        </label>
        <Select
          id={id}
          className="form-control"
          itemsAsObjects={[emptyScenario, ...scenarios]}
          itemToString={scenario =>
            scenario.scenarioId ? `${scenario.name} [${scenario.userId}]` : ''
          }
          selectedItemValue={`${selectedScenarioId}`}
          itemValue={scenario => `${scenario.scenarioId}`}
          onChange={scenario => onChange(scenario.scenarioId)}
        />
      </div>
      <div className="form-group-deprecated d-flex align-items-end">
        <Button onClick={onManageScenariosClick}>
          <FormattedMessage id="Hedger.Scenarios.Title" />
        </Button>
      </div>
    </div>
  );
}

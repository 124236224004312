import { forwardRef, type ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  flat?: boolean;
  icon?: boolean;
  outline?: boolean;
  discreet?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
    | 'black'
    | 'white';
  dataE2e?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      size = 'md',
      icon = false,
      flat = false,
      outline = false,
      discreet = false,
      variant = flat || outline || discreet ? 'primary' : 'default',
      disabled,
      children,
      dataE2e,
      ...otherProps
    },
    ref,
  ) => {
    const flatClass = flat ? 'flat-' : '';
    const outlineClass = outline ? 'outline-' : '';
    const discreetClass = discreet ? 'discreet-' : '';
    const classes = classNames(
      className,
      'btn',
      `btn-${size}`,
      `btn-${flatClass}${outlineClass}${discreetClass}${variant}`,
      {
        'btn-disabled': disabled,
        'btn-icon': icon,
      },
    );
    return (
      <button ref={ref} className={classes} disabled={disabled} data-e2e={dataE2e} {...otherProps}>
        {children}
      </button>
    );
  },
);

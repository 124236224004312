// listenerMiddleware.ts
import type { AppDispatch, AppState } from './store';
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<AppState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<AppState, AppDispatch>;

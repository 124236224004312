export function parseJson(text: string): object {
  try {
    return JSON.parse(text);
  } catch (e) {
    return {};
  }
}

export function stringifyJson(obj: object): string {
  return JSON.stringify(obj, null, 2);
}

import { useEffect, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useNavigate } from '@tanstack/react-router';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { useIntl } from 'react-intl';

import {
  FIXED_CACHE_ORDERGROUP,
  usePostOrderGroupsMutation,
  type OrderGroup,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { useHedgerSettingModal } from '@/components/hedger/execution/settings/useHedgerSettingModal.ts';
import { HedgerExportModal } from '@/components/hedger/order/HedgerExportModal.tsx';
import { parseServerDate } from '@/utils/date/date.ts';

export function HedgerOrderToolsPanel({ orderGroup }: { orderGroup: OrderGroup }) {
  const userRequest = orderGroup.userRequest;

  const [refresh, { isLoading: isPostOrderGroupsLoading }] = usePostOrderGroupsMutation({
    fixedCacheKey: FIXED_CACHE_ORDERGROUP,
  });
  const navigate = useNavigate();

  const isLoading = isPostOrderGroupsLoading;

  const hedgerSettingModal = useHedgerSettingModal();
  const exportModal = useModal(HedgerExportModal);

  async function refreshOrders() {
    const orderGroup = await refresh({
      scenarioId: userRequest.scenarioId,
      source: userRequest.source,
    }).unwrap();
    if (orderGroup !== undefined) {
      await navigate({
        to: '/hedger/order/$orderGroupId',
        params: { orderGroupId: orderGroup.orderGroupId.toString() },
      });
    }
  }

  async function onEditSettings() {
    await hedgerSettingModal.show(userRequest);
  }

  return (
    <div className="d-flex flex-wrap gap-2 align-items-center ">
      <div className="d-flex flex-column me-2 justify-content-center ">
        <TimeAgo time={orderGroup.generationTime} />
        {userRequest.source.sourceType === 'GREAT' ? (
          <div className="text-secondary">
            <span className="fw-medium">Perimeter: </span>
            <span className="text-info">{userRequest.source.greatDetails.perimeterPresetName}</span>
          </div>
        ) : null}
      </div>

      <Button size="lg" className="btn-icon-start" disabled={isLoading} onClick={refreshOrders}>
        {isLoading ? (
          <em className="icon spinner spinner-sm" style={{ height: 16 }} role="status"></em>
        ) : (
          <em className="icon">refresh</em>
        )}
        Refresh
      </Button>
      <Button size="lg" className="btn-icon-start" onClick={onEditSettings}>
        <em className="icon">edit</em>
        Edit settings
      </Button>
      <Button
        size="lg"
        variant="primary"
        accessKey="e"
        onClick={() => exportModal.show({ orderGroup })}
      >
        Export
      </Button>
    </div>
  );
}

function TimeAgo(props: { time: string }) {
  const date = parseServerDate(props.time);
  const [now, setNow] = useState(new Date().getTime());
  const timeAgo = useTimeAgo(date, now);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date().getTime());
    }, 1000 * 60);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-secondary">
      <span className="fw-medium">Last refresh:</span> <span className="text-info">{timeAgo}</span>
    </div>
  );
}

function useTimeAgo(date: Date | null, now: number) {
  const { formatRelativeTime, formatMessage } = useIntl();
  if (date === null) {
    return null;
  }
  const hoursAgo = differenceInHours(date.getTime(), now);
  if (Math.abs(hoursAgo) < 1) {
    const minutesAgo = differenceInMinutes(date.getTime(), now);
    return formatRelativeTime(minutesAgo, 'minute', { style: 'short' });
  } else if (Math.abs(hoursAgo) > 23) {
    return formatMessage({ id: 'Pricing.Old' });
  }
  return formatRelativeTime(hoursAgo, 'hour');
}

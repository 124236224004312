import { customAlphabet } from 'nanoid';

import { parseResponse, type ParsedResponse } from '@/core/parsing/parseResponse.ts';
import type { RegisterQueryRequestParams } from '@/core/webSocket/types.ts';
import type { MdxStateData } from '@/types/mdx.ts';

const nanoid = customAlphabet('1234567890abcdefghijklmopqrstuvwxyz', 21);

export interface QueryResult {
  rawResponse: MdxStateData;
  parsedResponse: ParsedResponse | undefined;
}

export function parseToQueryResult(data: MdxStateData): QueryResult {
  try {
    const parsedResponse = parseResponse(data);
    return {
      parsedResponse,
      rawResponse: data,
    };
  } catch (e) {
    return {
      parsedResponse: undefined,
      rawResponse: data,
    };
  }
}

export interface Range {
  from: number;
  to: number;
}

export interface QueryPayload {
  data: {
    context?: Record<string, string>;
  };
  ranges?: Record<string, Range>;
}

export function getQueryPayload(
  queryPayload: string,
  mdxQuery: string,
): RegisterQueryRequestParams {
  const payloadTemplate: QueryPayload = JSON.parse(queryPayload);
  const queryId = nanoid();
  return {
    action: 'REGISTER',
    data: {
      mdxQuery: {
        // eslint-disable-next-line no-control-regex
        mdx: mdxQuery.replace(new RegExp('\n', 'g'), ' '),
        context: payloadTemplate.data.context,
      },
      streamId: queryId,
      queryId: queryId,
      initialState: 'PAUSED',
      ranges: payloadTemplate.ranges,
    },
  };
}

import type { CellClassRules } from '@ag-grid-community/core';

import type { MeasureId } from '@/core/measures.ts';
import type { RowData } from '@/core/parsing/parseResponse.ts';
import type { WidgetColumn } from '@/store/slices/widget/widgetTypes.ts';
import {
  getValueForMeasure,
  type FetchContext,
} from '@/components/equityRisk/gridMappings/ColumnMapping.ts';

export function getCellClassRulesForWidgets(
  widgetColumn: WidgetColumn,
): CellClassRules<RowData> | undefined {
  if (widgetColumn.type === 'hierarchy') {
    return undefined;
  }

  switch (widgetColumn.measureId) {
    case 'UnderlyerSpotRTMove':
      return {
        'text-success': ({ value }) => value >= 0,
        'text-danger': ({ value }) => value < 0,
      };
    default:
      return {
        // 'text-danger': ({ value }) => value < 0,
      };
  }
}

export function getClassRulesOfHierarchy(context: FetchContext): CellClassRules | undefined {
  const targetConstraint = (value: number | undefined) => Math.abs(value ?? 0) >= 0.03;
  return {
    'hierarchy-constrained': params => {
      const spotVar = getValueAsNumber(params.data, 'UnderlyerSpotRTMove', context);
      return params.node.level === 1 && targetConstraint(spotVar);
    },
  };
}

function getValueAsNumber(data: RowData, measureId: MeasureId, context: FetchContext) {
  const spotVarValue = getValueForMeasure(data, measureId, context);
  return getNumber(spotVarValue);
}

function getNumber(value: unknown): number | undefined {
  if (typeof value === 'number') {
    return value;
  }
  return undefined;
}

import { useCallback, useId, type ChangeEvent } from 'react';

interface SwitcherProps {
  label?: string;
  id?: string;
  onChange?: (value: boolean) => void;
  checked?: boolean;
  colored?: boolean;
}

export function Switcher({ onChange, colored, checked, id, label }: SwitcherProps): JSX.Element {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.currentTarget.checked);
    },
    [onChange],
  );
  const labelId = useId() + (id ?? '');

  return (
    <div className={`form-check form-switch ${colored ? 'sgbs-switch-colored' : ''}`}>
      <input
        onChange={handleChange}
        checked={checked}
        type="checkbox"
        className="form-check-input"
        id={labelId}
      />
      <label className="form-check-label" htmlFor={labelId}>
        {label}
      </label>
    </div>
  );
}

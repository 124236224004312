import type { AnalysisRow } from './toAnalysisRow.ts';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import type { StormDetails } from '@/store/api/hedgerApi/hedgerApi.ts';
import { Checkbox } from '@/components/common/bootstrap/Checkbox.tsx';
import { formatServerDate } from '@/utils/date/date.ts';

interface AnalysisProps {
  riskAnalyses: AnalysisRow[];
  onSelectStormDetails: (analysisIds: StormDetails[]) => void;
  selectedStormDetails: StormDetails[];
}

export function StormAnalysisTable(analysisProps: AnalysisProps): JSX.Element {
  const { onSelectStormDetails, riskAnalyses, selectedStormDetails } = analysisProps;
  const columnLabels = [
    undefined,
    'AnalysisTable.Url',
    'AnalysisTable.Tags',
    'AnalysisTable.RootPortfolio',
    'AnalysisTable.Id',
    'AnalysisTable.StartTime',
  ] as const;

  function handleRowClick(analysisId: StormDetails) {
    const newAnalysisIds = selectedStormDetails.some(selectedId =>
      detailsEqual(selectedId, analysisId),
    )
      ? selectedStormDetails.filter(id => !detailsEqual(id, analysisId))
      : selectedStormDetails.concat(analysisId);
    onSelectStormDetails(newAnalysisIds);
  }

  return (
    <table className="table table-striped table-responsive-lg border">
      <thead>
        <tr>
          {columnLabels.map(key => (
            <th key={`${key}`}>{key !== undefined && <FormattedMessage id={`${key}`} />}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {riskAnalyses.map(analysisRow => {
          const id = idOf(analysisRow);
          const selected = selectedStormDetails.some(details => detailsEqual(details, id));
          return (
            <AnalysisRowElement
              key={analysisRow.riskAnalysis.id}
              analysisRow={analysisRow}
              selected={selected}
              onRowClick={handleRowClick}
            />
          );
        })}
      </tbody>
    </table>
  );
}

function detailsEqual(analysis1: StormDetails, analysis2: StormDetails) {
  return analysis1.analysisId === analysis2.analysisId && analysis1.uri === analysis2.uri;
}

function idOf(row: AnalysisRow): StormDetails {
  return {
    analysisId: row.riskAnalysis.id,
    uri: row.stormInfo.uri,
    stormName: row.stormInfo.name,
    rootPortfolio: row.riskAnalysis.rootPortfolio,
  };
}

interface AnalysisRowProps {
  selected: boolean;
  onRowClick: (analysisId: StormDetails) => void;
  analysisRow: AnalysisRow;
}

function AnalysisRowElement({ selected, onRowClick, analysisRow }: AnalysisRowProps): JSX.Element {
  const { stormInfo, riskAnalysis } = analysisRow;
  const onAnalysisRowClick = () => onRowClick(idOf(analysisRow));
  return (
    <tr
      onClick={onAnalysisRowClick}
      className={classNames('cursor-pointer', {
        'bg-lvl4': selected,
      })}
    >
      <td
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <Checkbox checked={selected} onChange={onAnalysisRowClick} />
      </td>
      <td>{stormInfo.uri}</td>
      <td>{riskAnalysis.analysisTags.join(',')}</td>
      <td>{riskAnalysis.rootPortfolio}</td>
      <td>{riskAnalysis.id}</td>
      <td>{formatServerDate(riskAnalysis.startTime)}</td>
    </tr>
  );
}

import type { DialectOptions } from 'sql-formatter';

import {
  argumentKeywords,
  booleanKeywords,
  booleanOperatorKeywords,
  cellPropertyKeywords,
  functionKeywords,
  kpiKeywords,
  mdxAxisKeywords,
  mdxStructuralKeywords,
  memberPropertyKeywords,
} from '@/components/queryTools/mdx/mdxKeywords.ts';

const reservedClauses = ['WITH', 'FROM', 'WHERE'];

const onelineClauses: string[] = [];

export const mdx: DialectOptions = {
  name: 'mdx',
  tokenizerOptions: {
    reservedSelect: ['SELECT'],
    reservedClauses: [...reservedClauses, ...onelineClauses],
    reservedSetOperations: [],
    reservedJoins: [],
    reservedPhrases: [],
    reservedKeywords: [
      ...mdxAxisKeywords,
      ...cellPropertyKeywords,
      ...booleanOperatorKeywords,
      ...booleanKeywords,
      ...mdxStructuralKeywords,
      ...memberPropertyKeywords,
      ...argumentKeywords,
      ...kpiKeywords,
      ...functionKeywords.map((kw: string) => kw.toUpperCase()),
    ],
    reservedFunctionNames: functionKeywords.map((kw: string) => kw.toUpperCase()),
    // NOTE: single quotes are actually not supported in N1QL,
    // but we support them anyway as all other SQL dialects do,
    // which simplifies writing tests that are shared between all dialects.
    stringTypes: ['""-bs', "''-bs"],
    identTypes: ['``'],
    extraParens: ['{}'],
    paramTypes: { positional: true, numbered: ['$'], named: ['$'] },
    lineCommentTypes: ['#', '--'],
    operators: ['%', '==', ':', '||'],
    // variableTypes: [{ regex: String.raw`\[[\w+\.]+\]` }],
    variableTypes: [{ regex: String.raw`\[.+?\]` }],
  },
  formatOptions: {
    onelineClauses,
  },
};

import { BorderItem } from '@/components/common/bootstrap/BorderItem.tsx';

interface FrameDataItemProps {
  title: string;
  data: string;
  rightBorder?: boolean;
}
export type DataItem = { title: string; data: string };
export function ViewFrameDataItems({ items }: { items: DataItem[] }): JSX.Element {
  return (
    <div className="d-flex flex-start gap-5">
      {items.map((item, index) => (
        <FrameDataItem
          key={item.title}
          title={item.title}
          data={item.data}
          rightBorder={index !== items.length - 1}
        />
      ))}
    </div>
  );
}
function FrameDataItem({ title, data, rightBorder }: FrameDataItemProps): JSX.Element {
  return (
    <>
      <div className="d-flex flex-column flex-start gap-1">
        <div className="fw-medium text-secondary"> {title} </div>
        <div> {data}</div>
      </div>
      {rightBorder ? <BorderItem /> : null}
    </>
  );
}

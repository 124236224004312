import NiceModal from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';

interface DeleteModalProps {
  onConfirmClick: () => Promise<void>;
  type: 'Recipient' | 'Scenario';
}
export const DeleteModal = NiceModal.create<DeleteModalProps>(function DeleteModal({
  onConfirmClick,
  type,
}) {
  return (
    <BootstrapModal
      footer={
        <CancelConfirmFooter
          onConfirm={onConfirmClick}
          confirmButtonProps={{
            variant: 'danger',
            component: <FormattedMessage id={`Hedger.${type}.DeleteModal.Confirm`} />,
          }}
        />
      }
      titleId={`Hedger.${type}.DeleteModal.Title`}
    >
      <span className="fw-normal">
        All the data will be removed and will not be possible to retrieve it.
      </span>
    </BootstrapModal>
  );
});

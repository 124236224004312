import ky from 'ky';

import { getConfig } from '@/utils/config/config.ts';
import { getSearchParameters } from '@/utils/libs/searchParams.ts';
import { getAuthorizationHeader } from '@/utils/sgwt/sgwtConnect.ts';

export function setRequestHeaders(headers: Headers) {
  const authorizationHeader = getAuthorizationHeader();
  if (authorizationHeader) {
    headers.set('Authorization', authorizationHeader);
  }

  const searchParameters = getSearchParameters();
  if (searchParameters.has('user')) {
    headers.set('X-Fake-Authentication', searchParameters.get('user')!);
  }
  if (searchParameters.has('permissions')) {
    headers.set('X-Fake-Iam-Permissions', searchParameters.get('permissions')!);
  }
  if (searchParameters.has('teams')) {
    headers.set('X-Fake-ERA-Teams', searchParameters.get('teams')!);
  }

  return headers;
}

export const webApi = ky.create({
  prefixUrl: getConfig().apiUrl,
  hooks: {
    beforeRequest: [
      request => {
        setRequestHeaders(request.headers);
      },
    ],
  },
});

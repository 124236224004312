import { FormattedMessage } from 'react-intl';

import type { IntlMessages } from '@/types/intl';

export function DefaultErrorRenderer({ error }: { error: Error }): JSX.Element {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">
        <FormattedMessage id="App.Error" />
      </h4>
      <div className="mt-2">
        <FormattedMessage
          id={(error.name ?? error.cause ?? 'Error.Unknown') as IntlMessages}
          defaultMessage={' '}
        />
        {import.meta.env.DEV && error.message !== undefined && <div>{`${error.message}`}</div>}
        {import.meta.env.DEV && error.cause !== undefined && (
          <div>{`${JSON.stringify(error.cause)}`}</div>
        )}
      </div>
    </div>
  );
}

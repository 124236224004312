import getSymbolFromCurrency from 'currency-symbol-map';
import { FormattedMessage } from 'react-intl';

import type { OrdersMetrics } from '@/store/api/hedgerApi/hedgerTypes.ts';
import type { IntlMessages, SupportedLocale } from '@/types/intl';

interface KpiPanelProps {
  ordersMetrics: OrdersMetrics;
  numberFormat: SupportedLocale;
}

const formatters: Record<SupportedLocale, Intl.NumberFormat> = {
  en: new Intl.NumberFormat('en'),
  fr: new Intl.NumberFormat('fr'),
};

function formatKpiValue(value: number, numberFormat: SupportedLocale): string {
  return formatters[numberFormat].format(parseInt(value.toString()));
}

interface KpiTotalItemProps {
  nb: number;
  nominalGross: number;
  nominalNet: number;
  currencySymbol: string | undefined;
  title: IntlMessages;
  numberFormat: SupportedLocale;
}

function KpiItemTotal({
  nominalNet,
  nominalGross,
  nb,
  currencySymbol,
  title,
  numberFormat,
}: KpiTotalItemProps): JSX.Element {
  return (
    <>
      <div className="d-flex flex-column text-end fw-medium">
        <span className="text-secondary fw-medium">
          <FormattedMessage id={title} />
        </span>
        <span className="display-4 text-figures">{nb}</span>
      </div>
      <div className="d-table text-end fw-medium">
        <div className="d-table-row">
          <div className="d-table-cell px-2">
            <FormattedMessage id="Hedger.KpiPanel.Net" />
          </div>
          <div className="d-table-cell text-secondary text-large ">
            {currencySymbol}
            {formatKpiValue(nominalNet, numberFormat)}
          </div>
        </div>
        <div className="d-table-row ">
          <div className="d-table-cell px-2 ">
            <FormattedMessage id="Hedger.KpiPanel.Gross" />
          </div>
          <div className="d-table-cell text-secondary text-large">
            {currencySymbol}
            {formatKpiValue(nominalGross, numberFormat)}
          </div>
        </div>
      </div>
    </>
  );
}

interface KpiItemProps {
  nb: number;
  nominal: number;
  currencySymbol: string | undefined;
  title: IntlMessages;
  numberFormat: 'en' | 'fr';
}

function KpiItem({ currencySymbol, nb, nominal, numberFormat, title }: KpiItemProps) {
  return (
    <div className="d-flex flex-column justify-content-center border-start px-2 fw-medium">
      <span className="text-secondary fw-medium">
        <FormattedMessage id={title} />
      </span>
      <div className="d-flex align-items-center">
        <span className="me-2 display-4 text-figures">{formatKpiValue(nb, numberFormat)}</span>
        <span className="text-secondary text-large">
          {currencySymbol}
          {formatKpiValue(nominal, numberFormat)}
        </span>
      </div>
    </div>
  );
}

export function KpiPanel({ ordersMetrics, numberFormat }: KpiPanelProps) {
  const currencySymbol = getSymbolFromCurrency(ordersMetrics.currency);
  const nbTotal = ordersMetrics.nbBuys + ordersMetrics.nbSells;

  return (
    <div className="d-flex flex-wrap align-items-center gap-2">
      <KpiItemTotal
        title="Hedger.KpiPanel.Total"
        nb={nbTotal}
        nominalNet={ordersMetrics.nominalNet}
        nominalGross={ordersMetrics.nominalGross}
        currencySymbol={currencySymbol}
        numberFormat={numberFormat}
      />
      <KpiItem
        title={'Hedger.KpiPanel.Buy'}
        nb={ordersMetrics.nbBuys}
        nominal={ordersMetrics.nominalBuys}
        currencySymbol={currencySymbol}
        numberFormat={numberFormat}
      />
      <KpiItem
        numberFormat={numberFormat}
        title={'Hedger.KpiPanel.Sell'}
        nb={ordersMetrics.nbSells}
        nominal={ordersMetrics.nominalSells}
        currencySymbol={currencySymbol}
      />
    </div>
  );
}

import { useCallback, useMemo, useRef, useState } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type {
  CellClassRules,
  ColDef,
  GridApi,
  IRichCellEditorParams,
  RowNode,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import NiceModal from '@ebay/nice-modal-react';
import { isDefined } from '@sgme/fp';
import { asSequence } from 'sequency';

import {
  rulesEntityTypes,
  rulesModels,
  underlyingTypes,
  type RuleCategory,
  type RulesModel,
} from '@/store/api/rulesApi/rulesModels.ts';

import '@/store/api/rulesApi/rulesApi.ts';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { processDataFromClipboard } from '@/components/rules/processDataFromClipboard.ts';
import type { RuleRowData } from '@/components/rules/rowDataManager/BaseRowDataManager.ts';
import { ModalRowDataManager } from '@/components/rules/rowDataManager/ModalRowDataManager.ts';

interface AddClauseBatchModalProps {
  defaultColDef: ColDef;
  category: RuleCategory;
  metricsValues: string[];
  rules: RulesModel[];
  importDataFromRulesGrid: (rowDataToAdd: RuleRowData[]) => void;
  externalRowData: RuleRowData[];
}

export const AddClauseBatchModal = NiceModal.create<AddClauseBatchModalProps>(
  ({ defaultColDef, category, metricsValues, rules, importDataFromRulesGrid, externalRowData }) => {
    const [isInvalidGrid, setIsInvalidGrid] = useState(true);

    const modalRowDataManager = useMemo<ModalRowDataManager>(() => {
      return new ModalRowDataManager(category, rules);
    }, [category, rules]);

    const gridApiRef = useRef<GridApi<RuleRowData> | undefined>();

    const handleGridChange = useCallback(() => {
      const invalidRowDataManagerGrid = modalRowDataManager.isInvalidGrid(
        gridApiRef.current?.getColumnDefs(),
      );
      modalRowDataManager.computeDuplicatedWithExternal(externalRowData);

      modalRowDataManager.computeDuplicated();
      gridApiRef.current?.refreshCells({ columns: ['duplicated'] });
      setIsInvalidGrid(invalidRowDataManagerGrid);
    }, [externalRowData, modalRowDataManager]);

    const handleDelete = useCallback(
      (rowId: number) => {
        modalRowDataManager.deleteRowById(rowId);
        const rowData = modalRowDataManager.getRowData();
        gridApiRef.current?.setGridOption('rowData', rowData);
        gridApiRef.current?.refreshCells({ columns: ['duplicated'] });

        if (rowData.length === 0) {
          modalRowDataManager.addEmptyRow();
        }

        handleGridChange();
      },
      [handleGridChange, modalRowDataManager],
    );

    const colDefs = useMemo<ColDef<RuleRowData>[]>(() => {
      return getModalColDefs(category, metricsValues, handleDelete, modalRowDataManager);
    }, [category, handleDelete, metricsValues, modalRowDataManager]);

    return (
      <BootstrapModal
        size="lg"
        titleId="Rules.AddClauseBatchModal.Title"
        isFullScreen={true}
        footer={
          <CancelConfirmFooter
            onConfirm={() => {
              const parsedRowData: RuleRowData[] = modalRowDataManager.getRowData().map(row => {
                const parsedRow = Object.entries(row).map(([columnName, val]) => {
                  // because the modal return value in string, and we cant strongly type it without super complex code.
                  const value = val as string;

                  const columnDefinition = rulesModels[category].find(
                    columnDef => columnDef.columnName === columnName,
                  );
                  if (columnDefinition === undefined) {
                    return [columnName, value];
                  }
                  return [columnName, modalRowDataManager.getParsedValue(columnDefinition, value)];
                });

                return Object.fromEntries(parsedRow);
              });

              importDataFromRulesGrid(parsedRowData);
            }}
            confirmButtonProps={{
              variant: 'primary',
              disabled: isInvalidGrid,
              component: 'Add batch',
            }}
          />
        }
      >
        <>
          <p>
            After you copied the content from your external tool (CTRL+C), select an empty cell in
            the table and press CTRL+V to paste the content and see its preview.
          </p>
          <AgGridReact<RuleRowData>
            modules={[ClientSideRowModelModule, ClipboardModule, RichSelectModule]}
            className="equity-grid-container mt-3 ag-theme-alpine ag-theme-era"
            rowSelection={'multiple'}
            rowData={modalRowDataManager.getRowData()}
            gridOptions={{
              defaultColDef,
              columnDefs: colDefs,
              suppressMenuHide: false,
            }}
            onCellEditingStopped={() => {
              const invalidRowDataManagerGrid = modalRowDataManager.isInvalidGrid(
                gridApiRef.current?.getColumnDefs(),
              );
              modalRowDataManager.computeDuplicatedWithExternal(externalRowData);

              modalRowDataManager.computeDuplicated();
              gridApiRef.current?.refreshCells({ columns: ['duplicated'] });
              setIsInvalidGrid(invalidRowDataManagerGrid);
            }}
            onGridReady={e => {
              gridApiRef.current = e.api;
            }}
            onPasteEnd={event => {
              const newRowData = event.api
                .getRenderedNodes()
                .map(({ data }) => data)
                .filter(isDefined);

              modalRowDataManager.updateRowData(newRowData);
              modalRowDataManager.computeDuplicatedWithExternal(externalRowData);
              modalRowDataManager.computeDuplicated();

              handleGridChange();
            }}
            processDataFromClipboard={processDataFromClipboard}
          />
        </>
      </BootstrapModal>
    );
  },
);

function getModalColDefs(
  category: RuleCategory,
  metricValues: string[],
  onDelete: (rowId: number) => void,
  modalRowDataManager: ModalRowDataManager,
) {
  const allColumnName = rulesModels[category].map(({ columnName }) => columnName);
  const columnDefinitions = asSequence(allColumnName)
    .distinctBy(columnName => columnName)
    .toArray();

  const cellClassRules: CellClassRules = {
    'ag-cell-danger': params => {
      if (modalRowDataManager.isInitialGridState()) {
        return false;
      }

      return !modalRowDataManager.isValidValue(params.colDef.field, params.value);
    },
  };

  const colDefs: ColDef[] = [
    {
      field: 'metric',
      cellClassRules,
      ...dropDown(metricValues),
    },
    ...columnDefinitions.map(columnName => {
      if (columnName === 'entityType') {
        return {
          field: columnName,
          cellClassRules,
          ...dropDown([...rulesEntityTypes, null]),
        };
      }

      if (columnName === 'toUnderlyingType' || columnName === 'underlyingType') {
        return {
          field: columnName,
          cellClassRules,
          ...dropDown([...underlyingTypes, null]),
        };
      }

      return {
        field: columnName,
        cellClassRules,
      };
    }),
    {
      headerName: 'Delete',
      width: 100,
      cellClass: 'flex-center',
      editable: false,
      cellRenderer: ({ node }: { node: RowNode }) => (
        <Button
          disabled={modalRowDataManager.isInitialGridState()}
          onClick={() => onDelete(node.data.id!)}
          icon
          variant="danger"
          flat
        >
          <em className="icon icon-sm flex-center">delete_outline</em>
        </Button>
      ),
    },
  ];

  colDefs.unshift(
    {
      width: 45,
      colId: 'duplicatedWithExternal',
      cellRenderer: ({ data }: { data: RuleRowData }) => {
        const duplicatedKey = modalRowDataManager.isDuplicatedFromExternal(data);
        if (duplicatedKey) {
          return <em className="icon icon-sm">error_outline</em>;
        }
      },
      tooltipValueGetter: ({ data }) => {
        const duplicatedKey = modalRowDataManager.isDuplicatedFromExternal(data);
        if (duplicatedKey) {
          return 'This line duplicated and will override the existing one';
        }
      },
    },
    {
      width: 45,
      colId: 'duplicated',
      cellRenderer: ({ data }: { data: RuleRowData }) => {
        const duplicatedKey = modalRowDataManager.isDuplicated(data);
        if (duplicatedKey !== undefined) {
          return <em className="icon icon-sm">warning</em>;
        }
      },
      tooltipValueGetter: ({ data }) => {
        const duplicatedKey = modalRowDataManager.isDuplicated(data);
        if (duplicatedKey !== undefined) {
          return 'Line is duplicated';
        }
      },
    },
  );

  return colDefs;
}

function dropDown(values: readonly (string | null)[]): ColDef {
  return {
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      // @ts-ignore
      values: values,
      searchType: 'match',
      allowTyping: true,
      filterList: true,
      highlightMatch: true,
    } satisfies IRichCellEditorParams,
  };
}

import { redirect } from '@tanstack/react-router';

import { userSlice, type UserPermission } from '@/store/slices/user/userSlice.ts';
import { store } from '@/store/store.ts';

export function validateOnePermission(...requiredPermissions: UserPermission[]) {
  return () => {
    const user = userSlice.selectors.user(store.getState());
    const hasAccess = user.permissions.some(p => requiredPermissions.includes(p));
    if (!hasAccess) {
      const permittedRoutesByPriority = getPermittedRoutesByPriority(user.permissions);
      const toRoute = permittedRoutesByPriority[0] ?? '/notAuthorized';
      throw redirect({ to: toRoute, replace: true });
    }
  };
}

function getPermittedRoutesByPriority(userPermissions: UserPermission[]) {
  const permittedRoutes = [];

  if (userPermissions.includes('viewEquityRisk')) {
    permittedRoutes.push('/');
  }

  if (userPermissions.includes('viewHedger')) {
    permittedRoutes.push('/hedger');
  }

  if (userPermissions.includes('viewRules')) {
    permittedRoutes.push('/rules');
  }

  return permittedRoutes;
}

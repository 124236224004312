import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';

import {
  commonGridStateSchema,
  type CommonGridState,
} from '@/store/slices/prefs/commonGridStateSchema.ts';
import { validateAndLog } from '@/store/slices/prefs/validateAndLog.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import type { AppState, AppThunk } from '@/store/store.ts';
import { loadUserSessionService, saveUserSessionService } from '@/web/session/sessionApi.ts';

export interface HedgerState {
  gridState: CommonGridState | undefined;
}

const initialState: HedgerState = {
  gridState: undefined,
};

export const hedgerSlice = createSlice({
  name: 'hedger',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<HedgerState>) => {
      return action.payload;
    },
    setValue: (state, action: PayloadAction<Partial<HedgerState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchHedgerState.fulfilled, (_, action) => {
      if (action.payload !== undefined) {
        return action.payload;
      }
    });
  },
});

const validationSchema = z.object({
  gridState: commonGridStateSchema,
});

export function validateHedgerState(data: unknown): HedgerState | undefined {
  return validateAndLog('HedgerState', validationSchema, data);
}

export function saveHedgerGridStateToSessionServiceThunk(gridState: CommonGridState): AppThunk {
  return async (dispatch, getState) => {
    dispatch(hedgerSlice.actions.set({ gridState }));
    const email = userSlice.selectors.email(getState());
    await saveUserSessionService(
      email,
      'hedger',
      {
        gridState,
      },
      validationSchema,
    );
  };
}

export const fetchHedgerState = createAsyncThunk<
  HedgerState | undefined,
  void,
  { state: AppState }
>('hedgerSlice/fetchHegderState', async (_, { getState }) => {
  const user = userSlice.selectors.user(getState());
  return loadUserSessionService(user.email, 'hedger').then(validateHedgerState);
});

import type { PropsWithChildren } from 'react';
import { Outlet } from '@tanstack/react-router';

import { useAppSelector } from '@/store/hooks.ts';
import { Toasts } from '@/components/common/bootstrap/Toast.tsx';
import { Footer } from '@/components/common/layout/Footer.tsx';
import { Header } from '@/components/common/layout/Header.tsx';
import { SuspenseWrapper } from '@/components/common/utils/SuspenseWrapper.tsx';
import { UserPrefs } from '@/components/prefs/UserPrefs.tsx';

function PageOverlay(): JSX.Element | null {
  const currentPageOverlay = useAppSelector(state => state.ui.currentPageOverlay);
  switch (currentPageOverlay) {
    case 'UserPrefs':
      return (
        <div className="page-overlay border-start">
          <UserPrefs />
        </div>
      );
    default:
      return null;
  }
}

function Main({ children }: PropsWithChildren): JSX.Element {
  return (
    <main>
      <div className="page">{children}</div>
      <PageOverlay />
    </main>
  );
}

export function RootRouteComponent(): JSX.Element {
  return (
    <SuspenseWrapper>
      <div className="app">
        <Header />
        <Toasts />
        <Main>
          <SuspenseWrapper>
            <Outlet />
          </SuspenseWrapper>
        </Main>
        <Footer />
      </div>
      {/*<TanStackRouterDevtools position="top-right" />*/}
    </SuspenseWrapper>
  );
}

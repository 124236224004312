import { useRef, type PropsWithChildren } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DraggableStyle,
  type DropResult,
} from '@hello-pangea/dnd';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import {
  colorsRulesSlice,
  deleteRuleThunk,
  moveRuleThunk,
  replaceRulesThunk,
  rollbackRuleThunk,
  stopEditAndSaveRuleThunk,
  toggleRuleThunk,
  validateColorRules,
  type ColorRule,
} from '@/store/slices/colorRules/colorsRulesSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Dropdown } from '@/components/common/bootstrap/Dropdown.tsx';
import { Switcher } from '@/components/common/bootstrap/Switcher.tsx';
import { ColorRuleEdit } from '@/components/prefs/tabs/ColorRules/ColorRuleEdit.tsx';
import { downloadBlob } from '@/utils/downloadBlob.ts';
import { parseJson, stringifyJson } from '@/utils/json.ts';

function getItemStyle(isDragging: boolean, draggableStyle: DraggableStyle = {}) {
  return {
    // userSelect: 'none' as const,
    background: isDragging ? 'var(--bs-emphasis-color)' : undefined,
    ...draggableStyle,
  };
}

export function ColorCodingTab() {
  const colorRules = useAppSelector(state => state.colorRules.rules);
  const editionState = useAppSelector(state => state.colorRules.editionState);
  const dispatch = useAppDispatch();

  function moveCard(dragIndex: number, hoverIndex: number) {
    dispatch(moveRuleThunk(dragIndex, hoverIndex));
  }

  function onEdit(index: number) {
    dispatch(colorsRulesSlice.actions.editRule({ index }));
  }

  function onDisable(index: number, enabled: boolean) {
    dispatch(toggleRuleThunk(index, enabled));
  }

  function onDelete(index: number) {
    dispatch(deleteRuleThunk(index));
  }

  function onCreate() {
    dispatch(colorsRulesSlice.actions.addRule());
  }

  if (editionState !== undefined) {
    const onCancel = function () {
      dispatch(rollbackRuleThunk());
    };

    function onClose() {
      dispatch(stopEditAndSaveRuleThunk());
    }

    return <ColorRuleEdit editIndex={editionState.index} onClose={onClose} onCancel={onCancel} />;
  }

  async function importFiles(file: File) {
    const rules = validateColorRules(parseJson(await file.text()));
    if (rules.length === 0) {
      dispatch(addErrorToastThunk('Invalid rules file'));
    } else {
      dispatch(replaceRulesThunk(rules));
    }
  }

  function onExport() {
    downloadJson('rules.json', colorRules);
  }

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const dragIndex = result.source.index;
    const destinationIndex = result.destination.index;

    moveCard(dragIndex, destinationIndex);
  }

  return (
    <>
      <div className="btn-group">
        <UploadButton onUpload={importFiles} />
        <Button className="btn-icon-start my-3" onClick={onExport}>
          <em className="icon">download</em>
          Export
        </Button>
        <Button className="btn-icon-start my-3" onClick={onCreate}>
          <em className="icon">add</em>
          Create
        </Button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              // style={getListStyle(droppableSnapshot.isDraggingOver)}
              className="d-flex flex-column"
            >
              {colorRules.map((rule, index) => (
                <Draggable key={rule.name} draggableId={rule.name} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      data-e2e="rule"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={getItemStyle(
                        draggableSnapshot.isDragging,
                        draggableProvided.draggableProps.style,
                      )}
                    >
                      <RuleCard
                        index={index}
                        rule={rule}
                        onToggle={checked => onDisable(index, checked)}
                        onEdit={() => onEdit(index)}
                        onDelete={() => onDelete(index)}
                      >
                        <span className={`${rule.bgColor} ${rule.textColor}`}>{rule.name}</span>
                      </RuleCard>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

function RuleCard({
  index,
  rule,
  children,
  onToggle,
  onEdit,
  onDelete,
}: PropsWithChildren<{
  index: number;
  rule: ColorRule;
  onToggle: (checked: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
}>) {
  function handleDropdownClick(action: 'edit' | 'delete') {
    if (action === 'delete') {
      onDelete();
    } else {
      onEdit();
    }
  }

  return (
    <div className="card card-raising card-bordered m-1 bg-lvl2">
      <div className="d-flex card-body p-2 justify-content-between gap-2 align-items-center cursor-grab">
        <div className="d-flex align-items-center">
          <i
            className={classNames('icon icon-md cursor-grab', {
              'text-dark': !rule.enabled,
            })}
            style={{ minWidth: 20 }}
          >
            drag_indicator
          </i>
          <span
            className={classNames('badge rounded-pill', {
              'bg-primary': rule.enabled,
              'bg-dark': !rule.enabled,
            })}
          >
            {index + 1}
          </span>
        </div>

        <div>{children}</div>

        <div className="d-flex align-items-center gap-2">
          <Dropdown
            itemsAsObjects={['edit', 'delete']}
            ItemRenderer={ItemRenderer}
            onItemClick={handleDropdownClick}
          >
            <span
              className={classNames({
                'text-dark': !rule.enabled,
              })}
            >
              Actions
            </span>
          </Dropdown>
          <Switcher checked={rule.enabled} onChange={onToggle} />
        </div>
      </div>
    </div>
  );
}

function ItemRenderer({ item }: { item: 'edit' | 'delete' }) {
  switch (item) {
    case 'delete':
      return <span className="text-danger">Delete</span>;
    case 'edit':
      return <span>Edit</span>;
  }
}

function downloadJson(filename: string, colorRules: ColorRule[]) {
  const blob = new Blob([stringifyJson(colorRules)], {
    type: 'application/json',
  });
  downloadBlob(filename, blob);
}

function UploadButton({ onUpload }: { onUpload: (file: File) => void }) {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  function onButtonClick() {
    uploadInputRef.current?.click();
  }
  function onInputChange(files: FileList | null) {
    if (files !== null && files.length > 0) {
      onUpload(files[0]);
    }
  }
  return (
    <>
      <input
        ref={uploadInputRef}
        onChange={event => onInputChange(event.target.files)}
        type="file"
        accept="application/json"
        className="d-none"
      />
      <Button className="btn-icon-start my-3" onClick={onButtonClick}>
        <em className="icon">upload</em>
        Import
      </Button>
    </>
  );
}

import { memo } from 'react';
import { Allotment } from 'allotment';

import type { ParsedResponse } from '@/core/parsing/parseResponse.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { SuspenseWrapper } from '@/components/common/utils/SuspenseWrapper.tsx';
import { MyEditor } from '@/components/queryTools/components/MyEditor.tsx';
import type { QueryResult } from '@/components/queryTools/query/common.ts';
import { queryResponseAsset } from '@/components/queryTools/queryResponseAsset.ts';

function formatJson(obj: any) {
  return JSON.stringify(obj, null, 2);
}

interface ExecViewProps {
  currentQuery: string;
  queryPayload: string;
  renderResult: (result: ParsedResponse) => JSX.Element;
}

export function ExecView(props: ExecViewProps): JSX.Element {
  return (
    <SuspenseWrapper key={props.currentQuery + props.queryPayload} errorRenderer={ErrorView}>
      <ExecViewInner {...props} />
    </SuspenseWrapper>
  );
}

function ExecViewInner({ currentQuery, queryPayload, renderResult }: ExecViewProps): JSX.Element {
  const wsUrl = useAppSelector(state => state.query.wsUrl);
  if (wsUrl === undefined) {
    return <ErrorPanel>Bad websocket URL</ErrorPanel>;
  }

  const result: QueryResult = queryResponseAsset.read(wsUrl, queryPayload, currentQuery);
  // This log is intentional. Since we have no GUI for it, it is a convenient way to see
  // the parsed result after executing a query
  console.log(result);
  return <ResponseView renderResult={renderResult} queryResult={result} />;
}

type ResponseViewProps = {
  queryResult: QueryResult;
  renderResult: (result: ParsedResponse) => JSX.Element;
};

function ErrorView({ error }: { error: Error }) {
  return (
    <div
      className="p-3 text-danger"
      style={{ overflowY: 'scroll', height: '100%', whiteSpace: 'pre' }}
    >
      {error.message}
    </div>
  );
}

const ResponseView = memo(({ queryResult, renderResult }: ResponseViewProps) => {
  const { rawResponse, parsedResponse } = queryResult;
  if (parsedResponse === undefined) {
    return (
      <Allotment vertical>
        <MyEditor
          title="Raw"
          language="json"
          value={formatJson(rawResponse)}
          options={{ readOnly: true }}
        />
        <div className="text-danger">Could not parse</div>
      </Allotment>
    );
  }
  return (
    <>{renderResult(parsedResponse)}</>
    // <Allotment vertical>
    //   <MyEditor title="parsed" language="json" value={formatJson(parsedResponse)} readonly />
    // <QueryResultTable treeData={treeData} data={parsedResponse} />
    // </Allotment>
  );
});

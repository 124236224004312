import { Button } from '@/components/common/bootstrap/Button.tsx';
import { RouteErrorComponent } from '@/components/common/utils/RouteErrorComponent.tsx';
import { isError } from '@/utils/error/isError.ts';

export function EquityErrorComponent({ error }: { error: unknown }): JSX.Element {
  if (isError(error) && error.name === 'Error.GreatServerNoUrl') {
    return (
      <div className="d-flex text-center text-secondary flex-grow-1">
        <div className="d-flex align-items-center justify-content-center flex-grow-1">
          <div className="w-25 d-flex flex-column gap-2">
            <em className="icon icon-xl text-warning">warning</em>

            <div className="display-4 text-warning">No GREAT server available</div>
            <div>
              <p>No GREAT server available for you profile.</p>
              <p>Please contact support.</p>
            </div>
            <div className="mt-3">
              <Button
                className="btn-icon-start"
                discreet
                size="lg"
                variant="secondary"
                onClick={() => window.location.reload()}
              >
                <em className="icon">refresh</em>
                Try again
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <RouteErrorComponent error={error} />;
}

import MatomoTracker from '@jonkoops/matomo-tracker';

import { getConfig } from '@/utils/config/config.ts';
import { isNode } from '@/utils/libs/isNode.ts';

//TODO fix typing for category in trackEvent function

export const matomo = createMatomo();

function createMatomo(): MatomoTracker {
  if (isNode) {
    return new MatomoTracker({ urlBase: 'url', siteId: 1, disabled: true });
  }

  const { matomo: matomoConfig } = getConfig();

  return new MatomoTracker({
    urlBase: matomoConfig.url,
    siteId: parseInt(matomoConfig.site_id, 10),
    trackerUrl: `${matomoConfig.url}/piwik.php`,
    srcUrl: `${matomoConfig.url}/piwik.js`,
    // disabled on localhost
    disabled: import.meta.env.DEV,
  });
}

import type EventEmitter from 'events';
import { pEventIterator, type IteratorOptions } from 'p-event';
import { asSequence } from 'sequency';

import { computeCoords, type MdxColumn, type RowData } from '@/core/parsing/parseResponse.ts';
import type { CellDataEvent, CellSetDataEvent, WsEvents } from '@/core/webSocket/types.ts';

export async function* queryIterator(
  wsEventEmitter: EventEmitter<WsEvents>,
  id: string,
  opts: IteratorOptions<CellSetDataEvent> = {},
) {
  const cellSetDataEvents = pEventIterator<'cellSetData', CellSetDataEvent>(
    wsEventEmitter,
    'cellSetData',
    opts,
  );
  for await (const cellSetDataEvent of cellSetDataEvents) {
    // workaround for filter not working https://github.com/sindresorhus/p-event/issues/18
    if (cellSetDataEvent.queryId === id) {
      yield cellSetDataEvent;
    }
  }
}

export interface RowUpdate {
  add: RowData[];
  remove: RowData[];
  update: RowData[];
  columns?: MdxColumn[];
}

export interface QueryIteratorOptions {
  iteratorOptions?: IteratorOptions<any>;
  // workaround for iteratorOptions.limit being global
  limit?: number;
  transform?: (rowData: RowData) => void;
}

export async function* queryGenerator(
  wsEventEmitter: EventEmitter<WsEvents>,
  id: string,
  options?: QueryIteratorOptions,
): AsyncGenerator<RowUpdate> {
  const { iteratorOptions, transform } = options ?? {};
  const events = pEventIterator<'cellSetData' | 'cellData', CellSetDataEvent | CellDataEvent>(
    wsEventEmitter,
    ['cellSetData', 'cellData'],
    {
      ...iteratorOptions,
      resolutionEvents: ['end'],
      filter: ({ queryId }) => queryId === id,
    },
  );

  let columns: string[] = [];
  let rowData: RowData[] = [];

  let eventCount = 0;
  for await (const event of events) {
    if (event.queryId !== id) {
      continue;
    }
    eventCount++;

    if ('data' in event) {
      yield handleCellSetData(event);
    } else if ('cells' in event) {
      yield handleCellData(event);
    }

    if (options?.limit !== undefined && eventCount >= options.limit) {
      break;
    }
  }

  function handleCellSetData(event: CellSetDataEvent): RowUpdate {
    const previousPathsToRemove = asSequence(rowData).associateBy(rowData =>
      rowData.dataPath.join('/'),
    );

    const newRowData = event.data.rowData;

    const add: RowData[] = [];
    const update: RowData[] = [];

    if (transform !== undefined) {
      newRowData.forEach(transform);
    }

    for (const rowDatum of newRowData) {
      const path = rowDatum.dataPath.join('/');

      if (previousPathsToRemove.has(path)) {
        update.push(rowDatum);
      } else {
        add.push(rowDatum);
      }
      previousPathsToRemove.delete(path);
    }

    // SIDE EFFECTS
    rowData = newRowData;
    columns = event.data.columns.filter(c => c.type === 'data').map(c => c.path.join('/'));
    return {
      columns: event.data.columns,
      add,
      update,
      remove: [...previousPathsToRemove.values()],
    };
  }

  function handleCellData(event: CellDataEvent): RowUpdate {
    const update: RowData[] = [];
    for (const cell of event.cells) {
      const { colIndex, rowIndex } = computeCoords(cell.ordinal, columns.length);
      const rowDatum = rowData[rowIndex];
      if (rowDatum !== undefined) {
        const column = columns[colIndex];
        rowDatum[column] = cell.value;
        update.push(rowDatum);
      }
    }
    return {
      add: [],
      update,
      remove: [],
    };
  }
}

import {
  BUS_TOPIC_SGCONNECT_USERCONNECTION,
  BUS_TOPIC_SGCONNECT_USERINFO,
  getWidgetBus,
} from '@/utils/sgwt/widgets.ts';

interface SgwtConstraint {
  name: string;
  values: string[];
}

export interface SgwtUserPermission {
  name: string;
  constraints: SgwtConstraint[];
}

interface SgwtUserAuthorization {
  resource: string;
  permissions: SgwtUserPermission[];
  resource_id: string;
}

export interface SgwtUserInfo {
  sub: string;
  zoneinfo: string;
  postal_country: string;
  mail: string;
  igg: string;
  last_name: string;
  login_ad: string;
  company_bdr_name: string;
  given_name: string;
  locale: string;
  contact_id: string;
  sgconnect_id: string;
  user_authorization: SgwtUserAuthorization[];
  rc_local_sigle: string;
  sesame_id: string;
  user_bdr_id: string;
  company_bdr_level: string;
  name: string;
  is_sg_group_user: string;
  family_name: string;
  first_name: string;
  company_bdr_id: string;
  preferred_language: string;
  origin_network: string;
  auth_level: string;
}

export function getUserInfo(): SgwtUserInfo | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<SgwtUserInfo>(BUS_TOPIC_SGCONNECT_USERINFO);
  }
  return undefined;
}

interface SgwtUserConnection {
  connected: boolean;
  claims: Record<string, string>;
  mail: string;
}

export function getUserConnection(): SgwtUserConnection | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<SgwtUserConnection>(BUS_TOPIC_SGCONNECT_USERCONNECTION);
  }
  return undefined;
}

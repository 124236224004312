import type { ColDef } from '@ag-grid-community/core';

import type { MdxColumn, RowData } from '@/core/parsing/parseResponse.ts';
import { absValueComparator } from '@/components/equityRisk/gridMappings/comparators.ts';

export function getTableColumnsFromMdx(columns: MdxColumn[]): ColDef<RowData>[] {
  return columns.map(getColumnMappingFromMdxColumn);
}

function getColumnMappingFromMdxColumn(column: MdxColumn): ColDef<RowData> {
  const name = column.path.filter(el => el !== 'AllMember').join('/');
  const key = column.path.join('/');
  return {
    colId: key,
    headerName: name,
    type: 'rightAligned',
    valueGetter: params => params.data && params.data[key],
    comparator: absValueComparator,
  };
}

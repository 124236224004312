import type { ICellRendererParams } from '@ag-grid-community/core';

export function InnerHierarchyRenderer({
  node,
  value,
}: Pick<ICellRendererParams, 'node' | 'value'>) {
  const expand = () => {
    node.setExpanded(!node.expanded);
  };

  if (node.isExpandable()) {
    return (
      <span className="cursor-pointer" onClick={expand}>
        {node.level === 0 ? 'Total' : value}
      </span>
    );
  }
  return <>{value}</>;
}

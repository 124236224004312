export interface AxeDefinition {
  id: number;
  hierarchies: AxisHierarchy[];
  positions: AxisPosition[][];
}

export interface AxisHierarchy {
  dimension: string;
  hierarchy: string;
}

export interface AxisPosition {
  namePath: string[];
  captionPath: string[];
}

export interface CellDefinition {
  ordinal: number;
  value: any;
  formattedValue: string;
}

export interface MdxStateData {
  axes: AxeDefinition[];
  cells: CellDefinition[];
}

export interface MdxUpdateData {
  cells: CellDefinition[];
}

export interface MdxState {
  type: 'cellSetData';
  status: 'success';
  data: MdxStateData;
  queryId: string;
}

export interface MdxUpdate {
  type: 'cellData';
  status: 'success';
  data: MdxUpdateData;
  queryId: string;
}

export interface MdxError {
  status: 'error';
  error: {
    errorChain: { type: string; message: string }[];
    stackTrace: string;
  };
  queryId: string;
  streamId: string;
}

export interface MdxGlobalError {
  error: string;
  details: string;
}

export function isGlobalError(error: MdxError | MdxGlobalError): error is MdxGlobalError {
  return typeof error.error === 'string';
}

export type MdxResponse = MdxState | MdxUpdate | MdxError | MdxGlobalError;

export enum Axes {
  SLICER = -1,
  COLUMNS = 0,
  ROWS = 1,
  PAGES = 2,
  CHAPTERS = 3,
  SECTIONS = 4,
}

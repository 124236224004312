import { asSequence } from 'sequency';

import { getAllMeasureIds, type AllMeasureId } from '@/core/measures.ts';
import type { CommonColumnState } from '@/store/slices/prefs/commonGridStateSchema.ts';
import type { VisualizationPreset } from '@/store/slices/prefs/visualizationPresetsSlice.ts';
import {
  isColIdMeasureId,
  isColIdValid,
} from '@/components/equityRisk/gridMappings/isColIdValid.ts';
import { defaultMeasureColumns } from '@/components/equityRisk/gridMappings/measureColumns.ts';

export function fixVisuPreset(visuPreset: VisualizationPreset): VisualizationPreset {
  const columnState = fixColumnState(visuPreset);
  const columnGroupState = fixColumnGroupState(visuPreset);

  return {
    ...visuPreset,
    columnState,
    columnGroupState,
  };
}

function fixColumnState(visuPreset: VisualizationPreset) {
  const columnState = visuPreset.columnState.filter(colState => isColIdValid(colState.colId));

  const colStateMeasureIds = columnState.map(c => c.colId).filter(isColIdMeasureId);
  const missingMeasureIds = asSequence(getAllMeasureIds()).minus(colStateMeasureIds).toArray();

  const measureCols = asSequence(defaultMeasureColumns).associateBy(col => col.measureId);

  // insert missing measures
  for (const measureId of missingMeasureIds) {
    const measureColumn = measureCols.get(measureId)!;

    let index = columnState.length;
    if (measureColumn.parent !== undefined) {
      index = columnState.findIndex(cs => cs.colId === measureColumn.parent) + 1;
      if (index === -1) {
        index = columnState.length;
      }
    }

    const colState: CommonColumnState = getDefaultColState(measureId);
    columnState.splice(index, 0, colState);
  }
  return columnState;
}

function getDefaultColState(measureId: AllMeasureId | 'Hierarchy') {
  if (measureId === 'Hierarchy') {
    return { hide: false, pinned: true, width: 200, sort: null, colId: measureId };
  }
  return { hide: true, pinned: null, width: 120, sort: null, colId: measureId };
}

function fixColumnGroupState(visuPreset: VisualizationPreset) {
  const validColumnGroup = asSequence(defaultMeasureColumns)
    .map(it => it.parent)
    .distinct()
    .map(colId => colId + '_group')
    .toArray();
  return visuPreset.columnGroupState.filter(cg => validColumnGroup.includes(cg.groupId));
}

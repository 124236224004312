import {
  createRouteMask,
  createRouter,
  RouterProvider as TanRouterProvider,
  type FileRoutesByPath,
} from '@tanstack/react-router';

import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { RouteErrorComponent } from '@/components/common/utils/RouteErrorComponent.tsx';
import { routeTree } from '@/_generated/routeTree.gen.ts';

const hedgerSettingsModalMask = createRouteMask({
  routeTree,
  from: '/hedger/settings/$scenarioId',
  to: '/hedger',
});

function NotFoundPage() {
  return (
    <div className="px-4 d-flex flex-column justify-content-center align-items-center">
      <h4>Not found</h4>
      <p>This URL is not known</p>
    </div>
  );
}

function getRouter() {
  return createRouter({
    routeTree,
    defaultPendingComponent: Loader,
    defaultNotFoundComponent: NotFoundPage,
    defaultErrorComponent: RouteErrorComponent,
    // opt-out of preloading
    defaultPreload: false,
    defaultPendingMs: 1,
    // https://tanstack.com/router/v1/docs/guide/data-loading#avoiding-pending-component-flash
    // defaultPendingMinMs: 0,
    routeMasks: [hedgerSettingsModalMask],
  });
}

export function RouterProvider(): JSX.Element {
  return <TanRouterProvider router={getRouter()} />;
}

export type MyRouter = ReturnType<typeof getRouter>;
export type RouteName = keyof FileRoutesByPath;

declare module '@tanstack/react-router' {
  interface Register {
    router: MyRouter;
  }
}
